<template>
  <div class="blog-page">
    <h1 class="blog-page__title-page">
      Блог
    </h1>
    <div class="blog-page__content">
      <m-filter-posts
        v-if="!isLoadingBlog"
        :default-year="getDefaultYear"
        :years="years"
      />
      <div v-if="posts.length">
        <MBlogPostWrapper
          :posts="posts"
          :is-load-more-mode="isLoadMoreMode"
        />
        <sfOPagination
          v-if="totalPages > 1"
          class="blog-page__show-more"
          :current="currentPage"
          :per-page="perPage"
          :total="totalPages"
          @loadMoreFromPagEmit="loadMoreFromPagEmitFunction"
        />
      </div>
    </div>
    <ALoadingSpinner
      v-if="isLoadingBlog && !isLoadMoreMode"
      class="o-microcart-panel__loading"
      :is-absolute-position="true"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import MBlogPostWrapper from 'theme/components/molecules/m-blog-post-wrapper.vue'
import MFilterPosts from 'theme/components/molecules/m-filter-posts'
import SfOPagination from 'theme/components/storefront-override/SfOPagination'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'

const THEME_PAGE_SIZE = 24;

export default {
  name: 'Blog',
  components: {
    MBlogPostWrapper,
    MFilterPosts,
    ALoadingSpinner,
    SfOPagination
  },
  data () {
    return {
      perPage: THEME_PAGE_SIZE,
      routeData: {},
      isLoadMoreMode: false
    }
  },
  computed: {
    ...mapGetters({
      getDefaultYear: 'blog/getDefaultYear',
      posts: 'blog/getPosts',
      isLoadingBlog: 'blog/getIsLoadingBlog',
      years: 'blog/getYears',
      total: 'blog/getTotal',
      currentPage: 'blog/getCurrentPage'
    }),
    totalPages: {
      get () {
        const t = Math.ceil(this.total / THEME_PAGE_SIZE);
        if (this.currentPage === t) return 0
        return t
      }
    }
  },
  async created () {
    await this.checkForBlogs()
  },
  mounted () {
    document.querySelector('body')?.classList.add('blog-page-root')
    this.resetCurrentPage()
  },
  beforeMount () {
    this.$bus.$on('blog-update-posts', this.loadPostsMore)
  },
  beforeDestroy () {
    this.$bus.$off('blog-update-posts', this.loadPostsMore)
    document.querySelector('body').classList.remove('blog-page-root')
  },
  methods: {
    ...mapActions({
      resetCurrentPage: 'blog/resetCurrentPage',
      updateBlogs: 'blog/updateBlogs',
      loadBlogs: 'blog/loadBlogs',
      loadBlogCategories: 'blog/loadBlogCategories'
    }),
    async checkForBlogs () {
      const getPosts = await this.loadBlogs({ route: this.routeData })

      if (!getPosts.length) {
        this.$router.push(this.localizedRoute('/page-not-found'))
      }
    },
    async loadMoreFromPagEmitFunction () {
      this.isLoadMoreMode = true
      let saveCount = this.currentPage

      this.routeData = {
        query: {
          page: saveCount += 1
        },
        params: {
          loadMore: true
        }
      }

      await this.loadBlogs({ route: this.routeData })

      this.isLoadMoreMode = false;
    }
  }
}
</script>
<style lang="scss">
@import "~theme/css/breakpoints";

.blog-page-root div#static {
  padding: 0 var(--spacer-10);

  .sf-breadcrumbs__list-item {
    padding-left: 0;
  }

  @media (min-width: $tablet-min) {
    padding: 0 var(--spacer-17);
  }
}
</style>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.blog-page {
  &__title-page {
    margin: 0 0 var(--spacer-20) 0;
    @include header-title;
  }

  ::v-deep .sf-pagination {
    justify-content: center;

    .sf-pagination__item {
      display: none;
    }

    .products__load-more {
      position: static;
      background: var(--dark-orange);
      color: var(--white);
      padding: var(--spacer-16);
      font-weight: var(--font-medium);
      font-size: var(--font-size-18);
      transform: unset;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: var(--max-width);
    margin-bottom: var(--spacer-45);
    row-gap: px2rem(20);

    @include for-desktop {
      padding: 0;
    }
  }
}

::v-deep .blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-column-gap: var(--spacer-15);
  grid-row-gap: var(--spacer-30);
  margin-bottom: var(--spacer-30);

  @media (min-width: $tablet-min) {
    grid-column-gap: var(--spacer-10);
    margin-bottom: var(--spacer-40);
  }

  @include for-desktop {
    grid-template-columns: repeat(4, minmax(238px, 1fr));
    grid-row-gap: var(--spacer-40);
  }

  @include for-tablet {
    grid-template-columns: repeat(3, minmax(238px, 1fr));
  }

  > div {
    cursor: pointer;
  }
}
</style>
