<template>
  <SfTabs :open-tab="1">
    <SfTab :title="$t('Rent')">
      <template>
        <div v-html="tabFirst" />
      </template>
    </SfTab>
    <SfTab :title="$t('Sublease')">
      <template>
        <div v-html="tabSecond" />
      </template>
    </SfTab>
  </SfTabs>
</template>
<script>
import { SfTabs } from '@storefront-ui/vue'

export default {
  name: 'MRentTabs',
  components: {
    SfTabs
  },
  props: {
    tabFirst: {
      type: String,
      default: ''
    },
    tabSecond: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.sf-tabs {
  max-width: 790px;
  margin: 0 auto;
  font-family: var(--font-family-inter);

  // reset mobile styles for the SfTabs
  -tabs-title-z-index: 1;
  --tabs-content-order: 1;
  --tabs-title-flex: 0 0 auto;
  --tabs-title-margin: 0 var(--spacer-lg) -2px 0;
  --tabs-title-padding: var(--spacer-xs) 0;
  --tabs-title-color: var(--c-text-muted);
  --tabs-content-tab-padding: var(--spacer-base) 0;
  --tabs-chevron-display: none;
  --tabs-title-font-size: var(--font-size-16);
  --tabs-title-font-weight: var(--font-medium);
}

.rent__title {
  font-size: var(--font-size-20);

  @media (min-width: $tablet-min) {
    font-size: var(--font-size-32);
  }
}

.rent__subtitle {
  font-size: var(--font-size-13);
  color: var(--dark-gray);

  @media (min-width: $tablet-min) {
    font-size: var(--font-size-16);
  }
}

::v-deep .m-rent-tabs {
  &__grid-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: var(--spacer-50);
  }

  &--info {
    font-size: var(--font-size-16);
    line-height: var(--font-size-28);
    font-weight: var(--font-normal);
    margin: 0 0 var(--spacer-50) 0;
    color: var(--dark-gray);

    @media (min-width: ($tablet-min)) {
      height: 150px;
      margin: 0;
      line-height: var(--font-size-26);
    }
  }

  &__grid-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    text-align: center;
    margin-top: 42px;

    .sf-image {
      img {
        --image-width: 160px;
        width: 100%;
        height: auto;
      }
    }

    @media (min-width: ($tablet-min)) {
      width: 50%;
      justify-content: start;
      flex-direction: row;
      text-align: left;
      flex-wrap: nowrap;

      .sf-image {
        overflow: inherit;

        img {
          min-width: 170px;
          --image-width: 170px;
          margin-left: calc(-1 * var(--spacer-10));
        }
      }

      &:first-of-type {
        > p {
          width: 40%;
        }
      }

      &:last-of-type {
        p {
          width: 30%;
        }
      }
    }

    &--description {
      width: 260px;
      color: var(--black);
      line-height: var(--font-size-24);

      @media (min-width: ($tablet-min)) {
        width: 48%;
      }
    }
  }

  &__second-tab {
    .m-rent-tabs--title {
      text-align: left;
    }

    .m-rent-tabs--info {
      margin: 0 0 var(--spacer-30) 0;

      @media (min-width: ($tablet-min)) {
        height: auto;
      }
    }

    .m-rent-tabs__bottom-banner {
      height: 190px;

      h2 {
        width: 95%;
        padding-bottom: 10px;
        line-height: 21px;
      }

      &__tel-area {
        > a {
          line-height: var(--font-size-40);
        }
      }
    }
  }

  &--title {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: var(--font-size-20);
    font-weight: var(--font-medium);
    line-height: var(--font-size-24);

    @media (min-width: ($tablet-min)) {
      font-size: var(--font-size-32);
      line-height: var(--font-size-33);
      text-align: left;
    }
  }

  &__terms {
    display: flex;
    flex-wrap: wrap;
  }

  &__terms-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;

    li {
      font-weight: var(--font-normal);
      color: var(--black);
      line-height: 28px;
      max-width: 400px;
      margin-bottom: 10px;

      @media (min-width: ($tablet-min)) {
        width: 388px;
      }

      &::before {
        content: "\2022";
        color: var(--orange);
        font-weight: var(--font-bold);
        display: inline-block;
        width: 20px;
        margin-left: -1.7em;
        font-size: 1.5rem;
        vertical-align: top;
      }

      div {
        display: inline-block;
        width: 315px;

        @media (min-width: ($tablet-min)) {
          width: 345px;
        }
      }
    }
  }

  &__first-list {
    @media (min-width: ($tablet-min)) {
      height: 170px;
    }
  }

  &__second-list {
    @media (min-width: ($tablet-min)) {
      height: 315px;
      margin-top: var(--spacer-30);
    }
  }

  &__bottom-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: var(--spacer-50) 0 0 0;
    height: 135px;
    width: 100%;
    text-align: center;
    background: var(--light-gray);

    @media (min-width: ($tablet-min)) {
      height: 150px;
      margin: var(--spacer-50) 0 0 0;
    }

    &__tel-area {
      > a {
        padding-right: 2.5rem;
      }
    }

    h2 {
      font-weight: var(--font-medium);
      font-size: var(--font-size-20);
      color: var(--black);
      margin: 0;

      @media (min-width: ($tablet-min)) {
        margin-bottom: 0;
      }
    }

    a {
      font-size: var(--font-size-18);
      color: var(--orange);
      font-weight: var(--font-medium);

      @media (min-width: ($tablet-min)) {
        font-size: var(--font-size-32);
      }
    }
  }
}
</style>
