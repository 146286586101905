<template>
  <div class="faq-section container center">
    <header v-if="faqTitle" class="sf-heading title">
      <h3 class="sf-heading__title sf-heading__title--h3">
        {{ faqTitle }}
      </h3>
    </header>

    <SfAccordion
        :multiple="true"
        :open="[]"
        transition=""
        :show-chevron="false"
        :first-open="true"
        class="accordion"
        id="sf-accordion"
    >
      <SfAccordionItem
          v-for="faq in faqs"
          :key="faq.id"
          :header="faq.title"
      >
        <div class="accordion-item-text" v-html="faq.text"></div>
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<script>
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'
import { SfAccordion } from '@storefront-ui/vue';

export default {
  name: 'AFaq',
  mixins: [customFormSpinnerLoading],
  props: {
    faqTitle: {
      type: String,
      default: () => ('')
    },
    faqs: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    SfAccordion
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";
@import '~theme/css/fonts';

.faq-section {
  margin-top: var(--static-page-faq-top--mobile, var(--spacer-50))!important;

  @media (min-width: $tablet-min) {
    margin-top: var(--static-page-faq-top, var(--spacer-60))!important;
  }

  &.container {
    max-width: px2rem(790);
  }

  .sf-heading {
    margin-bottom: var(--spacer-28);
  }

  ::v-deep {
    a {
      color: var(--c-primary);
      text-decoration: none;
      &:hover {
        color: var(--c-text);
      }
    }
  }
}

</style>
