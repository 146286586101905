<template>
  <div
    id="static"
    class="static"
    :class="[{'full-width': isContactsPage || isFullWidthPage}, {'is-plug-page': isPlugPage}]"
  >
    <LazyHydrate :trigger-hydration="isMounted">
      <m-breadcrumbs v-if="!isHideBreadcrumbs" />
    </LazyHydrate>

    <LazyHydrate :trigger-hydration="isMounted">
      <json-ld-breadcrumb />
    </LazyHydrate>

    <NoSSR>
      <component :is="currentRoute.component" :content="content" />
    </NoSSR>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CmsPage from '@vue-storefront/core/pages/CmsPage';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import MBreadcrumbs from 'theme/components/molecules/m-breadcrumbs';
import { metaCms } from '../meta/cms';
// Buyers
import SpecialOffers from 'theme/pages/Info/Buyers/SpecialOffers';
import Novelty from 'theme/pages/Info/Buyers/Novelty';
import Stores from 'theme/pages/Info/Buyers/Stores';
import Loyalty from 'theme/pages/Info/Buyers/Loyalty';
import TMVarto from 'theme/pages/Info/Buyers/TMVarto';
import OwnTm from 'theme/pages/Info/Buyers/OwnTm';
import Quiz from 'theme/pages/Info/Buyers/Quiz';
import HelpCenter from 'theme/pages/Info/Buyers/HelpCenter';
import FaqPage from 'theme/pages/Info/HelpCenter/FaqPage';
import Gazeta from 'theme/pages/Info/Gazeta';
import GiftCards from 'theme/pages/Info/Buyers/GiftCards';
import Odessa from 'theme/pages/Info/Buyers/Odessa';
// AboutCompany
import AboutCompany from 'theme/pages/Info/AboutCompany/AboutCompany';
import Contacts from 'theme/pages/Info/AboutCompany/Contacts';
import UserAgreement from 'theme/pages/Info/AboutCompany/UserAgreement';
import Blog from 'theme/pages/Info/AboutCompany/Blog';
import PressKit from 'theme/pages/Info/AboutCompany/PressKit';
import VarusAppAgreement from 'theme/pages/Info/AboutCompany/VarusAppAgreement';
// Partners
import Suppliers from 'theme/pages/Info/Partners/Suppliers';
import Tenders from 'theme/pages/Info/Partners/Tenders';
import Rent from 'theme/pages/Info/Partners/Rent';
import Advertisers from 'theme/pages/Info/Partners/Advertisers';
import AntiCorruption from 'theme/pages/Info/Partners/AntiCorruption';
import TenderPage from 'theme/pages/Info/Partners/Tenders/TenderPage';
// Header Links
import Buyers from 'theme/pages/Info/Buyers';
import Partners from 'theme/pages/Info/Partners';
import Work from 'theme/pages/Info/Work';
import TastyJob from 'theme/pages/Info/TastyJob';
import TastyJobPageForm from 'theme/pages/Info/TastyJobPageForm';
import JuicyLifeWithVarus from 'theme/pages/Info/JuicyLifeWithVarus';
import LazyHydrate from 'vue-lazy-hydration';
import HotVacancies from 'theme/pages/Info/HotVacancies';
import CareerWithTaste from 'theme/pages/Info/CareerWithTaste';
import ExperienceOfYourFuture from 'theme/pages/Info/ExperienceOfYourFuture';
import Recipes from 'theme/pages/Info/Recipes';
import GoogleTagManager from 'theme/mixins/gtm'
import { safeHtml } from 'theme/helpers/safe-html';
import NoSSR from 'vue-no-ssr';
import BusinessDelivery from 'theme/pages/Info/Partners/BusinessDelivery.vue';
import BankOffers from 'theme/pages/Info/Buyers/BankOffers.vue';

export default {
  name: 'Static',
  components: {
    JsonLdBreadcrumb,
    MBreadcrumbs,
    LazyHydrate,
    NoSSR
  },
  mixins: [CmsPage, GoogleTagManager],
  data () {
    return {
      isMounted: false,
      isContactsPage: false,
      isPlugPage: false,
      navigation: [
        { title: this.$t('About us (Magento CMS)'), link: '/about-us', isCms: true },
        { title: this.$t('Customer service (Magento CMS)'), link: '/customer-service', isCms: true },
        { title: this.$t('Customer service'), link: '/customer-service' },
        { title: this.$t('Legal notice'), link: '/legal' },
        { title: this.$t('Store locator'), link: '/store-locator' },
        { title: this.$t('Delivery'), link: '/delivery' },
        { title: this.$t('Return policy'), link: '/returns' },
        { title: this.$t('Privacy policy'), link: '/privacy' },
        { title: this.$t('Size guide'), link: '/size-guide' },
        // Buyers
        { title: this.$t('Stock'), link: '/special-offers', component: SpecialOffers, isCms: true, plug: true },
        { title: this.$t('Everything new'), link: '/novelty', component: Novelty, isCms: true },
        { title: this.$t('Shops'), link: '/stores', component: Stores, isCms: true, plug: true },
        { title: this.$t('Loyalty program'), link: '/loyalty', component: Loyalty, isCms: true },
        { title: this.$t('Giveaway from TM VARTO'), link: '/tm-varto', component: TMVarto, isCms: true },
        { title: this.$t('Own TM'), link: '/own-tm', component: OwnTm, isCms: true },
        { title: this.$t('Газета'), link: '/gazeta-varus-0', component: Gazeta, isCms: true },
        { title: this.$t('Culinary support with chef Evgeny Klopotenko'), link: '/gazeta-kulinarna-pidtrimka', component: Gazeta, isCms: true },
        { title: this.$t('Gift certificates'), link: '/gift', component: FaqPage, isCms: true },
        { title: this.$t('Interview'), link: '/quiz', component: Quiz, isCms: true },
        { title: this.$t('Delivery for business'), link: '/optovi-zakupivli', component: BusinessDelivery, isCms: true },
        { title: this.$t('Financial Statements'), link: '/finansova-zvitnist', component: BusinessDelivery, isCms: true },
        { title: this.$t('Help'), link: '/help', component: HelpCenter, isCms: true },
        { title: this.$t('Bonus account'), link: '/bonus-account', component: FaqPage, isCms: true, childBy: 'HelpCenter' },
        { title: this.$t('Orders'), link: '/ordering', component: FaqPage, isCms: true, childBy: 'HelpCenter' },
        { title: this.$t('Paying'), link: '/payment', component: FaqPage, isCms: true, childBy: 'HelpCenter' },
        { title: this.$t('Shipping'), link: '/shipping-methods', component: FaqPage, isCms: true, childBy: 'HelpCenter' },
        { title: this.$t('Loyalty program'), link: '/loyalty-program', component: FaqPage, isCms: true, childBy: 'HelpCenter' },
        { title: this.$t('Promotions and subscriptions'), link: '/promotions-and-subscriptions', component: FaqPage, isCms: true, childBy: 'HelpCenter' },
        { title: this.$t('Gift certificates'), component: GiftCards, link: '/giftcards', isCms: true },
        { title: this.$t('Favorable offers from banks'), component: BankOffers, link: '/vigidni-propozitsiyi-vid-bankiv', isCms: true },
        { title: this.$t('Odessa'), component: Odessa, link: '/odesa', isCms: true },
        // AboutCompany
        { title: this.$t('About company'), link: '/about-company', component: AboutCompany, isCms: true },
        { title: this.$t('Contact us'), link: '/contacts', component: Contacts, isCms: true },
        { title: this.$t('User agreement'), link: '/user-agreement', component: UserAgreement, isCms: true },
        { title: this.$t('Privacy policy'), link: '/user-agreement-data-terminal', component: UserAgreement, isCms: true },
        { title: this.$t('Blog'), link: '/blog', component: Blog, isCms: true },
        { title: this.$t('Press-kit'), link: '/press-kit', component: PressKit, isCms: true },
        { title: this.$t('Varus app agreement'), link: '/varus-app-agreement', component: VarusAppAgreement, isCms: true },
        // Partners
        { title: this.$t('Suppliers'), link: '/suppliers', component: Suppliers, isCms: true, plug: true },
        { title: this.$t('Tenders'), link: '/tenders', component: Tenders, isCms: true },
        { title: this.$t('Tenders'), link: '/tender-page', component: TenderPage, isCms: true, childBy: 'Tenders' },
        { title: this.$t('For advertisers'), link: '/advertisers', component: Advertisers, isCms: true },
        { title: this.$t('Rent'), link: '/rent', component: Rent, isCms: true },
        { title: this.$t('Anti-corruption'), link: '/anti-corruption', component: AntiCorruption, isCms: true },
        // Header Links
        { title: this.$t('For clients'), link: '/buyers', component: Buyers, isCms: true, plug: true },
        { title: this.$t('For partners'), link: '/partners', component: Partners, isCms: true, plug: true },
        { title: this.$t('Work'), link: '/work', component: Work, isCms: true },
        { title: this.$t('Delicious work'), link: '/smachna_robota', component: TastyJob, isCms: true },
        { title: this.$t('Delicious work'), link: '/smachna_robota_form', component: TastyJobPageForm, isCms: true },
        { title: this.$t('Luscious life with VARUS'), link: '/juicy-life-with-varus', component: JuicyLifeWithVarus, isCms: true, plug: true },
        { title: this.$t('Hot vacancies'), link: '/hot-vacancies', component: HotVacancies, isCms: true, plug: true },
        { title: this.$t('Career with taste'), link: '/career-with-taste', component: CareerWithTaste, isCms: true, plug: true },
        { title: this.$t('Experience of your future'), link: '/experience-of-your-future', component: ExperienceOfYourFuture, isCms: true, plug: true },
        { title: this.$t('Recipes'), link: '/recipes', component: Recipes, isCms: true, plug: true }
      ]
    };
  },
  computed: {
    ...mapGetters({
      defaultContent: 'defaultContent/getDefaultContent'
    }),
    currentRoute () {
      const route = this.$route.path.replace(/^\/ru/, '')

      return {
        ...this.navigation.find(navigation => navigation.link === route)
      };
    },
    isFullWidthPage () {
      return this.currentRoute.link === '/odesa'
    },
    isHideBreadcrumbs () {
      return this.currentRoute.link === '/odesa'
    },
    content () {
      return this.currentRoute.isCms
        ? {
          title: this.$store.state.cmsPage.current.title,
          message: safeHtml(this.$store.state.cmsPage.current.content)
        }
        : this.defaultContent;
    }
  },
  watch: {
    currentRoute: {
      immediate: true,
      handler (next, prev) {
        if (!prev || next.link !== prev.link) {
          const routes = [];

          if (next.childBy === 'HelpCenter') {
            routes.push({
              name: this.$t('Help center'),
              route_link: this.localizedRoute('/help')
            });
          }

          this.$store.dispatch('breadcrumbs/set', {
            current: next.title,
            routes: routes
          });
        }
        if (this.currentRoute.plug) {
          this.$store.dispatch('ui/isPlugPage', true);
          this.isPlugPage = true;
        }

        // TODO need to remove the plugs when the pages are ready on V.2.0
        if (
          this.currentRoute.link === '/special-offers' ||
          this.currentRoute.link === '/stores' ||
          this.currentRoute.link === '/recipes' ||
          this.currentRoute.link === '/buyers' ||
          this.currentRoute.link === '/partners' ||
          this.currentRoute.link === '/work' ||
          this.currentRoute.link === '/juicy-life-with-varus' ||
          this.currentRoute.link === '/hot-vacancies' ||
          this.currentRoute.link === '/career-with-taste' ||
          this.currentRoute.link === '/experience-of-your-future'
        ) {
          this.$store.dispatch('ui/isPlugPage', true);
          this.isPlugPage = true;
        } else {
          this.$store.dispatch('ui/isPlugPage', false);
          this.isPlugPage = false;
        }
      }
    }
  },
  methods: {
    updateRoute (title) {
      /**
       * On mobile view clicking the "back" icon in SfContentPages emits click:change
       * event but without an argument. Unfortunately the same event is also used for
       * "normal" page changes so it has to be checked explicitly if it comes from "back"
       * icon or not. Currently this is the only way to do that.
       */
      if (title === undefined) {
        // "Back" icon was clicked in SfContentPages
        this.$router.back();
      } else {
        // "Normal" navigation to next page - if it exists
        const nextRoute = this.navigation.find(navigation => navigation.title === title);
        if (nextRoute) {
          this.$router.push(nextRoute.link);
        }
      }
    },
    iframeMessageHandler (event) {
      if (event?.data?.func !== 'submitForm') return

      if (event?.data?.formClassList.find(e => {
        return [
          'amform-form-quiz-first-form-ua', 'amform-form-quiz-first-form-ru', // prod
          'amform-form-quiz-ua', 'amform-form-quiz-ru' // uat
        ].includes(e)
      })) {
        this.gtmEventQuiztHandler({
          gtmEvent: 'Сторінка_quiz',
          eventCategory: 'Сторінка_quiz_Голосування',
          eventAction: 'Page_quiz_voting',
          eventLabel: event?.data?.dataValue
        })
      }

      if (event?.data?.formClassList.find(e => ['amform-form-quiz-second-form-ua', 'amform-form-quiz-second-form-ru']
        .includes(e))) {
        this.gtmEventHandler({
          gtmEvent: 'Сторінка_quiz_форма',
          parameters: 'Сторінка_quiz_форма_відправлена',
          eventMode: 'category'
        })
      }

      if (event?.data?.formClassList.find(e => ['amform-form-suppliers-form-ua', 'amform-form-suppliers-form-ru']
        .includes(e))) {
        this.gtmEventHandler({
          gtmEvent: 'Сторінка_suppliers_форма',
          parameters: 'Сторінка_suppliers_Стати постачальником_форма_відправлена',
          eventMode: 'category'
        })
      }

      if (event?.data?.formClassList.find(e => ['amform-form-tenders-form-ua', 'amform-form-tenders-form-ru']
        .includes(e))) {
        this.gtmEventHandler({
          gtmEvent: 'Сторінка_tenders_форма',
          parameters: 'Сторінка_tenders_Hе_знайшли_відповідний_тендер_форма_відправлена',
          eventMode: 'category'
        })
      }

      if (event?.data?.formClassList.find(e => ['amform-form-rent-form-ua', 'amform-form-rent-form-ru']
        .includes(e))) {
        this.gtmEventHandler({
          gtmEvent: 'Сторінка_rent_форма',
          parameters: 'Сторінка_rent_Запропонувати_площу_в_оренду_форма_відправлена',
          eventMode: 'category'
        })
      }

      if (event?.data?.formClassList.find(e => ['amform-form-anti-corruption-ua', 'amform-form-anti-corruption-ru']
        .includes(e))) {
        this.gtmEventHandler({
          gtmEvent: 'Сторінка_anti-corruption_форма',
          parameters: 'Сторінка_anti-corruption_Повідомте_про_порушення_форма_відправлена',
          eventMode: 'category'
        })
      }

      if (event?.data?.formClassList.find(e => ['amform-form-tasty-job-ua', 'amform-form-tasty-job-ru']
        .includes(e))) {
        this.gtmEventHandler({
          gtmEvent: 'Сторінка_smachna_robota_форма',
          parameters: 'Сторінка_smachna_robota_Смачна_робота_форма_відправлена',
          eventMode: 'category'
        })
      }

      if (event?.data?.formClassList.find(e => ['amform-form-contact-ua', 'amform-form-contact-ru']
        .includes(e))) {
        this.gtmEventHandler({
          gtmEvent: 'Сторінка_contacts_форма',
          parameters: 'Сторінка_contacts_Контакти_форма_відправлена',
          eventMode: 'category'
        })
      }
    }
  },
  async asyncData ({ store }) {
    await Promise.all([
      store.dispatch('homepage/loadAdvantagesContent'),
      store.dispatch('ui/loadCatalogMenu')
    ]);
  },
  async mounted () {
    this.isMounted = true
    document.querySelector('.default-layout').setAttribute('style', 'overflow-x: hidden');
    window.addEventListener('message', this.iframeMessageHandler)

    await Promise.all([
      this.$store.dispatch('defaultContent/updateDefaultContent')
    ])
    if (this.currentRoute.link === '/contacts') {
      this.isContactsPage = true;
    }
  },
  beforeDestroy () {
    document.querySelector('.default-layout').removeAttribute('style')
    window.removeEventListener('message', this.iframeMessageHandler)
  },
  metaInfo: metaCms
};
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/fonts";
@import "~theme/css/breakpoints";
@import "~theme/css/mixins";

.static {
  ::v-deep {
    iframe,
    .custom-form__iframe {
      border: none;
      outline: none;
      width: 100%;
      min-height: 600px;
      overflow: hidden;
    }

    .banner-toggle-images {
      position: relative;
      height: px2rem(150);

      @media (min-width: $tablet-min) {
        height: px2rem(250);
      }

      .description {
        .banner-text {
          position: relative;
          z-index: 1;
          font-size: var(--font-size-16);
          line-height: var(--spacer-19);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-32);
            line-height: var(--spacer-33);
          }
        }

        .banner-button {
          span {
            font-size: var(--font-size-14);

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-18);
            }
          }
        }
      }

      .images-wrapper {
        flex-direction: column;
        position: absolute;
        margin-right: 0;
        right: 0;
        width: auto;

        &.desc-version {
          display: none;
        }

        &.mob-version {
          display: flex;
        }

        @media (min-width: $tablet-min) {
          &.desc-version {
            display: flex;
          }

          &.mob-version {
            display: none;
          }
        }
      }
    }

    .custom__form {
      margin-top: var(--spacer-30);
      background: var(--green-light);
      padding: 0 var(--spacer-30);

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-80);
        margin-left: calc(-100vw / 2 + 100% / 2);
        margin-right: calc(-100vw / 2 + 100% / 2);
        min-height: 632px;
        background-image: url('/assets/form-background.png');
        background-repeat: no-repeat;
        background-position: left bottom;
      }

      &-container {
        max-width: 990px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        grid-column-gap: 1rem;

        @media (min-width: $tablet-min) {
          flex-direction: row;
        }
      }

      &-description {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 50px;

        @media (min-width: $tablet-min) {
          padding-top: 120px;
        }

        .sf-heading__title--h4 {
          @include header-title;
        }

        p {
          font-weight: 400;
          color: var(--dark-gray);
          font-size: var(--font-size-13);
          line-height: var(--spacer-22);
          margin: var(--spacer-10) 0 var(--spacer-25) 0;

          @media (min-width: $tablet-min) {
            margin: var(--spacer-25) 0 0 0;
            font-size: var(--font-size-16);
            line-height: var(--spacer-28);
          }
        }
      }

      &-block {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        height: 100%;

        @media (min-width: $tablet-min) {
          padding-top: var(--spacer-80);
        }

        .a-loading-spinner {
          top: -50px;

          .sf-loader__overlay {
            background-color: transparent;
          }
        }
      }

      .msg-position {
        flex-direction: row;
        justify-content: end;
      }
    }

    .photo-gallery-carousel {
      position: relative;

      @include for-desktop {
        margin-bottom: var(--spacer-100);
      }

      .sf-carousel__controls {
        display: none;

        @include for-desktop {
          display: flex;
          justify-content: space-between;
        }

        @media (min-width: $tablet-min) {
          top: 45%;
        }
      }

      .sf-carousel__wrapper {
        .glide {
          .glide {
            display: grid;
            grid-template-areas:
                    ". close-btn ."
                    "prev-btn track next-btn";
            grid-template-rows: auto 1fr;
            grid-template-columns: 100px 45px minmax(500px, 800px) 45px 100px;
            grid-column-gap: 1rem;
          }

          .close-fullscreen,
          .previous-slide,
          .next-slide {
            position: static;
            margin: 0;
          }

          &__track {
            grid-area: track;
            grid-column: 3 / 4;
          }

          .close-fullscreen {
            grid-area: close-btn;
            margin: 0 -30px 0 auto;
            grid-column: 3;
          }

          .previous-slide {
            grid-area: prev-btn;
            grid-column: 2;
          }

          .next-slide {
            grid-area: next-btn;
            grid-column: 4;
          }
        }
      }
    }
  }
}

#static {
  box-sizing: border-box;

  @include for-desktop {
    padding: 0 var(--spacer-sm);
    max-width: var(--max-width);
    margin: 0 auto;
  }
  --content-pages-height: auto;

  ::v-deep {
    .sf-content-pages__content,
    .sf-content-pages__sidebar {
      height: min-content;
    }
    .sf-breadcrumbs {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .breadcrumbs-offset {
    padding-left: var(--spacer-16);
    padding-right: var(--spacer-16);
  }

  &.full-width {
    @include for-desktop {
      max-width: 100%;
      padding: 0;

      .sf-breadcrumbs.sf-breadcrumbs {
        max-width: 1178px;
        margin: 0 auto;
      }
    }
  }

  &.is-plug-page {
    @include for-desktop {
      max-width: 100%;
      padding: 0;

      .sf-breadcrumbs.sf-breadcrumbs {
        display: none;
      }
    }
  }
}

::v-deep {
  .sf-bar__icon *[role=button] {
    cursor: pointer;
  }

  .sf-breadcrumbs__list-item {
    .router-link-active {
      color: var(--black);
    }
  }

  .custom__form-download-link {
    margin-top: var(--spacer-20);
    font-size: var(--font-size-16);
    color: var(--orange);
    @include font-icon(var(--icon-download2));

    &:before {
      font-size: var(--font-size-16);
      margin-right: var(--spacer-10);
    }

    @media (max-width: $mobile-max) {
      margin-top: -5px;
      margin-bottom: var(--spacer-30);
    }
  }
}
</style>
