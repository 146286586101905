<template>
  <section
    class="m-advantages"
    v-if="advantages.length"
  >
    <div
      class="m-advantages-list"
      v-if="!isMobile"
    >
      <div
        class="m-advantages__item"
        v-for="(advantage, i) in advantages"
        :key="i"
      >
        <span class="m-advantages__item-title">
          {{ advantage.title }}
        </span>
        <span class="m-advantages__item-desc">
          {{ advantage.description }}
        </span>
      </div>
    </div>
    <SfOCarousel
      v-else
      :settings="sliderSettings"
      is-show-bullets
    >
      <SfCarouselItem
        v-for="(advantage, i) in advantages"
        :key="i"
      >
        <div class="m-advantages__item">
          <span class="m-advantages__item-title">
            {{ advantage.title }}
          </span>
          <span class="m-advantages__item-desc">
            {{ advantage.description }}
          </span>
        </div>
      </SfCarouselItem>
    </SfOCarousel>
  </section>
</template>

<script>
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel'
import DeviceType from 'theme/mixins/DeviceType';
import { mapGetters } from 'vuex'

export default {
  name: 'MAdvantages',
  mixins: [DeviceType],
  components: {
    SfOCarousel
  },
  data () {
    return {
      advantages: [],
      sliderSettings: {
        type: 'slider',
        animationDuration: 2000,
        rewindDuration: 2000,
        gap: 0,
        perView: 2
      },
      getBrowserWidth: 0
    }
  },
  computed: {
    ...mapGetters({
      advantagesContent: 'homepage/getAdvantagesContent'
    })
  },
  methods: {
    parseAdvantages () {
      const result = [];

      if (typeof document === 'undefined') {
        return;
      }

      const wrapper = document.createElement('div');
      wrapper.innerHTML = this.advantagesContent;

      const items = wrapper.querySelectorAll('.m-advantages__item');

      items.forEach(item => {
        const titleElement = item.querySelector('.m-advantages__item-title');
        const descElement = item.querySelector('.m-advantages__item-desc');

        if (titleElement && descElement) {
          result.push({
            title: titleElement.innerHTML,
            description: descElement.innerHTML
          });
        }
      });

      this.advantages = result;
    }

  },
  beforeCreate () {
    this.$nextTick(() => {
      this.parseAdvantages()
    })
  }
}
</script>

<style lang="scss">
@import "~theme/css/breakpoints";

.m-advantages {
  padding-top: var(--spacer-50);
  padding-bottom: var(--spacer-50);

  @include for-tablet {
    padding-top: var(--spacer-60);
    padding-bottom: var(--spacer-60);
    line-height: var(--spacer-34);
  }

  @include for-desktop {
    padding-top: var(--spacer-2xl);
    padding-bottom: var(--spacer-2xl);
    line-height: var(--spacer-34);
  }

  @include for-mobile {
    .home & {
      margin-left: calc(-1 * var(--spacer-10));
      margin-right: calc(-1 * var(--spacer-10));
    }
  }

  .glide {
    box-sizing: initial;
  }

  &-list {
    display: flex;
    justify-content: space-around;
  }

  &__item {
    display: block;
    text-align: center;
    margin: 0;

    &.ua-lang {
      @media (min-width: 404px) and (max-width: $tablet-min) {
        width: 60%;
        padding: 0 20%;
      }
    }

    &-title {
      display: block;
      margin-bottom: var(--spacer-xs);
      font-size: var(--font-xl);

      @media(min-width: $tablet-min) {
        font-size: 2.25rem;
      }
    }

    &-desc {
      display: block;
    }
  }

  .sfo-carousel__bullets li {
    &:nth-child(even) {
      display: none;
    }
  }
}
</style>
