<template>
  <div class="plug" id="plug">
    <AStatic :content="content" ref="stores" />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';

export default {
  name: 'Stores',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  }
}
</script>
