<template>
  <div
    ref="tastyJobFormRef"
    class="m-tasty-job-form"
    v-html="formHtml"
  />
</template>
<script>
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'

export default {
  name: 'MTastyJobForm',
  mixins: [customFormSpinnerLoading],
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  data () {
    return {
      formHtml: ''
    }
  },
  mounted () {
    if (this.isContentAvailable()) {
      const div = document.createElement('div')
      div.innerHTML = this.content.message.trim()
      this.formHtml = div.querySelector('.custom__form')?.outerHTML
    }
  },
  methods: {
    isContentAvailable () {
      return Array.isArray(this.content)
        ? this.content.length
        : this.content.title && this.content.message;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
::v-deep {
  .custom__form.custom__form-tasty-job {
    margin-top: 0;
    padding: 0 var(--spacer-10);

    @include only-mobile {
      padding-bottom: var(--spacer-50);
    }

    .custom-form__iframe {
      min-height: 690px;
    }

    .custom__form-block {
      min-width: inherit;

      @media (min-width: $tablet-min) {
        min-width: 450px;
      }
    }

    .custom__form-description {
      .vacancy-url {
        color: var(--orange);
      }

      p {
        margin: var(--spacer-10) 0 0 0;

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-15);
        }

        &:last-of-type {
          margin-bottom: var(--spacer-25);

          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-10);
          }
        }
      }
    }

    .sf-heading {
      display: block;
    }
  }
}
</style>
