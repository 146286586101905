<template>
  <div class="m-faq-block">
    <div itemscope="" itemtype="https://schema.org/FAQPage">
      <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
        <h3 itemprop="name">{{ $t('faq block question 1') }}</h3>
        <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
          <div itemprop="text">
            <span>{{ $t('faq block answer 1-1') }}</span>
            <SfLink :link="varusLink">
              {{ varusLinkText }}
            </SfLink>
            <span>{{ $t('faq block answer 1-2') }}</span>
          </div>
        </div>
      </div>
      <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
        <h3 itemprop="name">{{ $t('faq block question 2') }}</h3>
        <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
          <div itemprop="text">
            <div>{{ $t('faq block answer 2-1') }}</div>
            <div>{{ $t('faq block answer 2-2') }}</div>
          </div>
        </div>
      </div>
      <div itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
        <h3 itemprop="name">{{ $t('faq block question 3') }}</h3>
        <div itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
          <span>{{ $t('faq block answer 3-1') }}</span>
          <SfLink :link="varusLink">
            {{ varusLinkText }}
          </SfLink>
          <span>{{ $t('faq block answer 3-2') }}</span>
        </div>
      </div>
      <div>
        <div class="m-faq-block__rules">
          <span v-html="$t('faq block answer 4-1')" />
          <SfLink :link="varusPdfLink" target="_blank">
            {{ $t('faq block answer 4-2') }}
          </SfLink>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfLink } from '@storefront-ui/vue';

export default {
  name: 'MFaqBlock',
  components: { SfLink },
  data () {
    return {
      varusPdfLink: '/assets/files/odesa.pdf',
      varusLink: 'https://varus.ua',
      varusLinkText: 'varus.ua'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";

.m-faq-block {
  margin: 0 auto;
  padding: 0 var(--spacer-10);
  max-width: 1178px;
  font-size: var(--spacer-13);
  line-height: var(--spacer-19);
  color: var(--dark-gray);
  text-align: justify;

  h3 {
    margin-bottom: var(--spacer-6);
  }

  p {
    margin-top: var(--spacer-6);
    font-size: var(--spacer-14);
  }

  .sf-link {
    font-weight: var(--font-medium);
    color: var(--orange);
    display: inline-block;
  }

  &__rules {
    margin-top: var(--spacer-14);
  }
}
</style>
