<template>
  <div id="user-agreement">
    <AStatic :content="content" />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';

export default {
  name: 'UserAgreement',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#user-agreement {
  ::v-deep {
    .sf-heading {
      display: none;
    }

    .sf-breadcrumbs__list-item {
      padding-top: var(--spacer-12);
    }

    .content {
      padding-top: var(--spacer-10);
      padding-bottom: 0;
      @include for-desktop {
        padding-top: 0;
        max-width: 792px;
        margin: var(--spacer-40) auto 0;
      }
    }

    .title {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
      margin-bottom: var(--spacer-10);
      margin-top: 0;
      @include for-desktop {
        font-size: var(--font-size-32);
        line-height: var(--font-size-33);
        margin-bottom: var(--spacer-28);
      }
    }

    .items-list {
      margin: 0 auto var(--spacer-40);
      padding-left: var(--spacer-18);
      @include for-desktop {
        margin-bottom: 207px;
      }
    }

    .item-list {
      font-size: var(--font-sm);
      font-weight: var(--font-normal);
      margin-bottom: var(--spacer-10);
      padding-left: var(--spacer-13);
      line-height: var(--font-size-24);
      color: var(--black);
      &:last-child {
        margin-bottom: 0;
      }
      @include for-desktop {
        padding-left: var(--spacer-18);
        margin-bottom: var(--spacer-20);
      }
    }
  }
}
</style>
