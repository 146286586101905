<template>
  <div id="about-company">
    <AStatic :content="content" ref="aboutCompany" />
    <div id="company-history">
      <vue-masonry-wall
        class="history-steps"
        :ssr="{columns: 2}"
        :items="stepList"
        :options="{width: 390, padding: 5}"
      >
        <template v-slot:default="{ item }">
          <div class="history-step">
            <div class="sf-image img-history">
              <img :src="item.imageSrc" :alt="item.desc">
            </div>
            <p class="year">
              {{ item.year }}
            </p>
            <p class="year-description">
              {{ item.description }}
            </p>
            <p v-if="item.subDescription" class="sub-description">
              {{ item.subDescription }}
            </p>
          </div>
        </template>
      </vue-masonry-wall>
    </div>
  </div>
</template>

<script>
import MAdvantages from 'theme/components/molecules/m-advantages';
import MShopsPhotoGallery from 'theme/components/molecules/m-shops-photo-galery';
import AStatic from 'theme/components/atoms/a-static';
import Vue from 'vue';
import VueMasonryWall from 'vue-masonry-wall';

export default {
  name: 'AboutCompany',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    },
    images: {
      type: Array,
      default: null
    }
  },
  components: {
    AStatic,
    VueMasonryWall
  },
  data () {
    return {
      photoList: [],
      stepList: []
    };
  },
  methods: {
    getAboutItems () {
      const historySteps = this.$refs.aboutCompany.$el.querySelector('.history-steps');

      if (!historySteps) {
        return '';
      }
      let historyStep = historySteps.querySelectorAll('.history-step');
      historyStep.forEach((el, i) => {
        const step = {
          id: i,
          imageSrc: el.getElementsByTagName('img')[0]?.getAttribute('src') || '',
          year: el.querySelector('.year')?.textContent || '',
          description: el.querySelector('.year-description')?.textContent || '',
          subDescription: el.querySelector('.sub-description')?.textContent || ''
        }
        this.stepList.push(step);
      })
    },
    getGalleryImages () {
      const images = this.$refs.aboutCompany.$el.querySelector('#imagelist');

      if (!images) {
        return '';
      }

      let imageItems = images.querySelectorAll('.image-item');
      imageItems.forEach((el, i) => {
        const image = {
          id: i,
          src: el.getElementsByTagName('img')[0]?.getAttribute('src') || '',
          name: el.getElementsByTagName('img')[0]?.getAttribute('alt') || this.$t('Image-' + i)
        }
        this.photoList.push(image);
      })
    },

    /**
     * Mapping the content value from Magento admin panel (CMS Page) on VS component - MAdvantages
     */
    mapAdvantages () {
      const advantages = this.$refs.aboutCompany.$el.querySelector('#advantages');

      if (!advantages) {
        return '';
      }

      const Component = Vue.extend(MAdvantages);
      const componentInstance = new Component({
        i18n: this.$i18n,
        store: this.$store
      });
      componentInstance.$mount(advantages);
    },

    /**
     * Mapping the content value from Magento admin panel (CMS Page) on VS component - MShopsPhotoGallery
     */
    mapShopsPhotoGallery () {
      const photoGallery = this.$refs.aboutCompany.$el.querySelector('#shop-photogallery');

      if (!photoGallery) {
        return '';
      }

      const Component = Vue.extend(MShopsPhotoGallery);
      const componentInstance = new Component({
        i18n: this.$i18n,
        store: this.$store,
        propsData: {
          images: this.photoList
        }
      });
      componentInstance.$mount(photoGallery);
    }
  },

  mounted: function () {
    this.getGalleryImages();
    this.mapAdvantages();
    this.mapShopsPhotoGallery();
    this.getAboutItems();
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/breakpoints";
@import "~theme/css/mixins";

#about-company {
  ::v-deep {
    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }
    }

    // Banner
    .banner {
      color: var(--black);
      background: var(--yellow);
      display: flex;
      justify-content: space-between;
      font-family: var(--font-family-inter);
      margin: var(--spacer-5) var(--spacer-10) var(--spacer-30);
      min-height: 150px;
      overflow: hidden;

      @include for-desktop {
        max-width:  px2rem(990);
        margin: 0 auto;
        min-height: 250px;
      }
    }

    .description {
      flex: 1 0 190px;
      line-height: var(--spacer-22);
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--spacer-10);

      @include for-desktop {
        flex: 0 0 50%;
        gap: var(--spacer-25);
        line-height: var(--spacer-33);
      }
    }

    .banner-title {
      padding-bottom: 0;
    }

    .banner-title > .sf-heading__title {
      font-size: var(--font-size-18);
      line-height: inherit;
      margin:  0 0 0 var(--spacer-28);
      text-align: left;
      font-family: var(--font-family-headers);
      text-transform: uppercase;

      @include for-desktop {
        font-size: var(--font-size-32);
        margin: 0 0 0 px2rem(64);
      }
    }

    .banner-subtitle {
      font-size: var(--font-size-13);
      line-height: var(--spacer-16);
      font-weight: var(--font-medium);
      margin: 0 0 0 var(--spacer-28);

      @include for-desktop {
        font-size: var(--font-size-16);
        line-height: var(--spacer-23);
        margin: 0 0 0 px2rem(64);
      }
    }
    .img-shop {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: visible;
      margin-right: var(--spacer-28);

      @include for-desktop {
        margin-right: px2rem(64);
      }
    }
    .img-shop > img {
      display: block;
      max-width: px2rem(168);
      width: 100%;

      @include for-desktop {
        max-width: px2rem(323);
        object-fit: contain;
      }
    }

    .center {
      padding: 0 var(--spacer-13);
      max-width: px2rem(790);
      margin: 0 auto;

      @include for-desktop {
        margin-top: var(--spacer-48);
        padding: 0;
      }

      .additional-info {
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);
        margin-bottom: var(--spacer-46);

        @include for-desktop {
          margin-bottom: var(--spacer-60);
        }
      }
    }

    .company-description {
      color: var(--dark-gray);
      font-weight: normal;
      font-size: var(--font-size-16);
      line-height: var(--spacer-26);

      @include for-desktop {
        font-size: var(--font-size-18);
        margin-top: var(--spacer-30);
      }
    }

    dt,
    dd {
      display: inline;
    }

    dd {
      margin-left: 0;
    }

    .title > h2 {
      @include header-title;
    }

    .m-advantages {
      padding: 0;
      margin: var(--spacer-25) auto var(--spacer-30);

      @include for-desktop {
        border: 1px solid var(--line-gray);
        padding: var(--spacer-32) 0;
        margin: var(--spacer-30) auto;
      }

      .sf-carousel__wrapper {
        border: 1px solid var(--line-gray);
        padding: var(--spacer-32) 0 var(--spacer-42);
      }

      .sf-carousel-item {
        &:nth-child(2n) {
          border-right: 1px solid var(--line-gray);
          border-left: 1px solid var(--line-gray);
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .m-advantages {
      .m-advantages__item {
        padding: 0 var(--spacer-10);
        flex-grow: 1;

        .m-advantages__item-desc {
          color: var(--black);
        }

        &:nth-child(2n) {
          border-right: 1px solid var(--line-gray);
          border-left: 1px solid var(--line-gray);
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    //Manager block
    .mass-media {
      background-color: var(--light-gray);
      display: flex;
      width: 100%;

      @include for-desktop {
        height: 200px;
      }

      .additional-wrapper {
        display: flex;
        justify-content: flex-start;
        position: relative;
        flex: 1;

        @include for-desktop {
          overflow: visible;
        }
      }

      .links-wrapper {
        box-sizing: border-box;
        z-index: 1;
        width: 100%;

        .title {
          margin-right: px2rem(130);

          & > h2 {
            color: var(--black);
            font-family: var(--font-family-inter);
            text-transform: none;
            font-size: var(--font-size-20);
            font-weight: 500;
            line-height: 24px;
            margin: var(--spacer-30) 0 var(--spacer-25) var(--spacer-35);

            @include for-desktop {
              margin: var(--spacer-40) 0 var(--spacer-22) var(--spacer-50);
            }
          }

          @media (min-width: $tablet-min) {
            margin-right: px2rem(250);
          }
        }

        @include for-desktop {
          border-right-color: transparent;
          margin: auto;
        }
      }

      .sf-button {
        display: inline-block;
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);
        margin-left: var(--spacer-30);
        padding: var(--spacer-13) var(--spacer-16);

        &.sf-button--primary {
          span {
            color: var(--white);
          }
        }

        @include for-desktop {
          margin-left: var(--spacer-50);
          padding: var(--spacer-7) var(--spacer-16);
        }
      }

      .sf-button--outline {
        margin: var(--spacer-15) 0 var(--spacer-20) var(--spacer-30);
        background-color: var(--light-gray);

        @include for-desktop {
          margin-left: var(--spacer-10);
        }
      }
    }

    .img-manager {
      position: absolute;
      right: -100px;
      bottom: 0;
      padding-left: px2rem(45);

      & > img {
        display: block;
        height: 200px;
        margin-left: px2rem(75);

        @media (min-width: $tablet-min) {
          height: 230px;
        }

        @include for-desktop {
          height: auto;
        }
      }

      @include for-desktop {
        right: -20px;
      }
    }

    .section-title {
      margin: var(--spacer-50) 0 var(--spacer-20);
      padding-bottom: 0;

      & > h2 {
        color: var(--black);
        font-size: var(--font-size-20);
        font-weight: 500;
        text-align: left;
      }

      @include for-desktop {
        font-weight: 500;
        margin: var(--spacer-80) 0 var(--spacer-25);
        padding-bottom: 0;
        text-align: left;

        & > h2 {
          font-size: var(--font-size-32);
        }
      }
    }
    .company-history {
      display: none;
    }
  }

  //History block
  #company-history {
    width: 100%;
    margin: 0 auto;
    max-width: calc(100% - 20px);
    @include for-tablet {
      max-width: calc(100% - 17px)
    }

    @include for-desktop {
      position: relative;
      width: 790px;
      max-width: 100%;
    }

    ::v-deep {
      .masonry-column {
        .masonry-item {

          &:first-child {
            @media (max-width: $tablet-min) {
              background: transparent;
            }
          }

          &:nth-child(2) {
            @media (max-width: 595px) {
              .history-step {
                padding: 0;
                background: transparent;
                .year {
                  position: relative;
                  font-size: var(--font-size-20);
                  line-height: var(--font-size-24);
                  color: var(--black);
                  font-weight: 500;
                  margin: 0;
                }
              }
            }
          }
        }

        &:first-child {
          .masonry-item {
            &:first-child {
              .history-step {
                padding: 0;
                background: transparent;
              }
            }
          }
        }

        &:nth-child(2) {
          .masonry-item {
            &:first-child {
              .history-step {
                padding: 0;
                background: transparent;
                .year {
                  position: relative;
                  font-size: var(--font-size-32);
                  line-height: var(--font-size-33);
                  color: var(--black);
                  font-weight: 500;
                  margin-bottom: 58px;
                }
              }
            }
          }
        }
      }
    }

    .history-steps {
      padding-left: 0;
      list-style: none;
      .history-step {
        img {
          margin: 0 auto;
        }
      }
    }

    .history-step {
      background: var(--light-gray);
      display: flex;
      flex-wrap: wrap;
      padding: var(--spacer-20) 0 var(--spacer-28);
      @include for-mobile {
        width: 100%;
      }

      @include for-desktop {
        display: inline-block;
        margin: 0 auto;
        padding: var(--spacer-30) 0;
        position: relative;
      }
    }

    .img-history {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      padding: 0 var(--spacer-20);
      object-fit: contain;

      @include for-desktop {
        padding: 0 var(--spacer-30);
        img {
          display: block;
        }
      }

      @include for-desktop {
        margin-bottom: var(--spacer-30);
      }
    }

    .year {
      margin: var(--spacer-15) 0 0 var(--spacer-20);
      font-weight: 500;
      font-size: var(--font-size-40);
      width: 100%;

      @include for-tablet {
        font-size: px2rem(36);
        margin: var(--spacer-15) var(--spacer-40) var(--spacer-10);
      }

      @include for-desktop {
        display: inline-block;
        font-size: var(--font-size-50);
        line-height: var(--spacer-60);
        margin: 0 var(--spacer-40) var(--spacer-10);
      }
    }

    .year-description {
      margin: var(--spacer-5) var(--spacer-20) 0;
      font-weight: normal;
      font-size: var(--font-size-16);
      width: 100%;

      @include for-tablet {
        font-weight: 400;
        font-size: var(--font-size-20);
        line-height: var(--font-size-26);
        color: var(--dark-gray);
        margin: 0 var(--spacer-40);
      }

      @include for-desktop {
        color: var(--dark-gray);
        font-size: var(--font-size-20);
        line-height: var(--spacer-26);
        margin: 0 var(--spacer-40);
        width: auto;
      }
    }

    .sub-description {
      color: var(--dark-gray);
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size-14);
      margin: var(--spacer-5) var(--spacer-20) 0;
      width: 100%;

      @include for-tablet {
        margin: var(--spacer-10) var(--spacer-40) 0;
      }

      @include for-desktop {
        line-height: var(--spacer-16);
        margin: 0 var(--spacer-40);
        margin-top: var(--spacer-10);
        width: px2rem(310);
      }
    }
  }
}
</style>
