<template>
  <div>
    <AStatic
      :content="content"
      custom-class="tasty-job-page-form"
    />
    <MTastyJobForm
      ref="tastyJobPageFrom"
      :content="content"
    />
  </div>
</template>
<script>
import MTastyJobForm from 'theme/components/molecules/m-tasty-job-form'

export default {
  name: 'TastyJobPageForm',
  components: {
    MTastyJobForm
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  mounted () {
    const getRefFrom = this.$refs.tastyJobPageFrom.$el

    const getContent = document.querySelector('.content')
    if (getContent) {
      getContent.append(getRefFrom)
    }
  },
  methods: {
    isContentAvailable () {
      return Array.isArray(this.content)
        ? this.content.length
        : this.content.title && this.content.message;
    }
  }
}
</script>
