<template>
  <div id="help-center">
    <AStatic :content="content" @click.native="handleLinkClick" />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';

export default {
  name: 'HelpCenter',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  },
  methods: {
    handleLinkClick (e) {
      if (e.target.matches('a')) {
        e.preventDefault();

        this.$router.push(this.localizedRoute('/' + e.target.getAttribute('href').replace(/^\//, '')));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@storefront-ui/shared/styles/helpers/breakpoints';
@import '~theme/css/fonts';
@import "~theme/css/mixins";

#help-center {
  ::v-deep {
    .content {
      padding: 0 var(--spacer-10);
    }

    .sf-heading {
      display: none;
    }

    header {
      max-width: 890px;
      margin: 0 auto;
      width: 100%;

      & > .title {
        margin: var(--spacer-13) 0 var(--spacer-25);
        @include header-title;

        @include for-desktop {
          margin: var(--spacer-20) auto var(--spacer-30) var(--spacer-50);
        }
      }
    }

    .most-popular-questions {
      border-top: 1px solid var(--dark-orange);
      border-bottom: 1px solid var(--dark-orange);
      display: flex;
      max-width: 890px;
      margin: 0 auto;
      padding: var(--spacer-30) 0 var(--spacer-37);
      width: 100%;

      @include for-desktop {
        border: 1px solid var(--dark-orange);
        box-sizing: border-box;
        padding: var(--spacer-50) var(--spacer-22) var(--spacer-15) var(--spacer-50);

      }
    }

    .image-wrapper {
      background-color: var(--light-gray);
      border-radius: 50%;
      height: var(--spacer-50);
      width: var(--spacer-50);

      @include font-icon(var(--icon-fire));

      &:before {
        display: block;
        height: var(--spacer-50);
        width: var(--spacer-50);
        text-align: center;
        line-height: var(--font-size-50);
        font-size: var(--font-size-22);
        color: var(--orange);
      }
    }

    .question-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: var(--spacer-15);
      margin-bottom: 0;

      p {
        font-size: var(--font-size-16);
        font-weight: 500;
        line-height: var(--spacer-20);
        margin: 0 auto var(--spacer-20);
        width: 100%;

        @include for-desktop {
          font-size: var(--font-size-24);
          line-height: var(--spacer-24);
          margin-bottom: var(--spacer-33);
        }
      }
    }

    .question {
      display: flex;
      font-size: var(--font-size-13);
      font-weight: 500;
      line-height: var(--spacer-13);
      margin: 0 auto var(--spacer-15);
      align-items: baseline;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
      @include for-desktop {
        &:last-child {
          margin-bottom: var(--spacer-15);
        }
      }

      &:before {
        content: '';
        background-color: var(--orange);
        border-radius: 50%;
        display: inline-block;
        height: var(--spacer-8);
        width: var(--spacer-8);
        margin-right: var(--spacer-20);
      }

      @include for-desktop {
        font-size: var(--font-size-16);
        line-height: var(--spacer-17);
        flex: 1 1 50%;
        margin: 0 0 var(--spacer-35) 0;
      }
    }

    .other-topics {
      display: flex;
      margin: var(--spacer-30) var(--spacer-10) 0;

      @include for-desktop {
        margin-top: var(--spacer-50);
      }
    }

    .topic-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      margin: 0 auto;
      max-width: 808px;
      padding-left: 0;
      width: 100%;

      @include for-desktop {
        justify-content: flex-start;
      }
    }

    .topic {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: var(--spacer-30);
      width: 100%;
      @include for-desktop {
        max-width: 370px;
        margin-right: var(--spacer-34);
      }

      &:last-child {
        margin-bottom: var(--spacer-50);

        @include for-desktop {
          margin-bottom: var(--spacer-90);
        }
      }
    }

    .topic-title {
      display: flex;
      align-items: center;
      font-size: var(--font-size-16);
      font-weight: 500;
      line-height: var(--spacer-20);
      color: var(--black);

      &:hover {
        color: var(--orange);
      }

      &:before {
        display: block;
        background-color: var(--light-gray);
        border-radius: 50%;
        margin-right: var(--spacer-15);
        height: var(--spacer-50);
        width: var(--spacer-50);
        line-height: var(--font-size-50);
        text-align: center;
        color: var(--orange);
        font-size: var(--font-size-24);
      }

      &:after {
        content: "";
        display: inline;
        border: 2px solid var(--orange);
        border-left-width: 0;
        border-top-width: 0;
        position: relative;
        height: 6px;
        width: 6px;
        top: 0;
        left: 12px;
        transform: rotate(-45deg);
        margin: auto 0;
        transition: transform 0.1s ease, -webkit-transform 0.1s ease;
        cursor: pointer;
      }

      @include for-desktop {
        font-size: var(--font-size-24);
        line-height: var(--spacer-27);
      }
    }

    .payment {
      @include font-icon(var(--icon-credit-card));

      &:before {
        font-size: var(--font-size-18);
      }
    }

    .delivery {
      @include font-icon(var(--icon-delivery));

      &:before {
        font-size: var(--font-size-17);
      }
    }

    .help-ordering {
      @include font-icon(var(--icon-cart));
    }

    .bonus-account {
      @include font-icon(var(--icon-logo-flower));
    }

    .promotions-and-subscriptions {
      @include font-icon(var(--icon-promotions));
    }

    .personal-account {
      @include font-icon(var(--icon-user));
    }

    .warranty-returns {
      @include font-icon(var(--icon-warranty));
    }

    .short-description {
      color: var(--dark-gray);
      font-size: var(--font-size-13);
      line-height: var(--spacer-22);
      margin: 0 0 0 var(--spacer-65);

      @include for-desktop {
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);
      }
    }
  }
}
</style>
