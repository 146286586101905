import { render, staticRenderFns } from "./OwnTm.vue?vue&type=template&id=728ded63&scoped=true"
import script from "./OwnTm.vue?vue&type=script&lang=js"
export * from "./OwnTm.vue?vue&type=script&lang=js"
import style0 from "./OwnTm.vue?vue&type=style&index=0&id=728ded63&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728ded63",
  null
  
)

export default component.exports