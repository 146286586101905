<template>
  <div class="blog-posts">
    <div v-for="(post, i) in posts" :key="i">
      <MBlogPost
        :post-id="post.id"
        :img="post.featured_img"
        :views="post.views_count"
        :title="post.title"
        :date="post.publish_time"
        :like="post.upvote"
        :dislike="post.downvote"
        :identifier="post.identifier"
      />
    </div>
    <template v-if="isLoadMoreMode">
      <AProductPlaceholder
        v-for="index in initPlaceholderCount"
        :key="`${index}_placeholder`"
        class="m-category-list__item placeholder-item-svg"
      />
    </template>
  </div>
</template>
<script>
import MBlogPost from 'theme/components/molecules/m-blog-post'
import AProductPlaceholder from 'theme/components/atoms/a-product-placeholder';

export default {
  name: 'MBlogPostWrapper',
  components: {
    MBlogPost,
    AProductPlaceholder
  },
  props: {
    isLoadMoreMode: {
      type: Boolean,
      default: true
    },
    posts: {
      type: Array
    }
  },
  data () {
    return {
      placeholderCount: 24
    }
  },
  computed: {
    initPlaceholderCount () {
      const countProduct = this.posts.length;

      return countProduct > 0 ? countProduct : this.placeholderCount;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.a-category-placeholder {
  max-height: px2rem(125);
  overflow: hidden;

  @media (min-width: $tablet-min) {
    max-height: px2rem(170);
  }
}
</style>
