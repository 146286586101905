<template>
  <div class="sf-tabs">
    <div class="tabs-header">
      <button
        v-for="(link, i) in tabLinks"
        :key="'tab_link_' + i"
        class="sf-button sf-button--pure tabs-header__title"
        :class="{ 'tabs-header__title--active': getIsActive(i) }"
        data-transaction-name="Tab"
        @click="tabClick(i)"
      >
        {{ getTabTitle(link) }}
      </button>
    </div>
    <slot />
  </div>
</template>
<script>
import Vue from 'vue';
import SfTab from '@storefront-ui/vue/src/components/organisms/SfTabs/_internal/SfTab.vue';

Vue.component('SfTab', SfTab);

export default {
  name: 'SfOTabs',
  props: {
    /** Which tab should be open at the beginning  */
    openTab: {
      type: Number,
      default: 1
    },
    /** Max height of visible content  */
    tabMaxContentHeight: {
      type: String,
      default: ''
    },
    /** Text for button showing content  */
    tabShowText: {
      type: String,
      default: 'show'
    },
    /** Text for button hiding content  */
    tabHideText: {
      type: String,
      default: 'hide'
    },
    /** Text for tab buttons  */
    tabLinks: {
      type: Array,
      default: () => []
    },
    customClick: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      openedTabNum: 1
    }
  },
  mounted () {
    this.$on('toggle', this.toggle);
    if (this.openTab) {
      this.openedTabNum = this.openTab
      this.openChild();
    }
  },
  computed: {
    tabsNumber () {
      return this.tabLinks.length
    }
  },
  methods: {
    tabClick (tabNum) {
      this.$children[tabNum].tabClick();
      this.openedTabNum = tabNum + 1
      this.$emit('tabClick', this.openedTabNum)
    },
    toggle (id) {
      if (this.customClick) {
        this.$emit('add-click');
      }
      this.$children.forEach((child) => {
        child._uid === id
          ? (child.isActive = !child.isActive)
          : (child.isActive = false);
      });
    },
    openChild () {
      if (this.openTab < this.$children.length + 1) {
        this.$children[this.openTab - 1].isActive = true;
      }
    },
    getIsActive (tabNum) {
      return tabNum + 1 === this.openedTabNum
    },
    getTabTitle (link) {
      const number = link.number === undefined ? '' : ' (' + link.number + ')'
      return this.$t(link.title) + number
    }
  },
  provide: function () {
    const tabConfig = {};
    Object.defineProperty(tabConfig, 'tabMaxContentHeight', {
      get: () => this.tabMaxContentHeight
    });
    Object.defineProperty(tabConfig, 'tabShowText', {
      get: () => this.tabShowText
    });
    Object.defineProperty(tabConfig, 'tabHideText', {
      get: () => this.tabHideText
    });
    return {
      tabConfig
    };
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfTabs.scss";
@import 'theme/css/breakpoints';
.sf-tabs {
  .tabs-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }

    &__title {
      white-space: nowrap;
      background: var(--tabs-title-background);
      color: var(--tabs-title-color);
      font-size: var(--font-size-16);
      font-weight: var(--font-medium);
      padding: var(--spacer-10) 0;
      margin-right: var(--spacer-30);

      :last-child {
        margin-right: 0;
      }

      &--active {
        --tabs-title-color: var(--orange);
        border-bottom: 3px solid var(--orange);
      }
    }
  }

  .sf-tabs__title--active + .sf-tabs__content {
    border-top: 1px solid var(--gray3);
  }
}
</style>
