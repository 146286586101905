<template>
  <nav class="sf-pagination">
    <!-- @slot Custom markup for previous page button -->
    <slot name="prev" v-bind="{ isDisabled: !canGoPrev, prev: getPrev }">
      <div
        class="sf-pagination__item sf-pagination__item--prev"
        :class="{
          'sf-pagination__item--prev--disable': !canGoPrev
        }"
      >
        <component
          :is="componentIs"
          v-if="canGoPrev"
          :class="{
            'sf-link': hasRouter,
            'sf-button--pure': !hasRouter
          }"
          :href="checkHasRouter(getPrev)"
          aria-label="Go to previous page"
          data-transaction-name="Pagination - Prev"
          @click.prevent.stop="goTo(getPrev)"
        />
      </div>
    </slot>
    <template v-if="showFirst">
      <slot name="number" v-bind="{ page: 1 }">
        <component
          :is="componentIs"
          class="sf-pagination__item"
          :class="{
            'sf-link': hasRouter,
            'sf-button--pure': !hasRouter,
            'sf-pagination__item--was': pagesChain.includes(1)
          }"
          :href="getLinkTo(1)"
          data-transaction-name="Pagination - Go To First"
          @click.prevent.stop="goTo(1)"
        >
          <span @click="clearSelectedPages">1</span>
        </component>
      </slot>
      <slot name="points">
        <div
          class="sf-pagination__item"
          :class="{
            'sf-pagination__item--was': leftDotsWas
          }"
          v-if="showLeftDots"
        >
          ...
        </div>
      </slot>
    </template>
    <template v-for="page in limitedPageNumbers">
      <component
        :is="currentPage === page ? 'span' : componentIs"
        :key="page"
        class="sf-pagination__item"
        :class="{
          'sf-link': hasRouter,
          'sf-button--pure': !hasRouter && currentPage !== page,
          'sf-pagination__item--current': currentPage === page,
          'sf-pagination__item--was': pagesChain.includes(page),
        }"
        :href="currentPage !== page ? getLinkTo(page) : null"
        data-transaction-name="Pagination - Go To Page"
        @click.prevent.stop="currentPage !== page ? goTo(page) : null"
      >
        <span @click="clearSelectedPages">{{ page }}</span>
      </component>
    </template>
    <template v-if="showLast">
      <slot name="points">
        <div class="sf-pagination__item" v-if="showRightDots">
          ...
        </div>
      </slot>
      <slot name="number" v-bind="{ page: total }">
        <component
          :is="componentIs"
          class="sf-pagination__item"
          :class="{
            'sf-link': hasRouter,
            'sf-button--pure': !hasRouter,
          }"
          :href="getLinkTo(total)"
          data-transaction-name="Pagination - Go To Last"
          @click.prevent.stop="goTo(total)"
          @click.native="onNavClick"
        >
          <span>{{ total }}</span>
        </component>
      </slot>
    </template>
    <!-- @slot Custom markup for previous page button -->
    <slot name="next" v-bind="{ isDisabled: !canGoNext, next: getNext }">
      <div class="sf-pagination__item sf-pagination__item--next"
           :class="{
             'sf-pagination__item--next--disable': !canGoNext
           }"
      >
        <component
          :is="componentIs"
          v-if="canGoNext"
          :class="{
            'sf-link': hasRouter,
            'sf-button--pure': !hasRouter,
          }"
          :href="getLinkTo(getNext)"
          aria-label="Go to previous next"
          data-transaction-name="Pagination - Go To Next"
          @click.prevent.stop="goTo(getNext)"
          @click.native="onNavClick"
        />
      </div>
    </slot>
    <SfButton
      class="products__load-more sf-link--primary"
      v-if="total > currentPage"
      data-transaction-name="Pagination - Load More"
      @click="loadMoreFromPag"
    >
      <span>
        {{ $t('Show more {amount} products', { amount: perPage }) }}
      </span>
    </SfButton>
  </nav>
</template>
<script>
import { SfButton } from '@storefront-ui/vue';
import { isServer } from '@vue-storefront/core/helpers';
import {
  createSmoothscroll
} from 'theme/helpers';

export default {
  name: 'SfOPagination',
  components: {
    SfButton
  },
  props: {
    /**
     * Total number of pages
     */
    total: {
      type: Number,
      default: 0
    },
    /**
     * Maximum visible pagination items
     */
    visible: {
      type: Number,
      default: 3
    },
    /**
     * Status of arrows display
     */
    hasArrows: {
      type: Boolean,
      default: true
    },
    /**
     * Scroll top on page changed
     */
    scrollTop: {
      type: Boolean,
      default: false
    },
    /**
     * Current page number, for non router
     */
    current: {
      type: Number,
      default: 1
    },
    /**
     * Name of page query param for router
     */
    pageParamName: {
      type: String,
      default: 'page'
    },
    perPage: {
      type: Number,
      default: 40
    },
    countRangePages: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showMore: false
    }
  },
  computed: {
    pagesChain () {
      if (this.showMore) {
        return [...this.countRangePages, this.currentPage];
      } else {
        return [this.currentPage];
      }
    },
    hasRouter () {
      return this.$route && this.pageParamName !== false;
    },
    componentIs () {
      return this.hasRouter ? 'a' : SfButton;
    },
    currentPage () {
      if (this.showMore) {
        const current = this.countRangePages.sort()
        return current[this.countRangePages.length - 1] || this.current
      }

      if (!this.hasRouter) return this.current

      if (this.$route.query[this.pageParamName] === undefined) {
        return this.$route.params[this.pageParamName]
          ? parseInt(this.$route.params[this.pageParamName], 10)
          : 1
      }

      return this.$route.query[this.pageParamName]
        ? parseInt(this.$route.query[this.pageParamName], 10)
        : 1
    },
    getPrev () {
      return this.currentPage - 1;
    },
    canGoPrev () {
      return this.getPrev > 0 && !this.pagesChain.some((page) => page < this.currentPage);
    },
    getNext () {
      return this.currentPage + 1;
    },
    canGoNext () {
      return this.getNext <= this.total;
    },
    showFirst () {
      return this.firstVisiblePageNumber > 1;
    },
    showLast () {
      return this.lastVisiblePageNumber < this.total;
    },
    listOfPageNumbers () {
      return Array.from(Array(this.total), (_, i) => i + 1);
    },
    limitedPageNumbers () {
      if (this.total <= this.visible) {
        return this.listOfPageNumbers;
      }
      if (this.currentPage < this.visible - Math.floor(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(0, this.visible);
      }
      if (this.total - this.currentPage < this.visible - Math.ceil(this.visible / 2) + 1) {
        return this.listOfPageNumbers.slice(this.total - this.visible);
      }
      return this.listOfPageNumbers.slice(
        this.currentPage - Math.ceil(this.visible / 2),
        this.currentPage + Math.floor(this.visible / 2)
      );
    },
    firstVisiblePageNumber () {
      return this.limitedPageNumbers[0];
    },
    lastVisiblePageNumber () {
      return this.limitedPageNumbers[this.limitedPageNumbers.length - 1];
    },
    showLeftDots () {
      return this.firstVisiblePageNumber > 2;
    },
    leftDotsWas () {
      return this.pagesChain.some((page) => page > 1 && page < this.firstVisiblePageNumber);
    },
    showRightDots () {
      return this.total - this.lastVisiblePageNumber > 1;
    }
  },
  methods: {
    checkHasRouter (link) {
      return this.hasRouter ? this.getLinkTo(link) : null
    },
    loadMoreFromPag () {
      this.showMore = true;
      this.$store.dispatch('category-extension/clickLoadMore', { enable: true });
      this.$emit('loadMoreFromPagEmit');
    },
    clearSelectedPages () {
      this.countRangePages.length = 0;
    },
    getLinkObject (page) {
      const query = { ...this.$route.query }

      if (+page === 1 && query[this.pageParamName]) {
        delete query[this.pageParamName]
      }

      if (+page !== 1) {
        query[this.pageParamName] = page
      }

      return {
        ...this.$route,
        query,
        params: { ...this.$route.params, loadMore: false, page }
      };
    },
    getLinkTo (page) {
      if (!this.hasRouter) return null

      const link = this.getLinkObject(page)

      return this.$router.resolve(link).href;
    },
    onNavClick () {
      this.$store.dispatch('category-extension/clickLoadMore', { enable: false });
      this.$emit('countRangePagesEmit');
    },
    scrollToTop () {
      if (isServer || !this.scrollTop) return

      createSmoothscroll(document.documentElement.scrollTop || document.body.scrollTop, 0);
    },
    goTo (page) {
      this.showMore = false;

      this.scrollToTop();

      if (!this.hasRouter) return this.$emit('click', page)

      const link = this.getLinkObject(page);
      this.$router.push(link);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~@storefront-ui/shared/styles/components/molecules/SfPagination.scss";

.sf-pagination {
  padding-block: var(--spacer-25);

  &__item {
    color: var(--black);
    font-size: var(--font-sm);
    margin-inline: var(--spacer-20);

    @include for-mobile {
      margin-inline: var(--spacer-sm);
    }

    @include for-desktop {
      margin-inline: var(--spacer-22);
    }

    &--was,
    &--current {
      position: relative;
      z-index: 2;
      color: var(--black)!important;

      &::before {
        aspect-ratio: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        content: '';
        border-radius: 50%;
        background-color: var(--color-zircon);
        width: 2.75rem;
        z-index: -1;

        @include for-mobile {
          width: 2.5rem;
        }
      }
    }

    &--was {
      pointer-events: none;
      cursor: not-allowed;
    }

    &--prev,
    &--next {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      &--disable {
        pointer-events: none;
      }

      &:empty {
        position: relative;
        pointer-events: none;
        cursor: not-allowed;
      }

      .sf-link {
        position: relative;
        min-width: var(--spacer-30);
        height: var(--spacer-20);

        &:after {
          left: 50%;
        }
      }

      &:empty,
      .sf-link {
        &:after {
          $border: var(--spacer-2) solid var(--black);

          content: '';
          position: absolute;
          top: 50%;

          width: var(--spacer-3);
          height: var(--spacer-3);

          border-left: $border;
          border-bottom: $border;
        }
      }
    }

    &--prev {
      &:empty:after {
        transform: translateY(-50%) rotate(45deg);
      }

      .sf-link:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &--next {
      &:empty:after {
        transform: translateY(-50%) rotate(225deg);
      }

      .sf-link:after {
        transform: translate(-50%, -50%) rotate(225deg);
      }
    }

    &.sf-link:hover {
      position: relative;

      span {
        position: relative;
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        content: '';
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        background-color: var(--color-zircon);
      }
    }
  }

  &.products__pagination {
    justify-content: center;
    margin-left: 0;

    @include for-tablet {
      justify-content: flex-end;
    }

    @include for-desktop {
      justify-content: flex-end;
    }
  }

  .products__load-more {
    position: absolute;
    top: var(--spacer-20);
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    background-color: transparent;
    color: var(--orange);
    font-size: var(--font-sm);
    font-weight: var(--font-normal);

    @include for-tablet {
      position: absolute;
      top: 50%;
      left: var(--spacer-30);
      transform: translate(0, -50%);
    }

    @include for-desktop {
      position: absolute;
      top: 50%;
      left: var(--spacer-30);
      transform: translate(0, -50%);
    }
  }
}
</style>
