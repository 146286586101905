<template>
  <div
    data-transaction-name="Scroll To User Form"
    @click="scrollToUserForm"
    v-html="banner"
  />
</template>
<script>
export default {
  name: 'MRentBanner',
  props: {
    banner: {
      type: String
    }
  },
  methods: {
    scrollToUserForm () {
      this.$bus.$emit('rent-user-form', 'rentUserForm')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

::v-deep .rent__banner {
  margin: 0 auto;
  cursor: pointer;
  --banner-container-width: 95%;
  --banner-height: 150px;
  height: 150px;
  --banner-width: 100%;
  min-width: 355px;
  max-width: 990px;
  padding: px2rem(34) 3rem px2rem(50) px2rem(30);
  position: relative;
  background: linear-gradient(94.39deg, #E6EEF2 12.72%, #BDD9E8 90.6%);
  margin-bottom: 22px;

  &::before {
    content: '';
    background: url("/assets/rent/banner-m.png") no-repeat right;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;

    @media (min-width: ($tablet-min)) {
      background: url("/assets/rent/banner-d.png") no-repeat right;
    }
  }

  @media (min-width: ($tablet-min)) {
    --banner-container-width: 75%;
    --banner-height: 250px;
    height: 250px;
  }

  .sf-banner__title {
    max-width: 425px;
    --banner-title-font-size: var(--font-size-18);
    --banner-title-font-line-height: var(--font-size-22);
    --banner-title-font-weight: var(--font-medium);
    --banner-color: var(--black);
    --banner-title-font-family: var(--font-family-inter);
    --banner-title-text-transform: none;
    z-index: 1;

    @media (min-width: ($tablet-min)) {
      margin-left: var(--spacer-50);
      --banner-title-font-line-height: var(--font-size-33);
      --banner-title-font-size: var(--font-size-32);
    }
  }
}
</style>
