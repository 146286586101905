<template>
  <div class="novelty">
    <SfHeading
      :title="$t('Everything new')"
      :level="1"
    />
    <MNewProductList
      :is-loading="loading"
      :products="newProducts"
      class-list="products__grid new-product-list"
    />
    <SfButton
      v-show="isShowMoreVisible"
      class="products__load-more"
      data-transaction-name="Novelty - Load More"
      @click="loadMoreFromPag"
    >
      <span>
        {{ $t('Show more {amount} products', { amount: showMoreCount }) }}
      </span>
    </SfButton>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MNewProductList from 'theme/components/molecules/m-new-product-list';
import { SfHeading, SfButton } from '@storefront-ui/vue';

const THEME_PAGE_SIZE = 24;

export default {
  name: 'Novelty',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    MNewProductList,
    SfHeading,
    SfButton
  },
  data () {
    return {
      currentPage: 1,
      perPage: THEME_PAGE_SIZE
    }
  },
  mounted () {
    this.loadVarusNewProducts({ pageSize: this.perPage, currentPage: 1, loadMore: false })
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.loadVarusNewProducts({ pageSize: this.perPage, currentPage: 1, loadMore: false })
      }
    }
  },
  computed: {
    ...mapGetters({
      getVarusNewProducts: 'category-extension/getVarusNewProducts',
      getNewProductsTotal: 'category-extension/getNewProductsTotal',
      newProductsLoading: 'category-extension/getNewProductsLoading'
    }),
    newProducts () {
      return this.getVarusNewProducts || []
    },
    loading () {
      return this.newProductsLoading
    },
    showMoreCount () {
      const dif = this.getNewProductsTotal - this.newProducts.length
      return dif > this.perPage ? this.perPage : dif
    },
    isShowMoreVisible () {
      return this.getNewProductsTotal > this.newProducts.length
    }
  },
  methods: {
    ...mapActions({
      loadVarusNewProducts: 'category-extension/loadVarusNewProducts'
    }),
    async loadMoreFromPag () {
      this.currentPage += 1
      this.loadVarusNewProducts({
        pageSize: this.perPage,
        currentPage: this.currentPage,
        loadMore: true
      })
    }
  }
}
</script>

<style lang="scss">
  @import "~theme/css/breakpoints";
  @import "~theme/css/mixins";

  .novelty {
    padding: 0 var(--spacer-10);

    @media (min-width: $tablet-min) {
      padding: 0 var(--spacer-17);
    }

    @media (min-width: $desktop-min) {
      padding: 0;
    }

    .sf-heading {
      text-align: left;
      margin-bottom: var(--spacer-7);
      padding: 0;

      @media (min-width: $tablet-min) {
        margin-bottom: var(--spacer-20);
      }
    }
    .sf-heading__title {
      @include header-title;
    }

    .products__load-more {
      margin: 0 auto var(--spacer-40);
    }
  }

</style>
