<template>
  <div
    class="rent-form"
    v-html="form"
    ref="rentUserForm"
  />
</template>
<script>
import { email } from 'vuelidate/lib/validators'
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'

export default {
  name: 'ORentSubmitForm',
  mixins: [customFormSpinnerLoading],
  props: {
    form: {
      type: String,
      default: ''
    }
  },
  beforeMount () {
    this.$bus.$on('rent-user-form', this.goToForm)
  },
  beforeDestroy () {
    this.$bus.$off('rent-user-form')
  },
  methods: {
    goToForm (refName) {
      const element = this.$refs[refName]
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  },
  validations: {
    email: {
      email
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.static .rent-form {
  ::v-deep {
    .custom__form {
      overflow: hidden;
    }

    .custom__form,
    .custom-form__iframe {
      min-height: inherit;
      height: px2rem(598);

      @include for-desktop {
        height: px2rem(511);
      }

      @include for-tablet {
        height: px2rem(610);
      }
    }
  }
}
</style>
