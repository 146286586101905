<template>
  <div class="a-category-placeholder">
    <svg width="460" fill="none" xmlns="http://www.w3.org/2000/svg" height="500" viewBox="0 0 460 500">
      <rect fill="#EEEEEE" rx="5" x="10" height="40" width="80%" y="270" />
      <rect fill="#EEEEEE" rx="5" x="10" height="40" width="40%" y="300" />
      <rect fill="#EEEEEE" rx="5" x="10" height="40" width="40%" y="350" />
      <rect fill="#EEEEEE" rx="5" x="10" height="40" width="30%" y="400" />
      <rect x="10" width="440" rx="10" fill="#EEEEEE" height="250" />
      <animate
        attributeType="CSS"
        attributeName="opacity"
        from="1"
        to=".3"
        dur="2s"
        values="1;.3; 1"
        keyTimes="0; 0.5; 1" repeatCount="indefinite"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AProductPlaceholderHorizontal'
};
</script>

<style lang="scss">
.a-category-placeholder {
  svg {
    path, rect {
      fill: var(--placeholder-color)
    }
  }
}
</style>
