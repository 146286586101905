<template>
  <div class="press-kit">
    <AStatic :content="content" ref="pressKit" />
  </div>
</template>

<script>
import MShopsPhotoGallery from 'theme/components/molecules/m-shops-photo-galery';
import AStatic from 'theme/components/atoms/a-static';
import Vue from 'vue';

export default {
  name: 'PressKit',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    },
    images: {
      type: Array,
      default: null
    }
  },
  components: {
    AStatic
  },
  data () {
    return {
      photoList: []
    }
  },
  methods: {
    getGalleryImages () {
      const images = this.$refs.pressKit.$el.querySelector('#imagelist');
      if (!images) {
        return '';
      }
      let imageItems = images.querySelectorAll('.image-item');
      imageItems.forEach((el, i) => {
        const image = {
          id: i,
          src: el.getElementsByTagName('img')[0]?.getAttribute('src') || '',
          name: el.getElementsByTagName('img')[0]?.getAttribute('alt') || this.$t('Image-' + i)
        }
        this.photoList.push(image);
      })
    },

    /**
     * Mapping the content value from Magento admin panel (CMS Page) on VS component - MShopsPhotoGallery
     */
    mapShopsPhotoGallery () {
      const photoGallery = this.$refs.pressKit.$el.querySelector('#shop-photogallery');
      if (!photoGallery) {
        return '';
      }

      const Component = Vue.extend(MShopsPhotoGallery);
      const componentInstance = new Component({
        i18n: this.$i18n,
        store: this.$store,
        propsData: {
          images: this.photoList
        }
      });
      componentInstance.$mount(photoGallery);
    }
  },

  mounted: function () {
    this.getGalleryImages();
    this.mapShopsPhotoGallery()
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.press-kit {
  ::v-deep {
    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }
    }

    // Banner
    .banner {
      color: var(--white);
      background: linear-gradient(94.39deg, #FF8A2F 12.72%, #DE5B27 90.6%);
      display: flex;
      justify-content: space-between;
      font-family: var(--font-family-inter);
      margin: 0 var(--spacer-10) var(--spacer-30);
      min-height: 150px;
      overflow: hidden;

      @include for-desktop {
        max-width: 990px;
        margin: 0 auto;
        min-height: 250px;
      }
    }

    .description {
      margin: var(--spacer-25) 0 0 var(--spacer-15);
      flex: 1 0 200px;

      .banner-text {
        font-size: var(--font-size-18);
        font-weight: 500;
        line-height: var(--spacer-22);
        margin-top: 0;

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-32);
          line-height: var(--spacer-33);
        }

        span.text {
          display: block;
          font-size: var(--font-size-12);
          font-weight: 400;
          line-height: var(--spacer-16);
          margin-top: var(--spacer-8);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-16);
            margin-top: var(--spacer-10);
            font-weight: 400;
            line-height: var(--spacer-23);
            opacity: 0.8;
          }
        }
      }

      @media (min-width: $tablet-min) {
        margin: 64px 0 0 52px;
        flex: 1 0 330px;
      }
    }

    .images-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-right: -150px;
      position: relative;
      width: 100%;

      @include for-desktop {
        margin-right: 0;
      }

      .banner-img {
        background: url("/assets/press-kit/banner.png") no-repeat;
        background-position: right bottom;
        background-size: 80%;
        & > img {
          height: 100%;
          position: relative;
        }
      }
    }

    .center {
      padding: 0 13px;
      max-width: 790px;
      margin: 0 auto;

      @include for-desktop {
        margin-top: var(--spacer-48);
        padding: 0;
      }
    }

    .title {
      padding-bottom: 0;

      & > h2 {
        color: var(--black);
        font-size: var(--font-size-20);
        font-weight: 500;
        line-height: var(--spacer-24);
        text-align: left;

        @include for-desktop {
          font-size: var(--font-size-32);
          line-height: var(--font-size-33);
          margin-bottom: var(--spacer-30);
        }
      }
    }

    .section-title {
      margin: var(--spacer-30) 0 var(--spacer-15);
      padding-bottom: 0;

      & > h2 {
        color: var(--black);
        font-size: var(--font-size-20);
        font-weight: 500;
        line-height: var(--spacer-24);
        text-align: left;
      }

      @include for-desktop {
        font-weight: 500;
        margin: var(--spacer-60) 0 var(--spacer-25);
        padding-bottom: 0;
        text-align: left;

        & > h2 {
          font-size: var(--font-size-24);
        }
      }
    }

    .photogallery {
      margin: var(--spacer-50) 0 var(--spacer-20);

      @include for-desktop {
        margin: var(--spacer-68) 0 var(--spacer-25);
      }
    }

    .main-information {
      p {
        font-size: var(--font-size-14);
        font-weight: 400;
        line-height: var(--spacer-24);
        margin: 0 0 var(--spacer-10);

        &:first-child {
          margin-top: var(--spacer-15);
        }
      }
    }

    .presentation {
      display: flex;
      align-items: center;
      background: url("/assets/press-kit/presentation.png") no-repeat center / cover;
      margin-top: var(--spacer-30);
      min-height: 150px;
      cursor: pointer;

      &:hover {
        transition: .2s ease-in-out;
        opacity: 0.7;
      }

      .view-presentation {
        color: var(--white);
        font-weight: 500;
        font-size: var(--font-size-20);
        line-height: var(--spacer-24);
        margin: 14.5% 0 15% var(--spacer-30);
        max-width: 132px;

        @include for-desktop {
          font-size: var(--font-size-32);
          line-height: var(--spacer-33);
          margin-left: var(--spacer-65);
        }
      }
    }

    //Logo block
    .logos {
      .section-title {
        display: flex;
        justify-content: center;
        @include for-desktop {
          justify-content: flex-start;
        }
      }
    }
    .list-logos {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      margin: var(--spacer-5) var(--spacer-5) var(--spacer-20);
      padding-left: 0;

      @include for-desktop {
        margin-bottom: var(--spacer-30);
      }

      .item-logo {
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;
        margin: var(--spacer-5) -5px;
        width: 50%;
      }

      img {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .logo-2 {
        padding: var(--spacer-30);

        @include for-desktop {
          padding: var(--spacer-57) 77px;
        }
      }
      .logo-3 {
        padding: var(--spacer-24) var(--spacer-30);

        @include for-desktop {
          padding: var(--spacer-48) 79px;
        }
      }
      .logo-4 {
        padding: var(--spacer-15) var(--spacer-50);

        @include for-desktop {
          padding: var(--spacer-27) 122px;
        }
      }
    }

    .view-logos {
      color: var(--white);
      background-color: var(--orange);
      display: block;
      font-size: var(--font-size-14);
      font-weight: 500;
      line-height: var(--spacer-24);
      max-width: 355px;
      margin: 0 auto;
      padding: var(--spacer-8) 0;
      text-align: center;
      width: 100%;

      &:hover {
        background-color: var(--orange-hover);
      }

      @include for-desktop {
        max-width: 230px;
      }
    }

    #imagelist {
      display: none;
    }

  }
}
</style>
