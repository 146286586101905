<template>
  <div class="gazeta">
    <AStatic
      class="gazeta-content"
      :content="content"
      ref="gazeta"
    />
  </div>
</template>
<script>
import AStatic from 'theme/components/atoms/a-static'

export default {
  name: 'Gazeta',
  components: {
    AStatic
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/mixins";

.gazeta {
  padding: 0 var(--spacer-10);

  @media (min-width: $tablet-min) {
    padding: 0 var(--spacer-17);
  }

  @media (min-width: $desktop-min) {
    padding: 0;
  }
  ::v-deep {
    .sf-heading {
      text-align: left;
      margin-bottom: var(--spacer-7);
      padding: 0;
      --heading-text-align: left;

      @media (min-width: $tablet-min) {
        margin-bottom: var(--spacer-20);
      }
    }
    .sf-heading__title {
      @include header-title;
    }
  }
}

.new-product-list {
  display: grid;
  grid-gap: var(--spacer-10);
  grid-template-columns: auto;

  @media (min-width: $tablet-min) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $desktop-min) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
::v-deep {
  .new-product-list__item {
    margin-bottom: var(--spacer-40);

    &-image {
      margin-bottom: var(--spacer-20);

      .sf-image {
        width: 100%;

        &--has-size {
          &::after {
            padding-bottom: 217px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      @media (min-width: $desktop-min) {
        margin-bottom: var(--spacer-15);
      }
    }

    &-date {
      margin-bottom: var(--spacer-6);

      span {
        font-weight: 400;
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);
        color: var(--dark-gray);
      }
    }

    &-name {
      margin-bottom:  var(--spacer-15);

      span {
        font-weight: 500;
        font-size: var(--font-size-20);
        line-height: var(--font-size-24);
        color: var(--black);

        @media (min-width: $desktop-min) {
          font-size: var(--font-size-24);
          line-height: var(--font-size-25);
        }
      }
    }

    &-description {
      span {
        font-weight: 400;
        font-size: var(--font-size-14);
        line-height: var(--font-size-24);
        color: var(--dark-gray);
      }
    }
  }
}
</style>
