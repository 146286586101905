<template>
  <div
    id="quiz"
    class="quiz"
  >
    <AStatic
      id="quiz-content"
      :content="content"
      ref="quiz"
    />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'

export default {
  name: 'Quiz',
  mixins: [customFormSpinnerLoading],
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  },
  methods: {
    dynamicIframeHight (event) {
      const getIframe = document.querySelector('iframe')

      if (getIframe && event?.data?.message) {
        const { formHeight, btnHeight } = event?.data?.message

        if (formHeight == null) return;

        const sumHeight = +formHeight + +btnHeight

        getIframe.height = '';
        getIframe.removeAttribute('style')

        if (sumHeight) {
          getIframe.setAttribute('style', `height: ${sumHeight}px;`);
        }
      }
    }
  },
  mounted () {
    window.addEventListener('message', this.dynamicIframeHight)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.dynamicIframeHight)
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.quiz {
  ::v-deep {
    .orange-text {
      color: var(--orange);
    }

    .container {
      margin: 0 var(--spacer-10);

      @media (min-width: $tablet-min) {
        max-width: px2rem(990);
        margin: 0 auto;
        min-height: px2rem(250);
      }
    }

    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }
    }

    .quiz-center {
      padding: 0;
      max-width: px2rem(990);
      margin: 0 auto;

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-50);
      }

      .title {
        padding-left: var(--spacer-10);
        margin-bottom: var(--spacer-15);

        @media (min-width: $tablet-min) {
          margin-bottom: var(--spacer-20);
        }

        @include for-desktop {
          padding-left: var(--spacer-100);
        }
      }
    }

    .title {
      padding-bottom: 0;

      & > h2 {
        @include header-title;
      }

      & > h3 {
        text-align: left;
        font-weight: 500;
        font-size: var(--font-size-20);
        line-height: var(--spacer-19);
        color: var(--black);

        @media (min-width: $tablet-min) {
          font-weight: 500;
          font-size: var(--font-size-24);
          line-height: var(--spacer-25);
        }
      }
    }

    // Banner
    .banner {
      position: relative;
      color: var(--white);
      background: linear-gradient(90deg, #fd8324 56.72%, #ffb680 100%, #f3be96 75%, #f95f23 95%);
      display: flex;
      justify-content: space-between;
      font-family: var(--font-family-inter);
      margin: var(--spacer-5) var(--spacer-10) var(--spacer-30);
      min-height: px2rem(150);
      overflow: hidden;
      border-radius: 10px;

      @include for-desktop {
        max-width:  px2rem(990);
        margin: 0 auto;
        min-height: px2rem(250);
        border-radius: 0;
        background: linear-gradient(90deg, #fd8324 56.72%, #ffb680 74%, #f3be96 80%, #f95f23 100%);
      }

      .sf-image {
        position: absolute;
        left: auto;
        right: 0;

        img {
          display: block;
          left: auto;
          right: 0;
          object-fit: cover;
          --image-width: 100%;
          --image-height: 100%;
          max-height: px2rem(150);

          @include for-desktop {
            max-height: px2rem(250);
          }
        }
      }

      .images-wrapper.desc-version {
        right: 35px;
      }
    }

    .description {
      line-height: var(--spacer-22);
      margin-top: 0;
      width: 70%;

      @media (min-width: $tablet-min) {
        max-width: 600px;
        line-height: var(--spacer-33);
        width: 65%;
      }
    }

    .banner-title {
      padding-bottom: 0;
    }

    .banner-title > .sf-heading__title {
      color: var(--white);
      font-size: var(--font-size-18);
      line-height: inherit;
      margin: var(--spacer-17) 0 var(--spacer-8) var(--spacer-23);
      text-align: left;
      font-weight: 500;

      @include for-desktop {
        font-size: var(--font-size-32);
        font-weight: var(--font-medium);
        margin: var(--spacer-48) 0 var(--spacer-20) px2rem(77);
      }
    }

    .banner-subtitle {
      font-size: var(--font-size-12);
      line-height: var(--spacer-13);
      margin-left: var(--spacer-23);
      opacity: .8;

      @include for-desktop {
        font-size: var(--font-size-16);
        font-weight: 400;
        line-height: var(--spacer-28);
        margin-left: px2rem(77);
      }
    }

    .img-shop {
      overflow: visible;

      > img {
        display: block;
        margin: var(--spacer-50) px2rem(110) 0 var(--spacer-30);
        width:  px2rem(140);
        filter: drop-shadow(0px 0px 80px #FFF);

        @include for-desktop {
          width: px2rem(250);
        }
      }
    }

    .quiz__form {
      margin: 0 var(--spacer-10);

      .custom-form__iframe {
        overflow: hidden;
        min-height: px2rem(600);
      }
    }

    .content {
      .custom__form {
        overflow: hidden;
        margin-top: 0;

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-65);
        }
      }

      .custom__form,
      .custom-form__iframe {
        min-height: inherit;
        height: px2rem(535);

        @media (min-width: $tablet-min) {
          height: px2rem(500);
        }
      }

      // top form
      .quiz-center {
        .custom-form__iframe {
          @media (min-width: $tablet-min) {
            padding-bottom: 0;
          }
        }
      }

      .custom__form-container {
        grid-column-gap: px2rem(133);

        .custom__form-block {
          flex: 1 1 67%;
        }
      }
    }
  }
}
</style>
