var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"sf-pagination"},[_vm._t("prev",function(){return [_c('div',{staticClass:"sf-pagination__item sf-pagination__item--prev",class:{
        'sf-pagination__item--prev--disable': !_vm.canGoPrev
      }},[(_vm.canGoPrev)?_c(_vm.componentIs,{tag:"component",class:{
          'sf-link': _vm.hasRouter,
          'sf-button--pure': !_vm.hasRouter
        },attrs:{"href":_vm.checkHasRouter(_vm.getPrev),"aria-label":"Go to previous page","data-transaction-name":"Pagination - Prev"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goTo(_vm.getPrev)}}}):_vm._e()],1)]},null,{ isDisabled: !_vm.canGoPrev, prev: _vm.getPrev }),_vm._v(" "),(_vm.showFirst)?[_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-link': _vm.hasRouter,
          'sf-button--pure': !_vm.hasRouter,
          'sf-pagination__item--was': _vm.pagesChain.includes(1)
        },attrs:{"href":_vm.getLinkTo(1),"data-transaction-name":"Pagination - Go To First"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goTo(1)}}},[_c('span',{on:{"click":_vm.clearSelectedPages}},[_vm._v("1")])])]},null,{ page: 1 }),_vm._v(" "),_vm._t("points",function(){return [(_vm.showLeftDots)?_c('div',{staticClass:"sf-pagination__item",class:{
          'sf-pagination__item--was': _vm.leftDotsWas
        }},[_vm._v("\n        ...\n      ")]):_vm._e()]})]:_vm._e(),_vm._v(" "),_vm._l((_vm.limitedPageNumbers),function(page){return [_c(_vm.currentPage === page ? 'span' : _vm.componentIs,{key:page,tag:"component",staticClass:"sf-pagination__item",class:{
        'sf-link': _vm.hasRouter,
        'sf-button--pure': !_vm.hasRouter && _vm.currentPage !== page,
        'sf-pagination__item--current': _vm.currentPage === page,
        'sf-pagination__item--was': _vm.pagesChain.includes(page),
      },attrs:{"href":_vm.currentPage !== page ? _vm.getLinkTo(page) : null,"data-transaction-name":"Pagination - Go To Page"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.currentPage !== page ? _vm.goTo(page) : null}}},[_c('span',{on:{"click":_vm.clearSelectedPages}},[_vm._v(_vm._s(page))])])]}),_vm._v(" "),(_vm.showLast)?[_vm._t("points",function(){return [(_vm.showRightDots)?_c('div',{staticClass:"sf-pagination__item"},[_vm._v("\n        ...\n      ")]):_vm._e()]}),_vm._v(" "),_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-link': _vm.hasRouter,
          'sf-button--pure': !_vm.hasRouter,
        },attrs:{"href":_vm.getLinkTo(_vm.total),"data-transaction-name":"Pagination - Go To Last"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goTo(_vm.total)}},nativeOn:{"click":function($event){return _vm.onNavClick.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.total))])])]},null,{ page: _vm.total })]:_vm._e(),_vm._v(" "),_vm._t("next",function(){return [_c('div',{staticClass:"sf-pagination__item sf-pagination__item--next",class:{
           'sf-pagination__item--next--disable': !_vm.canGoNext
         }},[(_vm.canGoNext)?_c(_vm.componentIs,{tag:"component",class:{
          'sf-link': _vm.hasRouter,
          'sf-button--pure': !_vm.hasRouter,
        },attrs:{"href":_vm.getLinkTo(_vm.getNext),"aria-label":"Go to previous next","data-transaction-name":"Pagination - Go To Next"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.goTo(_vm.getNext)}},nativeOn:{"click":function($event){return _vm.onNavClick.apply(null, arguments)}}}):_vm._e()],1)]},null,{ isDisabled: !_vm.canGoNext, next: _vm.getNext }),_vm._v(" "),(_vm.total > _vm.currentPage)?_c('SfButton',{staticClass:"products__load-more sf-link--primary",attrs:{"data-transaction-name":"Pagination - Load More"},on:{"click":_vm.loadMoreFromPag}},[_c('span',[_vm._v("\n      "+_vm._s(_vm.$t('Show more {amount} products', { amount: _vm.perPage }))+"\n    ")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }