import { RobotsEnum } from './types';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';

export function metaCms () {
  const plugPage = this.$store.state.ui.plugPageFlag;
  const cmsPage = this.$store.getters['cmsPage/getCmsPages'][0];
  const route = this.$store.getters['url/getCurrentRoute']
  const baseUrl = this.$store.getters['siteData/baseUrl'];
  const url = `${baseUrl}${route.fullPath}`;
  const relRobots = plugPage
    ? relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key)
    : relRobotsHandler(RobotsEnum.INDEX, config.xEnv.key);

  const meta = {
    title: cmsPage.meta_title || this.currentRoute.title || this.pageTitle,
    meta: {
      ...relRobots,
      title: {
        name: 'title',
        content: cmsPage.meta_title || this.currentRoute.title || this.pageTitle
      },
      description: {
        name: 'description',
        content: cmsPage.meta_description || this.pageDescription
      },
      keywords: {
        name: 'keywords',
        content: cmsPage.meta_keywords || this.pageKeywords
      },
      ogTitle: {
        name: 'og:title',
        content: cmsPage.meta_title || this.currentRoute.title || this.pageTitle
      },
      ogDescription: {
        name: 'og:description',
        content: cmsPage.meta_description || this.pageDescription
      },
      ogUrl: {
        name: 'og:url',
        content: url
      }
    }
  };

  return mergeMeta(meta)
}
