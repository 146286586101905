<template>
  <MCarouselItems
    :items="items"
    :slider-settings="sliderSettings"
    carousel-class="m-tasty-carousel"
    :common-props="{
      'is-show-bullets': true
    }"
  >
    <template slot-scope="{ item }">
      <div class="m-tasty-carousel__item">
        <img
          class="m-tasty-carousel__item-image"
          loading="lazy"
          :src="item.url"
          :alt="item.name"
        >
      </div>
    </template>
  </MCarouselItems>
</template>
<script>
import MCarouselItems from 'theme/components/molecules/m-carousel-items';

export default {
  name: 'MTastyJobCarousel',
  components: {
    MCarouselItems
  },
  props: {
    arrowColor: {
      type: String,
      default: 'gray'
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          perView: 6,
          gap: 10,
          peek: {
            before: 0,
            after: 0
          },
          breakpoints: {
            1023: {
              perView: 3,
              peek: {
                before: 0,
                after: 162
              }
            },
            767: {
              perView: 1,
              peek: {
                before: 0,
                after: 175
              }
            }
          }
        }
      }
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
};
</script>
