<template>
  <SfOCarousel
    class="slider-carousel photo-gallery-carousel"
    id="photo-gallery-carousel"
    :settings="sliderSettings"
  >
    <template #prev="{go}">
      <SfButton
        class="sf-arrow sf-arrow--rounded sf-arrow-prev"
        :class="prevButtonClass"
        aria-label="previous"
        @click="goPrev(); go('prev')"
        :disabled="sliderSettings.disablePrev"
      />
    </template>
    <SfCarouselItem
      v-for="(photo, index) in images"
      :key="index"
    >
      <SfOImage
        :src="photo.src"
        :alt="photo.name"
        :height="photo.height"
        width="100%"
        class="m-photogallery-carousel__image"
        :allow-src="true"
        :hide-loader="true"
        lazy
      />
      <div class="sfo-carousel__additional-buttons desktop-only">
        <SfButton
          class="osf-button osf-download"
          aria-label="download"
          @click="downloadImage(photo.src, photo.name)"
        />
        <SfButton
          class="osf-button osf-view"
          aria-label="view"
          @click="viewFullScreen(index, $event )"
        />
      </div>
    </SfCarouselItem>
    <template #next="{go}">
      <SfButton
        class="sf-arrow sf-arrow--rounded sf-arrow-next"
        :class="nextButtonClass"
        aria-label="next"
        @click="goNext(); go('next')"
        :disabled="sliderSettings.disableNext"
      />
    </template>
  </SfOCarousel>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import SfOImage from 'theme/components/storefront-override/SfOImage';
import DeviceType from 'theme/mixins/DeviceType';
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel';
import GlideInit from 'theme/helpers/glide';
import { BREAKPOINT_SM } from 'theme/helpers/breakpoints'

export default {
  name: 'MShopsPhotoGallery',
  mixins: [DeviceType],
  components: {
    SfButton,
    SfOCarousel,
    SfOImage
  },
  props: {
    arrowColor: {
      type: String,
      default: 'white'
    },
    images: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      glide: null,
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        currentPage: 1,
        disablePrev: true,
        disableNext: false,
        hoverpause: false,
        startAt: 0,
        gap: 6,
        perTouch: 6,
        perView: 2,
        dragThreshold: false,
        peek: {
          before: 0,
          after: 250
        },
        breakpoints: {
          [BREAKPOINT_SM - 1]: {
            perView: 1,
            perTouch: 1,
            peek: {
              before: 0,
              after: 75
            }
          }
        }
      }
    }
  },
  computed: {
    prevButtonClass () {
      return [`sf-arrow--${this.arrowColor}`, { 'sf-arrow--active': !this.sliderSettings.disablePrev }]
    },
    nextButtonClass () {
      return [`sf-arrow--${this.arrowColor}`, { 'sf-arrow--active': !this.sliderSettings.disableNext }]
    }
  },
  methods: {
    goNext () {
      this.sliderSettings.currentPage = (this.sliderSettings.currentPage + 1);
      this.sliderSettings.disablePrev = false;
      const calculatedImagesOnView = this.images.length / this.sliderSettings.perView + 1.5;

      if (this.sliderSettings.currentPage + 1 === calculatedImagesOnView) {
        this.sliderSettings.disableNext = true
      }
    },
    goPrev () {
      this.sliderSettings.currentPage = (this.sliderSettings.currentPage - 1)
      this.sliderSettings.disableNext = false

      if (this.sliderSettings.currentPage <= 1) {
        this.sliderSettings.disablePrev = true
      }
    },

    downloadImage: async function (src, name) {
      let imagePath = src.substring(src.indexOf('wysiwyg'));
      let payload = {
        path: imagePath,
        title: name
      }
      await this.$store.dispatch('photoGallery/downloadFile', payload);
    },

    async viewFullScreen (index, event) {
      if (event.target.className.indexOf('osf-view')) {
        let firstParent = event.target.parentElement;
        let secondParent = firstParent.parentElement;

        let slider = document.getElementById('photo-gallery-carousel');
        let originSlider = slider.querySelector('.glide')

        let cloneSlider = originSlider.cloneNode(true);
        cloneSlider.classList.add('clone-fullscreen');
        document.body.classList.add('open-fullscreen-gallery');

        let placeTarget = secondParent.closest('.glide__track');
        placeTarget.append(cloneSlider);

        let controlsBtns = slider.getElementsByClassName('sf-carousel__controls')[0];
        controlsBtns.classList.add('hidden');

        // Create a buttons
        let closeBtn = document.createElement('button');
        let prevBtn = document.createElement('button');
        let nextBtn = document.createElement('button');
        closeBtn.classList.add('close-fullscreen');
        prevBtn.classList.add('previous-slide');
        nextBtn.classList.add('next-slide');
        cloneSlider.appendChild(closeBtn);
        cloneSlider.appendChild(prevBtn);
        cloneSlider.appendChild(nextBtn);

        const Glide = await GlideInit();
        // Init a new instance of Glide slider for fullscreen view
        var glide = new Glide(cloneSlider);
        glide.update({ startAt: index })
        glide.mount();

        // Add a listener to close fullscreen after clicking outside the picture
        let listener = function (e) {
          let className = e.target.className;
          if (className === 'close-fullscreen') {
            document.body.classList.remove('open-fullscreen-gallery');
          }
          if (className === 'next-slide' || className === 'previous-slide' || className === 'close-fullscreen') {
            return true
          } else {
            document.body.classList.remove('open-fullscreen-gallery');
            cloneSlider.remove();
            controlsBtns.classList.remove('hidden');
            glide.destroy();
            document.removeEventListener('click', listener, true);
          }
        }

        closeBtn.addEventListener('click', () => {
          cloneSlider.remove();
          controlsBtns.classList.remove('hidden');
          glide.destroy();
          document.removeEventListener('click', listener, true);
        });

        prevBtn.addEventListener('click', () => {
          glide.go('<')
        });

        nextBtn.addEventListener('click', () => {
          glide.go('>')
        });

        document.addEventListener('click', listener, true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-photogallery-carousel {
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    height: var(--_image-height);
    width: var(--_image-width);
  }

  ::v-deep {
    img {
      margin: auto;
      right: 0;
    }
  }
}

::v-deep {
  .sf-carousel__wrapper {
    overflow: unset;

    .glide__track {
      overflow: hidden;
    }

    .sf-carousel-item {
      position: relative;

      &:first-child {
        margin-left: var(--spacer-10);

        @include for-desktop {
          margin-left: 0;
        }
      }

      &:hover .sfo-carousel__additional-buttons {
        display: flex;
      }
    }

    .glide__slide--active + .glide__slide {
      opacity: 0.6;

      @media (min-width: $tablet-min) {
        opacity: 1;
      }
    }

    @media (min-width: $tablet-min) {
      .glide__slide--active + .glide__slide + .glide__slide {
        opacity: 0.6;

        &:hover {
          .sfo-carousel__additional-buttons {
            display: none;
          }
        }
      }
    }
  }

  .sf-arrow--white {
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }

  .sf-carousel__controls {
    button[disabled="disabled"] {
      visibility: hidden;
    }
  }
}

.sfo-carousel__additional-buttons {
  align-items: center;
  display: none;
  justify-content: center;
  height: inherit;
  position: absolute;
  top: 0;
  width: inherit;
  z-index: 2;
}

.osf-button {
  padding: 0;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  height: 66px;
  width: 66px;
}

.osf-download {
  background: var(--orange);

  @include font-icon(var(--icon-download));

  &:before {
    color: var(--white);
    font-size: var(--font-size-19);
  }
}

.osf-view {
  background: var(--white);

  @include font-icon(var(--icon-eye));

  &:before {
    color: var(--orange);
    font-size: var(--font-size-19);
  }
}

//Styles for full screen
::v-deep {
  .clone-fullscreen {
    display: flex;
    align-items: center;
    position: absolute;
    top: -100px;
    z-index: 12;

    .close-fullscreen {
      cursor: pointer;
      background: #F3F4F6;
      border-radius: var(--spacer-5);
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--spacer-30);
      opacity: 0.7;
      position: absolute;
      right: 192px;
      top: -30px;
      width: var(--spacer-30);
      @include font-icon(var(--icon-close));

      &:before {
        color: var(--black);
        font-size: var(--font-size-24);
      }

      &:hover {
        opacity: unset;
      }
    }

    .previous-slide,
    .next-slide {
      background: #F3F4F6;
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--spacer-42);
      margin: 0 150px;
      opacity: 0.7;
      position: absolute;
      width: var(--spacer-42);
      cursor: pointer;

      &:before {
        color: var(--black);
        font-size: var(--font-size-8);
      }

      &:hover {
        opacity: unset;
      }
    }

    .previous-slide {
      @include font-icon(var(--icon-arrow-bold-left));
    }

    .next-slide {
      right: 0;
      @include font-icon(var(--icon-arrow-bold-right));
    }

    .m-photogallery-carousel__image {
      display: flex;
      justify-content: center;
      overflow: visible;

      & > img {
        height: 529px;
      }
    }

    .sf-carousel-item {
      &:hover .sfo-carousel__additional-buttons {
        display: none;
      }
    }
  }

  .hidden {
    display: none;
  }
}
</style>
