import escapeHTML from 'escape-html';

const pattern = /((<[/a-zA-Z][^<>]*>)|(<!--|-->)|([<>]+))/gm;

export const safeHtml = (text) => {
  try {
    return (text || '').replace(pattern, (match, ...args) => (
      args?.[3] ? escapeHTML(args[3]) : match
    ))
  } catch (e) {
    return text
  }
}
