<template>
  <div id="contacts">
    <AStatic :content="content" ref="contactsPage" />
    <SfAccordion
      :show-chevron="false"
      :multiple="true"
      :open="[]"
      transition=""
      class="accordion"
      id="sf-accordion"
    >
      <SfAccordionItem
        v-for="department in departments"
        :key="department.id"
        :header="$t(department.title)"
        :class="{ 'accordion-item-open': isOpen }"
      >
        <ul class="item-position" v-for="(itemPosition, index) in department.positions" :key="index">
          <li class="position">
            <span>{{ $t('position') }}</span>{{ $t(itemPosition.position) }}
          </li>
          <li class="full-name">
            <span>{{ $t('Full name') }}</span>{{ $t(itemPosition.fullName) }}
          </li>
          <li class="telephone">
            <span>{{ $t('Phone') }}</span><a :href="'tel:' + itemPosition.telephone">{{ itemPosition.telephone }}</a>
          </li>
          <li class="email">
            <span>{{ $t('e-mail') }}</span><a :href="'mailto:' + itemPosition.email">{{ itemPosition.email }}</a>
          </li>
        </ul>
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<script>
import { SfAccordion } from '@storefront-ui/vue';
import { BREAKPOINT_MD } from 'theme/helpers/breakpoints';
import AStatic from 'theme/components/atoms/a-static';

export default {
  name: 'Contacts',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    SfAccordion,
    AStatic
  },
  data () {
    return {
      isOpen: false,
      viewMap: false,
      departments: [],
      personalData: []
    };
  },
  methods: {
    /**
     * Change class for "Open Source Map" block
     */
    showOSMap () {
      const osm = this.$refs.contactsPage.$el.querySelector('#osm-wrapper');
      if (!osm) {
        return '';
      }
      if (this.viewMap) {
        osm.className = 'view-map';
        osm.querySelector('iframe').className = 'view-map';
      } else {
        const toggleClass = this.$refs.contactsPage.$el.querySelector('.show-map.mobile-only');
        toggleClass.addEventListener('click', () => {
          osm.classList.toggle('view-map');
          osm.querySelector('iframe').classList.toggle('view-map');
        })
      }
    },
    /**
     * Mapping the content value from Magento admin panel (CMS Page) on VS component - SfAccordion
     */
    mapAccordion () {
      const accordion = this.$refs.contactsPage.$el.querySelector('#accordion');
      if (!accordion) {
        return '';
      }
      let accordionItems = accordion.querySelectorAll('.accordion-item');
      accordionItems.forEach((el, i) => {
        const accordionItem = {
          id: i,
          title: el.querySelector('.title')?.textContent || '',
          positions: []
        }

        let itemPositions = el.querySelectorAll('.item-position');
        itemPositions.forEach((item) => {
          const personalData = {
            position: item.querySelector('.person-position')?.textContent || '',
            fullName: item.querySelector('.person-name')?.textContent || '',
            telephone: item.querySelector('.person-telephone')?.textContent || '',
            email: item.querySelector('.person-email')?.textContent || ''
          }
          accordionItem.positions.push(personalData)
        })
        this.departments.push(accordionItem);
      })
    },
    /**
     * Moved the feedback block after position  SfAccordion component in DOM structure
     */
    moveFeedbackBlock () {
      const feedbackBlock = this.$refs.contactsPage.$el.querySelector('.feedback-block');
      if (!feedbackBlock) {
        return '';
      } else {
        let targetElement = document.getElementById('sf-accordion');
        targetElement.insertAdjacentElement('afterend', feedbackBlock);
      }
    }
  },
  mounted: function () {
    let windowWith = document.documentElement.clientWidth;
    if (windowWith > [BREAKPOINT_MD - 1]) {
      this.viewMap = true;
    }
    this.showOSMap();
    this.mapAccordion();
    this.moveFeedbackBlock();
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~theme/css/breakpoints";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';
@import "~theme/css/mixins";

::v-deep {
  .sf-breadcrumbs.sf-breadcrumbs {
    max-width: 1272px;
  }
}

#contacts {
  ::v-deep {
    .content {
      padding: 0;

      & > .sf-heading {
        margin-left: var(--spacer-10);
        padding-bottom: 0;
        text-align: start;

        @include for-desktop {
          max-width: 1190px;
          margin: 0 auto var(--spacer-20);
        }

        .sf-heading__title {
          @include header-title;
        }
      }

      #accordion {
        display: none;
      }
    }

    //Map block
    .map-block {
      position: relative;
    }

    .main-office-wrapper {
      @include for-desktop {
        margin: 0 auto;
        position: relative;
        width: var(--max-width);
      }
    }

    .main-office {
      background-color: var(--white);
      margin: var(--spacer-10) auto 0;
      max-width: 395px;
      padding: 0 var(--spacer-10);
      width: 100%;

      @include for-desktop {
        margin: 0 var(--spacer-20);
        padding: 0;
        position: absolute;
        right: 80px;
        top: var(--spacer-64);
      }

      .office-tittle {
        font-size: var(--font-size-20);
        font-weight: 500;
        line-height: var(--spacer-20);

        @include for-desktop {
          padding-left: var(--spacer-20)
        }
      }

      .office-addresses,
      .office-phone {
        &:before {
          color: var(--black);
          font-size: var(--font-size-15);
          margin-right: var(--spacer-12);
        }
      }

      .office-addresses {
        display: flex;
        font-size: var(--font-size-13);
        line-height: var(--spacer-15);

        @include font-icon(var(--icon-location));

        @include for-desktop {
          padding-left: var(--spacer-20)
        }
      }

      .office-phone {
        display: flex;
        font-size: var(--font-size-15);
        line-height: var(--spacer-18);
        margin: var(--spacer-20) 0 var(--spacer-15);

        &:before {
          font-size: var(--font-size-13);
        }

        @include font-icon(var(--icon-phone));

        @include for-desktop {
          padding-left:var(--spacer-20);
          margin: var(--spacer-20) 0 var(--spacer-30);
        }
      }

      .show-map {
        color: var(--orange);
        font-size: var(--font-size-14);
        margin: var(--spacer-15) 0 var(--spacer-20);
      }

      .sf-image.img-office {
        @include for-desktop {
          margin: var(--spacer-20) var(--spacer-20) 0;
        }
      }
    }

    .hide-map {
      height: 0;
      min-height: 0;
      transition: height 0.7s ease;
    }

    .view-map {
      display: flex;
      height: 300px;
      margin-bottom: var(--spacer-20);
      transition: height 0.7s ease;

      @include for-desktop {
        height: 580px;
      }

      iframe {
        min-height: 300px;
        max-width: calc(var(--max-width) - 20px);

        @include for-desktop {
          min-height: 580px;
        }
      }

      @include for-desktop {
        margin: 0 auto var(--spacer-83) auto
      }
    }

    .view-all-addresses {
      background: var(--orange);
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-size-14);
      height: var(--spacer-40);
      margin: 0 auto var(--spacer-50);
      transition: .2s ease-in-out;
      max-width: 355px;
      width: 100%;
      z-index: 1;
      position: relative;

      &:hover {
        background-color: var(--orange-hover);
        color: var(--white);
      }

      @include for-desktop {
        margin-bottom: var(--spacer-20)
      }
    }

    // Departments block
    .departments,
    .feedback-block,
    .accordion {
      max-width: 790px;
      width: 100%;
      margin: 0 auto;

      .reception {
        background: var(--light-gray);
        display: flex;
        flex-wrap: wrap;
        margin: 0 var(--spacer-10) var(--spacer-18);
        padding: var(--spacer-30) 0 0 var(--spacer-30);

        @include for-desktop {
          margin-bottom: var(--spacer-50);
        }

        & > span {
          font-size: var(--font-size-14);
          line-height: var(--spacer-16);
          margin-bottom: var(--spacer-15);
          max-width: 360px;
          width: 100%;
          color: var(--dark-gray);
        }

        .reception-telephone {
          color: var(--orange);
          font-size: var(--font-size-18);
          margin-bottom: var(--spacer-30);

          @include for-desktop {
            font-size: var(--font-size-20);
            line-height: var(--spacer-20);
          }
        }
      }
    }

    .departments-title,
    .feedback-title {
      margin: 0 0 var(--spacer-15) var(--spacer-10);
      padding-bottom: 0;
      text-align: left;

      @include for-desktop {
        margin-bottom: var(--spacer-25);
      }
    }

    .sf-heading__title {
      font-size: var(--font-size-20);
      line-height: var(--spacer-24);
      font-weight: 500;

      @include for-desktop {
        font-size: var(--font-size-24);
      }
    }

    .custom__form-description {
      .sf-heading__title--h4 {
        @include header-title;
      }
    }

    .feedback-block {
      margin: var(--spacer-58) auto var(--spacer-20);

      @include for-desktop {
        margin:var(--spacer-80) auto var(--spacer-70);
      }

      .feedback-info {
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);
        margin-left: var(--spacer-10);
      }

      .custom__form {
        overflow: hidden;
      }

      .custom__form,
      .custom-form__iframe {
        min-height: inherit;
        height: px2rem(934);

        @media (min-width: $tablet-min) {
          height: px2rem(830);
        }
      }
    }

    .feedback-contacts {
      background: var(--light-gray);
      display: flex;
      flex-wrap: wrap;
      margin: 0 var(--spacer-10);

      @media (min-width: $tablet-min) {
        flex-wrap: nowrap;
        margin-top: var(--spacer-30);
      }

      p {
        display: flex;
        flex-direction: column;
        margin: var(--spacer-30) 0 var(--spacer-44) var(--spacer-50);
        width: 50%;

        span {
          font-size: var(--font-size-14);
          line-height: var(--spacer-16);
          color: var(--dark-gray);
        }

        a {
          color: var(--orange);
          font-size: var(--font-size-20);
          line-height: var(--spacer-20);
        }
      }
    }

    .sf-accordion-item {
      box-sizing: border-box;
      margin: var(--spacer-10);
      position: relative;

      .sf-accordion-item__header {
        background: var(--light-gray);
        font-size: var(--font-size-18);
        justify-content: space-between;
        border: 1px solid transparent;
        padding: var(--spacer-14) 0 var(--spacer-14) var(--spacer-20);
        text-align: left;

        @include font-icon(var(--icon-shape));

        &:before {
          position: absolute;
          right: 0;
          font-size: var(--font-size-12);
          display: block;
          margin: var(--spacer-15);
          height: var(--spacer-6);
          transition: transform 0.2s ease;
          width: var(--spacer-12);

          @include for-desktop {
            margin-right: var(--spacer-36);
          }
        }

        &.sf-accordion-item__header--open {
          background: transparent;
          border: 1px solid var(--gray3);
          border-bottom: none;

          &:before {
            transform: rotate(180deg);
            transition: transform 0.2s ease;
          }
        }

        @include for-desktop {
          padding: var(--spacer-22) 0 var(--spacer-22) var(--spacer-30);
        }
      }

      .sf-accordion-item__content {
        border: 1px solid var(--gray3);
        border-top: none;
      }
    }

    .item-position {
      border-top: 1px solid var(--gray3);
      font-weight: 400;
      list-style: none;
      margin: 0 var(--spacer-20) 0;
      padding-top: var(--spacer-20);
      padding-left: 0;

      @include for-desktop {
        display: flex;
        padding-top: 0;

        &:not(:first-child) {
          li {
            margin-top: var(--spacer-20);
          }

          span {
            display: none;
          }
        }
      }

      .position,
      .full-name,
      .telephone,
      .email {
        display: flex;
        font-size: var(--font-size-14);
        line-height: var(--spacer-16);
        margin-bottom: var(--spacer-20);
        word-break: break-word;

        @include for-desktop {
          flex-direction: column;
          flex: 25% 1 0;
        }

      }

      .full-name > span {
        text-transform: uppercase;
      }

      .email a {
        color: var(--orange);
      }

      span {
        color: var(--dark-gray);
        display: block;
        font-size: var(--font-size-13);
        line-height: var(--spacer-15);
        text-transform: lowercase;
        max-width: 125px;
        width: 100%;

        @include for-desktop {
          border-bottom: 1px solid var(--gray3);
          margin-bottom: var(--spacer-20);
          padding: var(--spacer-10) var(--spacer-58) var(--spacer-10) 0;
        }
      }
    }
  }
}
</style>
