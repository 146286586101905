<template>
  <div
    class="bank-offers"
    id="bank-offers"
  >
    <AStatic
      :content="content"
      ref="bankOffers"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AStatic from 'theme/components/atoms/a-static';
import { getProductImagePath } from 'theme/helpers';
import Vue from 'vue';
import AFaq from 'theme/components/atoms/a-faq.vue';

export default {
  name: 'BankOffers',
  data () {
    return {
      productInCartChangedQty: null
    }
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  },
  mounted () {
    this.mapAccordion();
  },
  methods: {
    ...mapActions({
      updateQuantity: 'cart/updateQuantity'
    }),
    getImage (sku) {
      return getProductImagePath(sku, 800, 800);
    },
    mapAccordion () {
      try {
        const accordion = this.$refs?.bankOffers?.$el?.querySelector('#accordion');

        if (!accordion) return

        const title = accordion?.previousElementSibling?.classList?.contains('sf-heading__title')
          ? accordion?.previousElementSibling : null

        this.faqTitle = title?.innerText || ''

        if (title) {
          title.classList.add('display-none')
        }

        if (!accordion) {
          return '';
        }
        let accordionItems = accordion.querySelectorAll('.accordion-item');

        const items = [...accordionItems].map((el, i) => {
          const accordionItem = {
            id: i,
            title: this.$t(el.querySelector('.title')?.textContent || ''),
            text: el.querySelector('.accordion-item-text')?.innerHTML || ''
          };

          return accordionItem
        })

        this.faqs = items

        // Replace the div with your Vue component
        if (accordion) {
          const component = new Vue({
            render: h => h(AFaq, {
              props: {
                faqTitle: this.faqTitle,
                faqs: this.faqs
              }
            })
          }).$mount();

          // Replace the div with the rendered component
          accordion.parentNode.replaceChild(component.$el, accordion);
        }
      } catch (e) {}
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";
@import '~theme/css/fonts';

.bank-offers {
  ::v-deep {
    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }

      .center {
        max-width: px2rem(800);
        margin: var(--spacer-30) auto 0;

        .sf-heading {
          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-50);
          }

          &__title {
            text-align: left;
            font-size: var(--font-size-20);
            font-weight: var(--font-medium);
            color: var(--black);

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-32);
            }
          }
        }
      }
    }

    .banner {
      color: var(--white);
      min-height: px2rem(150);
      overflow: hidden;
      margin-bottom: var(--spacer-30);

      @media (min-width: $tablet-min) {
        max-width: px2rem(990);
        margin: 0 auto;
        max-height: px2rem(250);
      }

      .images-wrapper--mobile {
        @media (min-width: $tablet-min) {
          display: none;
        }
      }

      .images-wrapper--desktop {
        @media (max-width: $mobile-max) {
          display: none;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    h1 {
      @include header-title;
      margin-bottom: var(--spacer-30);
    }

    .content-wrapper {
      max-width: px2rem(820);
      margin: 0 auto var(--spacer-50);
      padding: 0 var(--spacer-10);

      h2 {
        margin: var(--spacer-50) 0 0 0;
        font-size: var(--font-size-16);
        font-weight: var(--font-medium);
        color: var(--black);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
        }
      }

      ol {
        margin-top: var(--spacer-10);
        padding: 0 0 0 var(--spacer-18);

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-25);
        }

        li {
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);
        }
      }

      p {
        margin: var(--spacer-10) 0 0 0;
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);

        @media (min-width: $tablet-min) {
          margin: var(--spacer-20) 0 0 0;
        }

        a {
          color: var(--orange);
        }
      }
    }
  }

  ::v-deep {
    .faq-section {
      margin-top: px2rem(25)!important;
    }

    .cards {
      border: 1px solid #EDEEF1;
      max-width: px2rem(990);
      margin: 0 auto;

      .row {
        display: flex;
        border-bottom: 1px solid #EDEEF1;

        @media (max-width: $tablet-min) {
          flex-direction: column;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .card {
        padding: px2rem(26) px2rem(26) px2rem(34) px2rem(34);
        flex: 1;
        display: flex;
        flex-direction: column;

        &:first-child {
          border-right: 1px solid #EDEEF1;
        }

        @media (min-width: $tablet-min) {
          &:first-child {
            border-bottom: 1px solid #EDEEF1;
          }
        }

        &-image {
          margin-bottom: px2rem(35);

          &-placeholder {
            background: var(--light-gray);
            width: 100%;
            height: px2rem(240);
            border-radius: px2rem(20);
          }
        }

        &-title {
          font-weight: 500;
        }

        &-content {
          max-width: 415px;

          a {
            color: var(--c-primary);
            text-decoration: none;

            &:hover {
              color: var(--c-text);
            }
          }
        }

        &-action {
          flex: 1;
          align-items: flex-end;
          justify-content: flex-start;
          display: flex;
        }

        .sf-button {
          display: inline-block;
          &:hover {
            color: var(--button-color)
          }
        }
      }
    }

    h2 {
      font-family: 'Inter';
      font-weight: 500;
      font-size: var(--font-size-24);
    }

    .accordion {
      max-width: px2rem(790);
      width: 100%;
      margin: 0 auto;
    }

    .sf-accordion-item {

      box-sizing: border-box;
      margin: var(--spacer-10) 0;
      position: relative;

      @media (min-width: $tablet-min) {
        margin: var(--spacer-12) 0;
      }

      .sf-accordion-item__header {
        background: var(--light-gray);
        font-size: var(--font-size-14);
        border: 1px solid transparent;
        line-height: var(--spacer-24);
        justify-content: space-between;
        padding: var(--spacer-15) 0 var(--spacer-15) var(--spacer-15);
        text-align: left;
        padding-right: var(--spacer-40);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-18);
        }

        @include font-icon(var(--icon-shape));

        &:before {
          position: absolute;
          right: 0;
          font-size: var(--font-size-12);
          margin: var(--spacer-15);
          height: var(--spacer-15);
          transition: transform 0.2s ease;
          width: var(--spacer-15);

          @media (min-width: $tablet-min) {
            margin-right: var(--spacer-36);
          }
        }

        &.sf-accordion-item__header--open {
          background: transparent;
          border: 1px solid var(--gray3);
          border-bottom: none;

          &:before {
            transform: rotate(180deg);
            transition: transform 0.2s ease;
          }
        }

        @media (min-width: $tablet-min) {
          padding: var(--spacer-30) 0 var(--spacer-30) var(--spacer-30);
        }
      }

      .sf-accordion-item__content {
        border: 1px solid var(--gray3);
        border-top: none;
        padding: 0 var(--spacer-26) var(--spacer-15) var(--spacer-15);

        @media (min-width: $tablet-min) {
          padding: 0 var(--spacer-65) var(--spacer-30) var(--spacer-30);
        }

        .accordion-item-text {
          color: var(--black);
          font-weight: 400;
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-16);
            line-height: var(--spacer-28);
          }
        }
      }
    }

    @media (max-width: $tablet-min) {
      .hide-mobile {
        display: none!important;
      }
    }
  }
}
</style>
