<template>
  <div class="anti-corruption">
    <AStatic
      class="anti-corruption-content"
      :content="content"
      ref="antiCorruption"
    />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'

export default {
  name: 'AntiCorruption',
  mixins: [customFormSpinnerLoading],
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.anti-corruption {
  ::v-deep {
    .orange-text {
      color: var(--orange);
    }

    .container {
      margin: 0 var(--spacer-10);

      @media (min-width: $tablet-min) {
        max-width: px2rem(990);
        margin: 0 auto;
        min-height: px2rem(250);
      }
    }

    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }
    }

    // Banner
    .banner {
      color: var(--black);
      background: linear-gradient(94.39deg, #EBE9E4 12.72%, #EBE9E4 90.6%);
      display: flex;
      min-height: px2rem(150);
      overflow: hidden;
      position: relative;
      margin-bottom: var(--spacer-30);

      @media (min-width: $tablet-min) {
        max-width: px2rem(990);
        margin: 0 auto;
        max-height: px2rem(250);
      }

      &-text {
        font-weight: 500;
        margin-top: 0;
        font-size: var(--font-size-16);
        line-height: var(--spacer-18);
        width: 100%;
        max-width: 240px;

        @media (min-width: $tablet-min) {
          width: 100%;
          max-width: 100%;
          font-weight: 500;
          font-size: var(--font-size-32);
          line-height: var(--spacer-33);
          color: var(--black);
        }
      }

      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white);
        margin: 0;
        width: auto;
        max-width: px2rem(215);
        height: px2rem(40);
        outline: none;
        border: none;
        cursor: pointer;
        padding: 8px 16px;
        box-sizing: border-box;

        @media (min-width: $tablet-min) {
          min-width: px2rem(273);
          height: px2rem(53);

          span {
            line-height: var(--spacer-24);
          }
        }

        span {
          white-space: nowrap;
          font-style: normal;
          font-weight: 500;
          text-align: center;
          color: var(--black);
          line-height: var(--spacer-24);
        }
      }
    }

    .description {
      margin: var(--spacer-35) var(--spacer-25) var(--spacer-40);
      z-index: 1;

      @media (min-width: $tablet-min) {
        margin: 64px 0 69px 77px;
      }
    }

    .images-wrapper {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;

      img {
        filter: drop-shadow(0px 0px 150px #58A0C8);
      }

      .banner-img {
        & > img {
          height: 100%;
          position: relative;
        }
      }
    }

    .center {
      padding: 0 var(--spacer-13);
      max-width: px2rem(790);
      margin: 0 auto;

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-50);
        padding: 0;
      }
    }

    .title {
      padding-bottom: 0;

      & > h2 {
        @include header-title;
      }

      & > h3 {
        text-align: left;
        font-weight: 500;
        font-size: var(--font-size-20);
        line-height: var(--spacer-19);
        color: var(--black);

        @media (min-width: $tablet-min) {
          font-weight: 500;
          font-size: var(--font-size-24);
          line-height: var(--spacer-25);
        }
      }
    }

    .main-information {
      margin-bottom: var(--spacer-30);

      @media (min-width: $tablet-min) {
        margin-bottom: var(--spacer-50);
      }

      &__text {
        font-weight: 400;
        font-size: var(--font-size-16);
        line-height: var(--spacer-26);
        color: var(--dark-gray);
        margin-top: var(--spacer-15);
        margin-bottom: var(--spacer-10);

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-30);
          margin-bottom: var(--spacer-15);
          line-height: var(--spacer-28);
        }
      }

      &__subtext {
        font-size: var(--font-size-14);
        font-weight: 400;
        line-height: var(--spacer-24);
        margin: 0 0 var(--spacer-10);
      }
    }

    .custom__form {
      max-height: px2rem(673);
      overflow: hidden;

      @media (min-width: $tablet-min) {
        max-height: px2rem(632);
      }
    }

    .other-contact-form__content {
      margin-top: var(--spacer-20);
      padding: var(--spacer-30);
      background: #F3F4F6;
      display: flex;
      flex-direction: column;
      min-height: 171px;
      box-sizing: border-box;

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-15);
        padding: 32px 49px 38px 52px;
        flex-direction: row;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: var(--font-size-14);
        line-height: var(--spacer-17);
        color: var(--dark-gray);
        margin-bottom: var(--spacer-15);
      }

      &-email {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: var(--spacer-30);

        @media (min-width: $tablet-min) {
          margin-bottom: 0;
        }

        a {
          font-style: normal;
          font-weight: 500;
          font-size: var(--font-size-18);
          line-height: var(--spacer-18);
          color: var(--orange);
          margin: 0;
        }
      }

      &-description {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        height: 100%;

        p {
          font-weight: 400;
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);
          color: var(--black);
          margin: 0;
        }
      }
    }
  }
}
</style>
