<template>
  <div class="tasty-job-page" :key="componentKey">
    <AStatic
      :content="content"
      custom-class="tasty-job"
      ref="tastyJob"
    />
    <MTastyJobStatisticsBox
      ref="tastyJobStatisticsBox"
      :advantages-content="advantagesContent"
    />
    <MTastyJobCarousel
      v-if="isLoadingCarousel"
      :slider-settings="sliderSettings"
      ref="tastyJobCarousel"
      :items="sliderItems"
    />
    <MTastyJobForm
      ref="tastyJobFormRef"
      :content="content"
    />
  </div>
</template>
<script>
import AStatic from 'theme/components/atoms/a-static'
import MTastyJobStatisticsBox from 'theme/components/molecules/m-tasty-job-statistics-box'
import MTastyJobCarousel from 'theme/components/molecules/m-tasty-job-carousel'
import { BREAKPOINT_SM, BREAKPOINT_MD } from 'theme/helpers/breakpoints'
import MTastyJobForm from 'theme/components/molecules/m-tasty-job-form'
import { mapGetters } from 'vuex'

export default {
  name: 'TastyJob',
  components: {
    MTastyJobStatisticsBox,
    AStatic,
    MTastyJobCarousel,
    MTastyJobForm
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  data () {
    return {
      sliderItems: [],
      isLoadingCarousel: false,
      componentKey: 0,
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        gap: 10,
        perView: 2,
        peek: {
          before: 0,
          after: 0
        },
        breakpoints: {
          [BREAKPOINT_MD]: {
            gap: 10,
            perView: 2,
            peek: {
              before: 0,
              after: 0
            }
          },
          [BREAKPOINT_SM - 1]: {
            animationTimingFunc: 'ease-in-out',
            animationDuration: 2000,
            rewindDuration: 2000,
            perView: 2,
            peek: {
              before: 0,
              after: 0
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      advantagesContent: 'homepage/getAdvantagesContent'
    })
  },
  watch: {
    sliderItems: {
      immediate: true,
      handler (val) {
        if (val.length > 0) {
          this.isLoadingCarousel = true
        }
      }
    }
  },
  methods: {
    forceRerender () {
      this.componentKey += 1
    },
    moveStatisticsBlock (refTastyJob) {
      const refJobStatisticsBox = this.$refs.tastyJobStatisticsBox

      const getStatisticsBox = refTastyJob.$el.querySelector('.statistic')

      getStatisticsBox.innerHTML = ''

      getStatisticsBox.appendChild(refJobStatisticsBox.$el)
    },
    goToForm () {
      const element = document.querySelector('#custom__form')

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    moveCustomForm () {
      const getRefFrom = this.$refs.tastyJobFormRef.$el

      document.querySelector('.tasty-job #custom__form').remove()

      document.querySelector('.content').append(getRefFrom)
    },
    moveCarousel (refTastyJob) {
      const refCarousel = this.$refs.tastyJobCarousel
      const getImagesForCarousel = refTastyJob.$el.querySelector('.tasty-job__store__carousel')

      getImagesForCarousel.innerHTML = ''

      if (this.sliderItems.length > 0) {
        getImagesForCarousel.appendChild(refCarousel.$el)
      } else {
        refCarousel.$el.remove()
      }
    },
    prepareImagesForCarousel (element) {
      const getLinks = element.querySelector('.tasty-job__store__carousel').children

      if (getLinks && getLinks.length > 0) {
        this.sliderItems = Array.from(
          getLinks
        ).map(value => {
          return {
            url: value.src,
            name: ''
          }
        })
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const refTastyJob = this.$refs.tastyJob

      this.moveStatisticsBlock(refTastyJob)
      this.moveCarousel(refTastyJob)

      const getBtn = refTastyJob.$el.querySelector('.go-to-form')

      if (getBtn) {
        getBtn.addEventListener('click', this.goToForm)
      }

      this.moveCustomForm()
    })

    this.forceRerender()
  },
  beforeMount () {
    if (typeof document === 'undefined') {
      return
    }

    const wrapper = document.createElement('div')
    wrapper.innerHTML = this.content.message

    this.prepareImagesForCarousel(wrapper)
  },
  beforeDestroy () {
    document.querySelector('.go-to-form')
      .removeEventListener('click', this.goToForm)
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.tasty-job-page {
  ::v-deep {
    .sf-heading {
      display: none;
    }

    .custom__form.custom__form-tasty-job {
      margin-top: 0;
      padding: 0 var(--spacer-10);

      @include only-mobile {
        padding-bottom: var(--spacer-50);
      }

      .custom-form__iframe {
        min-height: 690px;
      }

      .custom__form-block {
        min-width: inherit;

        @media (min-width: $tablet-min) {
          min-width: 450px;
        }
      }

      .custom__form-description {
        .vacancy-url {
          color: var(--orange);
        }

        p {
          margin: var(--spacer-10) 0 0 0;

          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-15);
          }

          &:last-of-type {
            margin-bottom: var(--spacer-25);

            @media (min-width: $tablet-min) {
              margin-top: var(--spacer-10);
            }
          }
        }
      }

      .sf-heading {
        display: block;

        h4 {
          font-weight: var(--font-medium);
          font-size: var(--font-size-20);
          line-height: var(--font-size-24);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-32);
            line-height: var(--font-size-33);
          }
        }
      }
    }

    .tasty-job {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 var(--spacer-10);

      p {
        margin: 0;
        padding: 0;
      }

      h2 {
        font-size:  var(--font-size-20);
        line-height: var(--font-size-24);
        font-weight: var(--font-medium);
        color: var(--black);
        margin: 0;

        @media (min-width: $tablet-min) {
          font-size:  var(--font-size-32);
        }
      }

      &__our-philosophy > h2 {
        @include header-title;
      }

      > div {
        width: 100%;
      }

      div.banner-toggle-images {
        color: var(--black);
        background: linear-gradient(94.39deg, #C7A9D5 12.72%, #34818C 90.6%);
        display: flex;
        min-height: px2rem(150);
        overflow: hidden;
        position: relative;

        @media (min-width: $tablet-min) {
          max-width: px2rem(990);
          margin: 0 auto;
          max-height: px2rem(250);
        }

        .images-wrapper {
          z-index: 1;
        }

        .description {
          margin: var(--spacer-15) var(--spacer-25) var(--spacer-40);
          z-index: 1;

          &::after {
            content: '';
            position: absolute;
            box-shadow: -30px 75px 101px 102px rgba(255,182,128,0.94);
            z-index: -1;
            left: 74%;
            top: 62%;

            @include for-desktop {
              box-shadow: 2px 78px 110px 154px rgba(255,182,128,0.94);
            }

            @include for-tablet {
              left: auto;
              right: 35%;
            }
          }

          @include for-desktop {
            margin: var(--spacer-46) 0 var(--spacer-70) px2rem(77);
          }

          @include for-tablet {
            margin: var(--spacer-36) 0 0 px2rem(30);
          }

          .banner-text {
            font-weight: 500;
            margin: 0 0 var(--spacer-10) 0;
            color: var(--white);
            font-size: var(--font-size-18);
            line-height: var(--spacer-22);
            width: 100%;
            max-width: 65%;

            @include for-desktop {
              max-width: 80%;
              margin: 0 0 var(--spacer-30) 0;
              font-size: var(--font-size-32);
              line-height: var(--spacer-33);
            }
          }

          .banner-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--white);
            font-weight: var(--font-medium);
            margin: 0;
            width: auto;
            max-width: px2rem(126);
            height: px2rem(40);
            outline: none;
            border: none;
            cursor: pointer;
            padding: 8px 16px;
            box-sizing: border-box;
            white-space: nowrap;

            @media (min-width: $tablet-min) {
              max-width: px2rem(176);
              height: px2rem(53);
              font-size: var(--font-size-18);

              span {
                line-height: var(--spacer-24);
              }
            }

            span {
              white-space: nowrap;
              font-style: normal;
              font-weight: 500;
              text-align: center;
              color: var(--black);
              line-height: var(--spacer-24);
            }
          }
        }
      }

      .dark-gray {
        color: var(--dark-gray);
        font-size: var(--font-size-16);
      }

      &__store-list,
      &__middle-banner,
      &__our-philosophy {
        max-width: 790px;
      }

      &__our-philosophy {
        h2 {
          margin: var(--spacer-30) 0 var(--spacer-15) 0;

          @media (min-width: $tablet-min) {
            margin: var(--spacer-50) 0 var(--spacer-30) 0;
          }
        }

        .red-box {
          border: 1px solid var(--gray3);
          padding: var(--spacer-20);
          margin: var(--spacer-25) 0;

          @media (min-width: $tablet-min) {
            padding: var(--spacer-30);
            margin: var(--spacer-30) 0;
          }

          p {
            color: var(--orange);
            font-weight: var(--font-semibold);
            font-size: var(--font-size-20);
            line-height: var(--font-size-30);

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-22);
            }
          }
        }

        p {
          font-size: var(--font-size-14);
          line-height: var(--font-size-24);
        }

        > p:last-of-type {
          margin-top: var(--spacer-20);
        }
      }

      .description-box {
        &__card {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: var(--spacer-10);
          padding: 0;
          margin: 0;
          list-style: none;

          @include for-desktop {
            grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
          }

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            position: relative;
            height: auto;
            padding: var(--spacer-10);
            background: var(--light-gray);

            @media (min-width: $tablet-min) {
              height: px2rem(258);
              padding: var(--spacer-20);
            }
          }
        }

        &__image-wrapper {
          height: px2rem(74);
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid var(--gray);
          padding-bottom: var(--spacer-10);

          @media (min-width: $tablet-min) {
            height: px2rem(160);
            max-height: inherit;
            padding-bottom: 0;
          }

          img {
            max-width: 110px;

            @media (min-width: $tablet-min) {
              max-width: 212px;
            }
          }
        }

        &__text {
          font-size:  var(--font-size-13);
          line-height: px2rem(16);
          width: 100%;
          margin-top: var(--spacer-10);

          @media (min-width: $tablet-min) {
            font-size:  var(--font-size-16);
            line-height: px2rem(19);
            margin-top: var(--spacer-20);
          }
        }

        &__top-panel {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: var(--spacer-15);

          h2 {
            @include for-mobile {
              font-size: var(--spacer-16);
            }
          }

          @media (min-width: $tablet-min) {
            padding-bottom: var(--spacer-25);
          }
        }
      }

      &--box-first {
        padding: var(--spacer-30) 0 var(--spacer-50) 0;

        @media (min-width: $tablet-min) {
          padding: var(--spacer-50) 0 var(--spacer-40) 0;
        }
      }

      &--box-second {
        padding: var(--spacer-50) 0;

        @media (min-width: $tablet-min) {
          padding: var(--spacer-88) 0 var(--spacer-80) 0;
        }
      }

      &--btn {
        background: #EB6747;
        padding: var(--spacer-8) var(--spacer-16);
        text-align: center;
        border: unset;
        font-size: var(--font-size-14);
        line-height: var(--font-size-24);
        color: var(--white);
        cursor: pointer;
      }

      &__middle-banner {
        p {
          font-size: var(--font-size-14);
          line-height: var(--font-size-24);
        }

        P:first-of-type + p {
          padding: var(--font-size-20) 0;
        }

        img {
          padding-bottom: var(--spacer-30);

          @media (min-width: $tablet-min) {
            max-width: 790px;
            padding-bottom: px2rem(47);
          }
        }

        .statistic {
          .m-advantages {
            padding-top: var(--spacer-50);
            padding-bottom: 0;

            @include for-mobile {
              .sf-carousel__controls {
                display: none;
              }
            }
          }

          .m-advantages-list {
            border: 1px solid var(--gray);
            padding: var(--spacer-32) 0 var(--spacer-42) 0;

            .m-advantages__item {
              border-right: 1px solid var(--gray);
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: px2rem(74);
              align-items: center;
              flex-grow: 1;

              &:last-of-type {
                border-right: unset;
              }

              &-title {
                font-size: px2rem(36);
                line-height: 52px;
                margin-bottom: 0;
              }

              &-desc {
                color: var(--dark-gray);
                font-size: px2rem(16);
                line-height: 19px;
                margin-top: -5px;
              }
            }
          }
        }
      }

      &__store-list {
        @media (min-width: $tablet-min) {
          margin-bottom: var(--spacer-50);
        }

        h2 {
          margin-bottom: var(--spacer-5);

          @media (min-width: $tablet-min) {
            margin-bottom: var(--spacer-20);
          }
        }

        h2 {
          margin-top: 0;
          font-weight: var(--font-medium);
          font-size: var(--font-size-16);
          line-height: 19px;

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-24);
            line-height: 25px;
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        > li + li + li {
          margin-bottom: var(--spacer-50);

          @media (min-width: $tablet-min) {
            margin-bottom: var(--spacer-80);
          }
        }

        &-varus {
          margin-bottom: var(--spacer-30);

          @media (min-width: $tablet-min) {
            margin-bottom: var(--spacer-25);
          }

          h2 {
            margin-bottom: var(--spacer-15);
          }

          &__box-describe {
            font-size: var(--font-size-14);
            line-height: 24px;
            width: 49.3%;
            flex-grow: 1;

            @include for-mobile {
              order: 0;
            }

            p {
              padding-bottom: 8px;

              &:last-of-type {
                padding-bottom: 0;
              }
            }

            .tasty-job--btn {
              margin-top: var(--spacer-15);
            }
          }

          > div {
            display: flex;
            flex-wrap: wrap;
            grid-gap: var(--spacer-10);

            > *:not(div) {
              width: 49.3%;
              flex-grow: 1;

              @include for-mobile {
                width: 31%;
                order: -1;
              }
            }

            img {
              @include for-mobile {
                height: px2rem(111);
              }
            }
          }
        }

        &__banner-section {
          position: relative;

          .tasty-job--btn {
            position: absolute;
            bottom: 0;
            font-weight: var(--font-medium);
            font-size: var(--font-size-18);
            background: var(--white);
            color: var(--black);
            padding: var(--spacer-16) var(--spacer-18);
            margin: 0 0 var(--spacer-35) var(--spacer-35);

            @media (min-width: $tablet-min) {
              padding: var(--spacer-16) var(--spacer-30);
              margin: 0 0 var(--spacer-40) var(--spacer-65);
            }
          }

          .mobile-banner {
            display: block;

            @media (min-width: $tablet-min) {
              display: none;
            }
          }

          .desc-banner {
            display: none;

            @media (min-width: $tablet-min) {
              display: block;
            }
          }
        }

        &__simple-text-box {
          margin: var(--spacer-50) 0 var(--spacer-15) 0;

          @media (min-width: $tablet-min) {
            margin: var(--spacer-80) 0;
          }

          h2 {
            margin-bottom: var(--spacer-15);

            @media (min-width: $tablet-min) {
              margin-bottom: var(--spacer-20);
            }
          }

          > p:first-of-type {
            padding-bottom: var(--spacer-10);
          }
        }

        .m-tasty-carousel {
          position: relative;

          .sfo-carousel__bullets {
            display: flex;
            padding-top: 0;
            padding: var(--spacer-25) 0;
            z-index: 1;
            position: relative;

            .sf-bullets > li {
              margin-bottom: 0;
            }

            @media (min-width: $tablet-min) {
              display: none;
            }
          }

          .sf-carousel__controls {
            transform: var(--carousel-controls-transform, translate3d(0, -50%, 0));
            display: var(--carousel-controls-display, none);
            justify-content: var(--carousel-controls-justify-content, space-between);
            --carousel-controls-size: 15rem;
            --carousel-controls-display: flex;

            @media (max-width: $tablet-max) {
              --carousel-controls-display: none;
            }
          }

          &__item {
            max-width: 100%;
            height: px2rem(170);

            &-image {
              height: px2rem(170);
            }

            @media (min-width: $tablet-min) {
              height: px2rem(290);

              &-image {
                height: px2rem(290);
              }
            }
          }
        }
      }
    }
  }
}
</style>
