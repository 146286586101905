import Vue from 'vue';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';

export default {
  name: 'customFormSpinnerLoading',
  data () {
    return {
      customFormClasses: ['.custom__form-block', '.quiz__form-container'],
      renderComponents: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setLoadingSpinnerToCustomForm()
    })
  },
  methods: {
    setLoadingSpinnerToCustomForm () {
      this.customFormClasses.forEach(this.createSpinnerOnElement);
    },
    createSpinnerOnElement (element, index) {
      const formContainer = document.querySelector(element);

      if (!formContainer) return;

      this.setStyleToContainer(formContainer);
      this.createSpinner(index, formContainer);

      const iframe = formContainer.querySelector('iframe');
      if (iframe) this.handleIframeLoading(iframe);
    },
    setStyleToContainer (formContainer) {
      formContainer.style.position = 'relative';
    },
    createSpinner (index, formContainer) {
      const spinnerWrapper = document.createElement('div');
      const spinnerId = `loading-spinner-node-${index}`;

      spinnerWrapper.id = spinnerId;
      formContainer.appendChild(spinnerWrapper);
      this.renderSpinnerComponent(spinnerId);
    },
    renderSpinnerComponent (spinnerId) {
      const LoadingSpinnerComp = Vue.extend(ALoadingSpinner);

      this.renderComponents.push(
        new LoadingSpinnerComp({
          propsData: {
            isAbsolutePosition: true
          }
        }).$mount(`#${spinnerId}`)
      );
    },
    handleIframeLoading (frame) {
      frame.addEventListener('load', this.removeSpinner);
    },
    removeSpinner () {
      this.renderComponents.forEach(this.destroyComponent);
      this.renderComponents = [];
    },
    destroyComponent (component) {
      component.$destroy();
      if (component.$el.parentNode) {
        component.$el.parentNode.removeChild(component.$el);
      }
    }
  },
  beforeDestroy () {
    this.customFormClasses.forEach((element) => {
      const formContainer = document.querySelector(element)

      if (!formContainer) return

      formContainer.querySelector('iframe')
        .removeEventListener('load', this.removeSpinner);
    })
  }
}
