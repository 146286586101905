<template>
  <div
    ref="image"
    class="sf-image"
    :class="{ 'sf-image--has-size': size }"
    :style="size"
    v-on="$listeners"
  >
    <ALoadingSpinner
      v-show="!isLoaded && !hideLoader"
      :is-absolute-position="true"
    />
    <template v-if="isPicture">
      <picture>
        <source
          :srcset="source.desktop.url"
          :media="`(min-width: ${pictureBreakpoint}px)`"
        >
        <source
          :srcset="source.mobile.url"
          :media="`(max-width: ${pictureBreakpoint - 1}px)`"
        >
        <img
          v-show="source.desktop.url"
          :src="source.desktop.url"
          v-bind="$attrs"
          :width="width"
          :height="height"
          :alt="alt"
          :loading="lazy ? 'lazy' : 'eager'"
          @load="onLoad"
          @error="onError"
        >
      </picture>
    </template>
    <template v-else>
      <img
        v-show="source"
        :src="source"
        v-bind="$attrs"
        :width="width"
        :height="height"
        :alt="alt"
        :loading="lazy ? 'lazy' : 'eager'"
        @load="onLoad"
        @error="onError"
      >
    </template>
    <noscript v-if="lazy && noscript" inline-template>
      <img
        class="noscript"
        :src="noscript"
        v-bind="$attrs"
        :width="width"
        :height="height"
        :alt="alt"
        :loading="lazy ? 'lazy' : 'eager'"
      />
    </noscript>
    <div v-if="hasOverlay" class="sf-image__overlay">
      <slot />
    </div>
  </div>
</template>
<script>
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
export default {
  name: 'SfOImage',
  inheritAttrs: false,
  components: {
    ALoadingSpinner
  },
  props: {
    src: {
      type: [String, Object],
      default: ''
    },
    lazy: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    },
    pictureBreakpoint: {
      type: Number,
      default: 1024
    },
    alt: {
      type: String,
      default: ''
    },
    hideLoader: {
      type: Boolean,
      default: false
    },
    enableEvents: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoaded: false
    };
  },
  computed: {
    isPicture () {
      return !!this.src && typeof this.src === 'object';
    },
    source () {
      return this.src;
    },
    noscript () {
      return this.isPicture ? this.src.desktop.url : this.src;
    },
    size () {
      return (
        this.width &&
        this.height && {
          '--_image-width': this.width,
          '--_image-height': this.height
        }
      );
    },
    hasOverlay () {
      return this.$slots.default;
    }
  },
  mounted () {
    const isLoaded = this.checkLoaded()

    if (!isLoaded) return

    this.onLoad()
  },
  methods: {
    checkLoaded () {
      try {
        return this.$refs.image?.querySelector('img')?.complete
      } catch (e) {
        return false
      }
    },
    onLoad () {
      this.isLoaded = true

      if (!this.enableEvents) return

      this.$emit('on-load')
    },
    onError () {
      this.isLoaded = false

      if (!this.enableEvents) return

      this.$emit('on-error')
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfImage.scss";
</style>
