<template>
  <div id="suppliers" class="suppliers">
    <AStatic :content="content" ref="suppliers" />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'

export default {
  name: 'Suppliers',
  mixins: [customFormSpinnerLoading],
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  },
  mounted () {
    this.$refs['suppliers'].$el
      ?.querySelector('.banner-button')
      .addEventListener('click', this.moveToBanner);
  },
  methods: {
    moveToBanner () {
      const customForm = document.querySelector('.custom__form')
      customForm.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  },
  beforeDestroy () {
    this.$refs['suppliers'].$el
      .querySelector('.banner-button')
      .removeEventListener('click', this.moveToBanner);
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

#static {
  .suppliers {
    ::v-deep {
      .supplier__form {

        .custom__form-container {
          grid-column-gap: px2rem(73);

          .custom__form-description {
            flex: 0 1 42%;
            padding-top: var(--spacer-50);

            @media (min-width: $tablet-min) {
              padding-top: var(--spacer-70);
            }
          }

          iframe {
            min-height: px2rem(870);

            @media (min-width: $tablet-min) {
              min-width: 450px;
            }

            @media only screen and (max-width: 552px){
              min-height: px2rem(1035);
            }
          }
        }
      }
    }
  }
}

.suppliers {

  ::v-deep {
    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }
    }

    // Banner
    .banner {
      color: var(--white);
      background: linear-gradient(94.39deg, #61ABB6 12.72%, #34818C 90.6%);
      display: flex;
      justify-content: space-between;
      font-family: var(--font-family-inter);
      margin: 0 var(--spacer-10) var(--spacer-30);
      height: px2rem(150);
      overflow: hidden;

      @include for-desktop {
        max-width: px2rem(990);
        margin: 0 auto;
        height: px2rem(250);
      }

      &-text {
        font-weight: 500;
        margin-top: 0;
        font-size: var(--font-size-16);
        line-height: var(--spacer-18);
        width: 100%;

        @include for-desktop {
          width: 100%;
          max-width: 100%;
          font-weight: 500;
          font-size: var(--font-size-32);
          line-height: var(--spacer-33);
          color: var(--white);
        }
      }

      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white);
        margin: 0;
        height: px2rem(40);
        outline: none;
        border: none;
        cursor: pointer;
        padding: 8px 16px;

        @include for-desktop {
          max-width: px2rem(218);
        }

        span {
          font-style: normal;
          font-weight: 500;
          text-align: center;
          color: var(--black);
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);
        }

        @include for-desktop {
          min-width: px2rem(273);
          height: px2rem(56);

          span {
            font-size: var(--font-size-18);
            line-height: var(--spacer-24);
          }
        }
      }
    }

    .description {
      margin: var(--spacer-25) 0 0 var(--spacer-15);
      flex: 1 0 px2rem(300);

      .banner-text {
        font-size: var(--font-size-18);
        font-weight: 500;
        line-height: var(--spacer-22);
        margin-top: 0;

        @include for-desktop {
          font-size: var(--font-size-32);
          line-height: var(--spacer-33);
        }

        span.text {
          display: block;
          font-size: var(--font-size-12);
          font-weight: 400;
          line-height: var(--spacer-16);
          margin-top: var(--spacer-8);

          @include for-desktop {
            font-size: var(--font-size-16);
            margin-top: var(--spacer-10);
            font-weight: 400;
            line-height: var(--spacer-23);
            opacity: 0.8;
          }
        }
      }

      @include for-desktop {
        margin: var(--spacer-45) 0 0 px2rem(52);
        flex: 1 0 px2rem(500);
      }
    }

    .images-wrapper {
      display: flex;
      justify-content: flex-end;
      position: relative;
      width: 100%;

      img {
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        height: 367px;
        width: 367px;
        top: 65px;
        left: 55px;
        background: #FFB680;
        filter: blur(100px);
      }

      &.mob-version {
        &::after {
          top: 0;
          left: 10px;
        }
      }
    }

    .center {
      padding: 0;
      max-width: 790px;
      margin: 0 auto;

      > header {
        padding-bottom: px2rem(15);

        @media (min-width: $tablet-min) {
          padding-bottom: px2rem(30);
        }
      }

      > header,
      .main-information,
      .become-supplier {
        margin: 0 var(--spacer-10);
      }

      @media (min-width: $tablet-min) {
        padding: 0 px2rem(13);
      }

      @include for-desktop {
        margin-top: var(--spacer-48);
        padding: 0;
      }
    }

    .title {
      padding-bottom: 0;

      & > h2 {
        @include header-title;
      }
    }

    .section-title {
      margin: var(--spacer-30) 0 var(--spacer-15);
      padding-bottom: 0;

      & > h2 {
        color: var(--black);
        font-size: var(--font-size-20);
        font-weight: 500;
        line-height: var(--spacer-24);
        text-align: left;
      }

      @include for-desktop {
        font-weight: 500;
        margin: var(--spacer-60) 0 var(--spacer-25);
        padding-bottom: 0;
        text-align: left;

        & > h2 {
          font-size: var(--font-size-24);
        }
      }
    }

    .main-information {
      p {
        font-size: var(--font-size-16);
        font-weight: 400;
        line-height: var(--spacer-28);
        margin: 0 0 var(--spacer-20);
        color: var(--dark-gray);

        &:first-child {
          margin-top: var(--spacer-15);
        }
      }
    }

    .become-supplier {
      margin-top: var(--spacer-30);

      .title {
        margin: 0;

        .sf-heading__title--h3 {
          font-size: var(--font-size-20);
          text-align: left;

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-24);
          }
        }
      }

      &-steps {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: var(--spacer-10);

        &-step {
          width: 100%;
          min-height: px2rem(240);
          margin-bottom: var(--spacer-10);
          background: var(--light-gray);
          padding: var(--spacer-30);
          box-sizing: border-box;
          font-family: var(--font-family-inter);

          @media (min-width: $tablet-min) {
            width: calc(50% - 5px);
            min-height: px2rem(270);
            padding: var(--spacer-30) var(--spacer-10) 0 var(--spacer-30);
          }

          &__number {
            width: var(--spacer-60);
            height: var(--spacer-60);
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--white);
            border-radius: var(--spacer-50);
            font-size: var(--font-size-24);
            color: var(--orange);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
          }

          &__title {
            margin: var(--spacer-15) 0 0 0;
            font-weight: 500;
            font-size: var(--font-size-16);
            color: var(--black);
            line-height: var(--spacer-24);

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-20);
            }
          }

          &__text {
            margin: var(--spacer-10) 0 0 0;
            font-weight: 400;
            font-size: var(--font-size-13);
            color: var(--dark-gray);
            line-height: 24px;
            padding: 0;
            list-style-position: inside;

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-14);
            }

            li::marker {
              color: var(--orange);
              font-size: var(--font-size-20);
            }

          }
        }
      }
    }
  }
}
</style>
