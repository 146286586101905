<template>
  <div
    class="post-filters"
    :class="{
      'post-filters--is-loading': isFilterLoading,
      'post-filters--z-index': isMenuVisible
    }"
  >
    <SfSelect
      v-model="yearProps"
      class="post-filters__years"
      :label="$t('Year of publication')"
      :disabled="false"
      :valid="false"
      placeholder=""
      @change="handlerYear"
    >
      <SfSelectOption value="all">
        {{ $t('All years') }}
      </SfSelectOption>
      <SfSelectOption
        v-for="(option, key) in years"
        :key="key"
        :value="option"
      >
        {{ option.key }}
      </SfSelectOption>
    </SfSelect>
    <SfSelect
      v-model="articleValueTypes"
      :label="$t('Article type')"
      class="post-filters__types"
      :valid="false"
      :disabled="false"
      placeholder=""
      @change="handlerTypePost"
    >
      <SfSelectOption value="all">
        {{ $t('All articles') }}
      </SfSelectOption>
      <SfSelectOption
        v-for="(option, key) in blogCategories"
        :key="key"
        :value="option"
      >
        {{ option.title }}
      </SfSelectOption>
    </SfSelect>
  </div>
</template>
<script>
import { SfSelect } from '@storefront-ui/vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'MFilterPosts',
  props: {
    defaultYear: {
      type: Object,
      default: () => []
    },
    years: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SfSelect
  },
  data () {
    return {
      yearProps: 'all',
      articleValueTypes: 'all',
      isMenuVisible: false
    }
  },
  watch: {
    isCatalogOpened (newValue, oldValue) {
      if (newValue !== oldValue) this.isMenuVisible = newValue
    }
  },
  computed: {
    ...mapState({
      isCatalogOpened: state => state.ui.isMobileCatalog
    }),
    ...mapGetters({
      isFilterLoading: 'blog/getIsFilterLoading',
      blogCategories: 'blog/getBlogCategories'
    })
  },
  methods: {
    ...mapActions({
      loadBlogCategories: 'blog/loadBlogCategories',
      filterByValue: 'blog/filterByValue',
      resetPosts: 'blog/resetPosts'
    }),
    handlerYear (value) {
      if (this.yearProps !== 'all') {
        this.filterByValue({ key: 'year', value: { 'eq': value } })
      } else {
        this.resetPosts()
      }
    },
    handlerTypePost (value) {
      if (this.articleValueTypes !== 'all') {
        this.filterByValue({ key: 'category.title', value: { 'eq': value } })
      } else {
        this.resetPosts()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.post-filters {
  display: flex;
  column-gap: var(--spacer-15);

  @media (min-width: $tablet-min) {
    column-gap: var(--spacer-10);
  }

  &--is-loading {
    opacity: 0.3;
    pointer-events: none;
  }

  &__years,
  &__types {
    flex: 1;
    background: var(--light-gray);
    border-radius: 5px;
    padding: px2rem(8) 0 px2rem(5) 0;
    max-width: 590px;
  }

  &--z-index {
    .post-filters__years,
    .post-filters__years {
      ::v-deep .sf-select__dropdown {
        z-index: 1;
      }
    }
  }

  ::v-deep .sf-select {
    --select-dropdown-position: absolute;
    --select-dropdown-top: 100%;
    --select-dropdown-bottom: auto;
    --select-animation-enter: ssdesktop 150ms;
    --select-animation-leave: ssdesktop 150ms reverse;

    &__cancel,
    &__error-message {
      display: none;
    }

    &__dropdown {
      bottom: var(--select-dropdown-bottom, px2rem(20));
      background: var(--light-gray);
      margin-top: var(--spacer-10);
      border-radius: var(--spacer-10);
      z-index: 2;
    }

    .sf-overlay {
      display: none;
    }

    &__chevron {
      right: px2rem(17);
    }

    &__options {
      padding: var(--spacer-10) 0 var(--spacer-10) 0;
      scrollbar-width: thin;
      scrollbar-color: var(--black);

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(235, 103, 71, 0.3);

        &:hover {
          background-color: var(--orange);
        }
      }
    }

    &-option {
      font-size: var(--font-size-14);
      color: var(--black);
      --select-selected-padding: 1rem var(--spacer-base) 0 var(--spacer-sm);
      padding: var(--spacer-20) 0 0 var(--spacer-20);
      border-radius: var(--spacer-10);

      &:last-child {
        padding-bottom: var(--spacer-20);
      }

      &--is-active {
        --select-option-background: none;
        color: var(--orange);
      }
    }

    &__label {
      font-size: var(--font-size-12);
      left: var(--spacer-20);
    }

    &--is-active {
      --chevron-color: var(--black);
    }
  }
}
</style>
