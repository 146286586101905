<template>
  <div id="advertisers">
    <div class="center">
      <header class="sf-heading title">
        <h2 class="sf-heading__title sf-heading__title--h2">
          {{ $t('For advertisers') }}
        </h2>
      </header>
    </div>
    <SfOAccordion
      :multiple="true"
      :open="[]"
      transition=""
      :show-chevron="false"
      :first-open="false"
      class="accordion"
      id="sf-accordion"
    >
      <SfOAccordionItem
        v-for="faq in advertisersAccordion"
        :key="faq.id"
        :header="$t(faq.title)"
        :images="faq.image"
      >
        <div class="accordion-item-text" v-html="faq.text" />
      </SfOAccordionItem>
    </SfOAccordion>
    <AStatic :content="content" ref="advertisers" />
  </div>
</template>

<script>
import MShopsPhotoGallery from 'theme/components/molecules/m-shops-photo-galery';
import AStatic from 'theme/components/atoms/a-static';
import Vue from 'vue';
import SfOAccordion from 'theme/components/storefront-override/SfOAccordion/SfOAccordion';
export default {
  name: 'Advertisers',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    },
    images: {
      type: Array,
      default: null
    }
  },
  components: {
    AStatic,
    SfOAccordion
  },
  data () {
    return {
      photoList: [],
      isOpen: false,
      advertisersAccordion: [],
      personalData: []
    }
  },
  methods: {
    mapAccordion () {
      const accordion = this.$refs.advertisers.$el.querySelector('#accordion');
      if (!accordion) {
        return '';
      }
      let accordionItems = accordion.querySelectorAll('.accordion-item');
      accordionItems.forEach((el, i) => {
        const accordionItem = {
          id: i,
          title: el.querySelector('.title')?.textContent || '',
          image: el.querySelector('.image')?.getAttribute('src') || '',
          text: el.querySelector('.accordion-item-text')?.innerHTML || ''
        };
        this.advertisersAccordion.push(accordionItem);
      })
    },
    getGalleryImages () {
      const images = this.$refs.advertisers.$el.querySelector('#imagelist');
      if (!images) {
        return '';
      }
      let imageItems = images.querySelectorAll('.image-item');
      imageItems.forEach((el, i) => {
        const image = {
          id: i,
          src: el.getElementsByTagName('img')[0]?.getAttribute('src') || '',
          name: el.getElementsByTagName('img')[0]?.getAttribute('alt') || this.$t('Image-' + i)
        }
        this.photoList.push(image);
      })
    },

    /**
     * Mapping the content value from Magento admin panel (CMS Page) on VS component - MShopsPhotoGallery
     */
    mapShopsPhotoGallery () {
      const photoGallery = this.$refs.advertisers.$el.querySelector('#shop-photogallery');
      if (!photoGallery) {
        return '';
      }

      const Component = Vue.extend(MShopsPhotoGallery);
      const componentInstance = new Component({
        i18n: this.$i18n,
        store: this.$store,
        propsData: {
          images: this.photoList
        }
      });
      componentInstance.$mount(photoGallery);
    }
  },

  mounted: function () {
    this.getGalleryImages();
    this.mapShopsPhotoGallery();
    this.mapAccordion();
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import '~theme/css/fonts';
@import "~theme/css/mixins";

#advertisers {
  font-family: var(--font-family-inter);

  ::v-deep {
    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }

      #accordion {
        display: none;
      }
    }

    .center {
      max-width: px2rem(895);
      margin: 0 auto;

      @include for-desktop {
        margin-top: var(--spacer-48);
        padding: 0;
      }
    }

    .title {
      padding-bottom: 0;

      & > h2 {
        @include header-title;

        @include for-desktop {
          margin-bottom: var(--spacer-30);
          margin-left: var(--spacer-50);
        }
      }
    }

    .section-title {
      margin: var(--spacer-30) 0 var(--spacer-15);
      padding-bottom: 0;

      & > h2 {
        color: var(--black);
        font-size: var(--font-size-20);
        font-weight: 500;
        line-height: var(--spacer-24);
        text-align: center;
      }

      @include for-desktop {
        font-weight: 500;
        margin: var(--spacer-60) 0 var(--spacer-25);
        padding-bottom: 0;
        text-align: left;

        & > h2 {
          font-size: var(--font-size-24);
        }
      }
    }

    .photogallery {
      margin: var(--spacer-50) 0 var(--spacer-20);

      @include for-desktop {
        margin: var(--spacer-68) 0 var(--spacer-25);
      }
    }

    .main-information {
      p {
        font-size: var(--font-size-14);
        font-weight: 400;
        line-height: var(--spacer-24);
        margin: 0 0 var(--spacer-10);

        &:first-child {
          margin-top: var(--spacer-15);
        }
      }
    }

    .accordion {
      max-width: px2rem(895);
      width: 100%;
      margin: 0 auto;
      padding: 0 var(--spacer-10);
      box-sizing: border-box;
    }

    .sf-accordion-item {
      box-sizing: border-box;
      margin: var(--spacer-10) 0;
      position: relative;
      font-family: var(--font-family-inter);

      @media (min-width: $tablet-min) {
        margin: var(--spacer-12) 0;
      }

      .sf-accordion-item__header {
        background: var(--light-gray);
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);
        justify-content: flex-start;
        padding: 0;
        text-align: left;
        padding-right: var(--spacer-40);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
        }

        @include font-icon(var(--icon-shape));

        &:before {
          position: absolute;
          right: 0;
          font-size: var(--font-size-12);
          margin: var(--spacer-15);
          height: var(--spacer-15);
          transition: transform 0.2s ease;
          width: var(--spacer-15);

          @media (min-width: $tablet-min) {
            margin-right: var(--spacer-36);
          }
        }

        &.sf-accordion-item__header--open {

          &:before {
            transform: rotate(180deg);
            transition: transform 0.2s ease;
          }
        }

        &.sf-accordion-item__header--has-images {
          span {
            margin-left: var(--spacer-15);
            line-height: var(--spacer-18);

            @media (min-width: $tablet-min) {
              width: 50%;
              margin-left: var(--spacer-50);
              line-height: var(--spacer-25);
            }
          }

          img {
            width: px2rem(135);
            @media (min-width: $tablet-min) {
              width: auto;
            }
          }
        }
      }

      .sf-accordion-item__content {
        padding: var(--spacer-20) 0;
        border: none;

        @media (min-width: $tablet-min) {
          padding: var(--spacer-60) var(--spacer-50);
        }

        .accordion-item-text {
          font-family: var(--font-family-inter);
          color: var(--black);
          font-weight: 400;
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-16);
            line-height: var(--spacer-28);
          }

          p {
            margin: 0;
          }

          ul {
            li {
              &::marker {
                color: var(--orange);
              }
            }
          }
        }
      }
    }

    .marketing-services {
      margin-top: var(--spacer-45);
      padding: var(--spacer-30) 0;
      background: var(--light-gray);

      @include for-desktop {
        margin-top: var(--spacer-75);
        padding: var(--spacer-50);
      }

      &__title {
        margin: 0;
        padding: 0;
        font-size: var(--font-size-20);
        color: var(--black);
        font-weight: 500;
        text-align: center;

        @include for-desktop {
          font-size: var(--font-size-24);
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: var(--spacer-15);

        @include for-desktop {
          flex-direction: row;
          margin-top: var(--spacer-30);
        }

        &__name {
          margin: 0 0 var(--spacer-13) 0;
          font-size: var(--font-size-14);
          color: var(--black);
          font-weight: 500;

          @include for-desktop {
            margin: 0;
            font-size: var(--font-size-18);
          }
        }

        &__phone {
          font-size: var(--font-size-15);
          color: var(--black);
          font-weight: 400;
          margin-bottom: var(--spacer-13);

          @include for-desktop {
            margin-bottom: 0;
          }
        }

        &__email {
          font-size: var(--font-size-14);
          color: var(--orange);
          font-weight: 400;
        }
      }
    }

    .presentation {
      display: flex;
      align-items: center;
      background: url("/assets/press-kit/presentation.png") no-repeat center / cover;
      margin-top: var(--spacer-50);
      min-height: 150px;

      &:hover {
        transition: .2s ease-in-out;
        opacity: 0.7;
      }

      .view-presentation {
        color: var(--white);
        font-weight: 500;
        font-size: var(--font-size-20);
        line-height: var(--spacer-24);
        margin: 14.5% 0 15% var(--spacer-30);
        max-width: 132px;

        @include for-desktop {
          font-size: var(--font-size-32);
          line-height: var(--spacer-33);
          margin-left: var(--spacer-65);
        }
      }
    }

    //Logo block
    .logos {
      .section-title {
        display: flex;
        justify-content: center;
        @include for-desktop {
          justify-content: flex-start;
        }
      }
    }
    .list-logos {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      margin: var(--spacer-5) var(--spacer-5) var(--spacer-20);
      padding-left: 0;

      @include for-desktop {
        margin-bottom: var(--spacer-30);
      }

      .item-logo {
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;
        margin: var(--spacer-5) -5px;
        width: 50%;
      }

      img {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .logo-2 {
        padding: var(--spacer-30);

        @include for-desktop {
          padding: var(--spacer-57) 77px;
        }
      }
      .logo-3 {
        padding: var(--spacer-24) var(--spacer-30);

        @include for-desktop {
          padding: var(--spacer-48) 79px;
        }
      }
      .logo-4 {
        padding: var(--spacer-15) var(--spacer-50);

        @include for-desktop {
          padding: var(--spacer-27) 122px;
        }
      }
    }

    .view-logos {
      color: var(--white);
      background-color: var(--orange);
      display: block;
      font-size: var(--font-size-14);
      font-weight: 500;
      line-height: var(--spacer-24);
      max-width: 355px;
      margin: 0 auto;
      padding: var(--spacer-8) 0;
      text-align: center;
      width: 100%;

      &:hover {
        background-color: var(--orange-hover);
      }

      @include for-desktop {
        max-width: 230px;
      }
    }

    #imagelist {
      display: none;
    }

    .photo-gallery-carousel {
      margin-bottom: var(--spacer-45);
    }
  }
}
</style>
