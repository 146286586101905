<template>
  <div>
    <div class="rent">
      <m-rent-banner
        :banner="bannerHtml"
      />
      <m-rent-tabs
        :tab-first="tabFirst"
        :tab-second="tabSecond"
      />
    </div>
    <o-rent-submit-form :form="formHtml" />
  </div>
</template>
<script>
import MRentTabs from 'theme/components/molecules/m-rent-tabs.vue'
import MRentBanner from 'theme/components/molecules/m-rent-banner-area.vue'
import ORentSubmitForm from 'theme/components/organisms/o-rent-submit-form.vue'

export default {
  name: 'Rent',
  components: {
    MRentTabs,
    MRentBanner,
    ORentSubmitForm
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  data () {
    return {
      tabFirst: '',
      tabSecond: '',
      bannerHtml: '',
      formHtml: ''
    }
  },
  mounted () {
    if (this.isContentAvailable()) {
      const domElement = this.createElementFromHTML(this.content.message)

      this.tabFirst = domElement.querySelector('#rent-tab-first')?.outerHTML
      this.tabSecond = domElement.querySelector('#rent-tab-second')?.outerHTML
      this.bannerHtml = domElement.querySelector('.rent__banner')?.outerHTML
      this.formHtml = domElement.querySelector('.custom__form')?.outerHTML
    }
  },
  methods: {
    createElementFromHTML (htmlString) {
      const div = document.createElement('div')
      div.innerHTML = htmlString.trim()

      return div.firstChild
    },
    isContentAvailable () {
      return Array.isArray(this.content)
        ? this.content.length
        : this.content.title && this.content.message;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "$ui/styles/root";

.rent {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 10px;

  ::v-deep .sf-tabs {
    --tabs-title-font-size: var(--font-size-16);
    --tabs-title-font-weight: var(--font-medium);
    --tabs-content-font-family: var(--font-family-inter);

    &__title--active {
      --tabs-title-border-color: #EB6747;
      --tabs-title-border-width: 0 0 3px 0;
    }

    &__content__tab {
      padding-bottom: var(--spacer-sm);

      @media (min-width: ($tablet-min)) {
        padding-bottom: 0;
      }
    }

    &__title--active + .sf-tabs__content {
      margin-top: 0.5px;
      border: unset;
      border-top: var(--tabs-content-border, var(--tabs-content-border-style, solid) var(--tabs-content-border-color, var(--c-light)));
      border-width: var(--tabs-content-border-width, 1px);
    }

    @media (min-width: ($tablet-min)) {
      --tabs-title-font-size: var(--font-size-32);
      --tabs-title-font-weight: var(--font-medium);
    }

    button:not(.sf-tabs__title--active) {
      border-color: transparent;
      color: var(--orange);
    }

    button {
      color: var(--black);
    }
  }

  .rent__title {
    font-size: var(--font-size-20);

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-32);
    }
  }

  .rent__subtitle {
    font-size: var(--font-size-13);
    color: var(--dark-gray);

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-16);
    }
  }
}
</style>
