<template>
  <div
    class="gift-cards"
    id="gift-cards"
  >
    <AStatic
      :content="content"
      ref="giftCards"
    />
    <div
      class="cards"
      ref="cardsList"
    >
      <div
        v-for="product in getGiftCards"
        :key="product.id"
        class="cards-item"
      >
        <SfLink :link="localizedRoute(`/${product.url_path}`)">
          <slot name="image">
            <SfImage
              class="cards-item__image"
              :src="getImage(product.sku)"
              :alt="product.name"
            />
          </slot>
        </SfLink>
        <SfLink
          :link="localizedRoute(`/${product.url_path}`)"
          class="cards-item__title"
        >
          {{ product.name }}
        </SfLink>
        <NoSSR>
          <MProductAddToCart
            v-if="isStockState"
            :product="product"
            :stock="product.stock"
            @input="changeQuantity(product, $event)"
          />
        </NoSSR>
      </div>
    </div>
    <NoSSR>
      <MCertificateCheckForm
        id="certificate-check-component"
        class="certificate-check-component"
        @componentMounted="mountCertificateCheckForm"
      />
    </NoSSR>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SfImage, SfLink } from '@storefront-ui/vue';
import AStatic from 'theme/components/atoms/a-static';
import NoSSR from 'vue-no-ssr'
import { getProductImagePath } from 'theme/helpers';

export default {
  name: 'GiftCards',
  data () {
    return {
      productInCartChangedQty: null
    }
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic,
    MProductAddToCart: () => process.browser ? import('theme/components/molecules/m-product-add-to-cart') : null,
    MCertificateCheckForm: () => process.browser ? import('theme/components/molecules/m-certificate-check-form') : null,
    NoSSR,
    SfImage,
    SfLink
  },
  computed: {
    ...mapGetters({
      getGiftCards: 'giftCard/getGiftCards'
    })
  },
  created () {
    this.$store.dispatch('giftCard/fetchGiftCardsCollection');
  },
  mounted () {
    this.moveBlock();
  },
  methods: {
    ...mapActions({
      updateQuantity: 'cart/updateQuantity'
    }),
    getImage (sku) {
      return getProductImagePath(sku, 800, 800);
    },
    moveBlock () {
      const content = this.$refs.giftCards.$el.querySelector('.center .content');
      const cardsList = this.$refs.cardsList;
      content.classList.add('content-wrapper')
      return cardsList.insertAdjacentElement('afterend', content);
    },
    mountCertificateCheckForm () {
      const formElement = document.querySelector('#certificate-check-component')
      const targetElement = document.querySelector('#certificate-check-form')

      if (!formElement || !targetElement) return

      targetElement.insertAdjacentElement('afterbegin', formElement);
      formElement.setAttribute('style', 'visibility: visible')
    },
    isStockState (product) {
      return product?.stock.is_in_stock;
    },
    async changeQuantity (product, newQuantity) {
      const diffLog = await this.updateQuantity({
        product,
        qty: newQuantity
      })

      diffLog.clientNotifications.forEach(notificationData => {
        this.$store.dispatch(
          'notification/spawnNotification',
          notificationData,
          { root: true }
        )
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.certificate-check-component {
  visibility: hidden;
  margin-top: var(--spacer-60);
}

.gift-cards {
  ::v-deep {
    .content {
      padding: 0;

      & > .sf-heading {
        display: none;
      }

      .center {
        .sf-heading {
          max-width: px2rem(800);
          margin: var(--spacer-30) auto 0;

          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-50);
          }

          &__title {
            text-align: left;
            font-size: var(--font-size-20);
            font-weight: var(--font-medium);
            color: var(--black);

            @media (min-width: $tablet-min) {
              font-size: var(--font-size-32);
            }
          }
        }
      }
    }

    .banner {
      color: var(--white);
      min-height: px2rem(150);
      overflow: hidden;
      margin-bottom: var(--spacer-30);

      @media (min-width: $tablet-min) {
        max-width: px2rem(990);
        margin: 0 auto;
        max-height: px2rem(250);
      }

      .images-wrapper--mobile {
        @media (min-width: $tablet-min) {
          display: none;
        }
      }

      .images-wrapper--desktop {
        @media (max-width: $mobile-max) {
          display: none;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .content-wrapper {
      max-width: px2rem(820);
      margin: 0 auto var(--spacer-50);
      padding: 0 var(--spacer-10);

      h2 {
        margin: var(--spacer-50) 0 0 0;
        font-size: var(--font-size-16);
        font-weight: var(--font-medium);
        color: var(--black);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
        }
      }

      ol {
        margin-top: var(--spacer-10);
        padding: 0 0 0 var(--spacer-18);

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-25);
        }

        li {
          font-size: var(--font-size-14);
          line-height: var(--spacer-24);
        }
      }

      p {
        margin: var(--spacer-10) 0 0 0;
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);

        @media (min-width: $tablet-min) {
          margin: var(--spacer-20) 0 0 0;
        }

        a {
          color: var(--orange);
        }
      }
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    margin: var(--spacer-30) auto 0;
    max-width: px2rem(990);

    @include only-mobile {
      margin-top: var(--spacer-15);
      padding: 0 var(--spacer-10);
    }

    &-item {
      width: 50%;
      padding: var(--spacer-30);
      border: 1px solid var(--line-color);
      box-sizing: border-box;

      @include only-mobile {
        width: 100%;
        padding: var(--spacer-20);
      }

      &__image {
        width: 100%;
        border-radius: var(--spacer-20);
        background: var(--light-gray);

        ::v-deep img {
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        display: block;
        margin: var(--spacer-30) 0 var(--spacer-20);
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        color: var(--black);

        @include only-mobile {
          margin: var(--spacer-20) 0 var(--spacer-20);
          font-size: var(--font-size-16);
        }
      }
    }
  }

  ::v-deep {
    .a-add-to-cart {
      @include only-mobile {
        width: 100%;
      }
    }
  }
}
</style>
