<template>
  <div>
    <div
      v-if="!isLoading"
      :class="classList"
    >
      <SfLink
        v-for="(product) in products"
        :key="product.id"
        class="new-product-list__item"
        :link="localizedRoute(product.url_path)"
      >
        <div class="new-product-list__item-image">
          <SfImage
            :src="imagePath(product.newimage)"
            :alt="product.name"
            width="390"
            height="220"
          />
        </div>
        <div class="new-product-list__item-date">
          <span>{{ product.news_from_date ? parseDateFrom(product.news_from_date) : '' }}</span>
        </div>
        <div class="new-product-list__item-name">
          <span>{{ getTitle(product) }}</span>
        </div>
        <div class="new-product-list__item-description">
          <span v-html="truncateDescription(getDescription(product), '...')" />
        </div>
      </SfLink>
    </div>
    <div
      v-if="isLoading && isPlaceholderEnabled"
      :class="classList"
    >
      <AProductPlaceholderHorizontal
        v-for="index in placeholderCount"
        :key="placeholderCount + '_' + index"
        class="m-category-list__item placeholder-item-svg"
      />
    </div>
  </div>
</template>

<script>
import { SfLink, SfImage } from '@storefront-ui/vue';
import { getThumbnailPath } from '@vue-storefront/core/helpers';
import AProductPlaceholderHorizontal from 'theme/components/atoms/a-product-placeholder-horizontal';

const NEWS_TEXT_LENGTH = 110;

export default {
  name: 'MNewProductList',
  components: {
    SfLink,
    SfImage,
    AProductPlaceholderHorizontal
  },
  props: {
    classList: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    placeholderCount: {
      type: Number,
      default: 40
    },
    isPlaceholderEnabled: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    parseDateFrom (date) {
      const newDate = new Date(date.replace(' ', 'T'));
      const day = newDate.getDate();
      const month = newDate.toLocaleString('en', { month: 'long' });
      const year = newDate.getFullYear();

      return this.$t(`{day} ${month} {year}`, { day: `${day}`, year: `${year}` })
    },
    truncateDescription (text, suffix) {
      return text?.length > NEWS_TEXT_LENGTH ? text?.substring(0, NEWS_TEXT_LENGTH) + suffix : text;
    },
    imagePath (path) {
      if (path) {
        return getThumbnailPath(`/newimage${path}`)
      }
      return getThumbnailPath(path)
    },
    getTitle (product) {
      const title = product?.new_product_title;

      return title !== undefined && title !== null && title !== '' ? title : product.name
    },
    getDescription (product) {
      const description = product?.new_product_description;

      return description !== undefined && description !== null && description !== '' ? description : product.description
    }
  }
}
</script>

<style lang="scss">
@import "~theme/css/breakpoints";

.new-product-list {
  display: grid;
  grid-gap: var(--spacer-10);
  grid-template-columns: auto;

  @media (min-width: $tablet-min) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $desktop-min) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item {
    margin-bottom: var(--spacer-40);

    &-image {
      margin-bottom: var(--spacer-20);

      .sf-image {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      @media (min-width: $desktop-min) {
        margin-bottom: var(--spacer-15);
      }
    }

    &-date {
      margin-bottom: var(--spacer-6);

      span {
        font-weight: 400;
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);
        color: var(--dark-gray);
      }
    }

    &-name {
      margin-bottom:  var(--spacer-15);

      span {
        font-weight: 500;
        font-size: var(--font-size-20);
        line-height: var(--font-size-24);
        color: var(--black);

        @media (min-width: $desktop-min) {
          font-size: var(--font-size-24);
          line-height: var(--font-size-25);
        }
      }
    }

    &-description {
      span {
        font-weight: 400;
        font-size: var(--font-size-14);
        line-height: var(--font-size-24);
        color: var(--dark-gray);
      }
    }
  }
}
</style>
