<template>
  <div class="sf-accordion-item">
    <!-- @slot -->
    <slot
      name="header"
      v-bind="{
        header,
        isOpen,
        accordionClick,
        showChevron: $parent.showChevron,
      }"
    >
      <SfButton
        :aria-pressed="isOpen.toString()"
        :aria-expanded="isOpen.toString()"
        :class="[{ 'sf-accordion-item__header--open': isOpen, 'sf-accordion-item__header--has-images': images !=='' }]"
        class="sf-button--pure sf-accordion-item__header"
        data-transaction-name="Accordion"
        @click="accordionClick"
      >
        <img
          class="sf-accordion-images"
          v-if="images !==''"
          :src="images"
          :alt="header"
        >
        <span class="sf-accordion-item__title">{{ header }}</span>
        <SfChevron
          tabindex="0"
          class="sf-accordion-item__chevron"
          :class="{ 'sf-chevron--right': !isOpen }"
        />
      </SfButton>
    </slot>
    <transition :name="$parent.transition">
      <div v-if="isOpen" class="sf-accordion-item__content">
        <!-- @slot -->
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import { focus } from '@storefront-ui/vue/src/utilities/directives';
import { SfChevron, SfButton } from '@storefront-ui/vue';
export default {
  name: 'SfAccordionItem',
  directives: { focus },
  components: {
    SfChevron,
    SfButton
  },
  props: {
    header: {
      type: String,
      default: ''
    },
    images: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  methods: {
    accordionClick () {
      this.$parent.$emit('toggle', this._uid);
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfAccordion.scss";
</style>
