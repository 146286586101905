<template>
  <div
    class="tenders"
    id="tenders"
    ref="tenders"
  >
    <AStatic :content="content" />
    <SfOTabs
      v-show="tendersCollectionLoaded"
      :open-tab="1"
      :custom-click="true"
      @add-click="toogleTab"
    >
      <SfTab :title="computedActualTitle">
        <template>
          <div v-if="activeListLength">
            <div
              class="sf-tab__item"
              v-for="tab in getTendersActiveList.items"
              :key="tab.id"
              :header="$t(tab.name)"
            >
              <div class="sf-tab__item-content">
                <div class="sf-tab__item-info">
                  <header class="sf-heading title sf-tab__item-info--title">
                    <h3 class="sf-heading__title sf-heading__title--h3">
                      {{ tab.name }}
                    </h3>
                  </header>
                  <p class="sf-tab__item-info--date">
                    {{ $t('Deadline for applications') }}: {{ formatDate(tab.tender_deadline) }}
                  </p>
                  <router-link
                    :to="'tenders/'+tab.id"
                    class="sf-tab__item-info--button button-details"
                  >
                    <span>{{ $t('Show more') }}</span>
                  </router-link>
                </div>
                <div class="sf-tab__item-contact">
                  <p class="sf-tab__item-contact--icons sf-tab__item-contact--name">
                    {{ $t('Responsible') }}: {{ tab.responsible_name }}
                  </p>
                  <a
                    :href="'tel:'+ tab.phone"
                    class="sf-tab__item-contact--icons sf-tab__item-contact--phone"
                  >
                    {{ tab.phone }}
                  </a>
                  <a
                    href="mailto: tender@terwingroup.com"
                    class="sf-tab__item-contact--icons sf-tab__item-contact--email"
                  >
                    {{ tab.email }}
                  </a>
                  <router-link
                    :to="'tenders/'+tab.id"
                    class="sf-tab__item-contact--button button-details"
                  >
                    <span>{{ $t('Show more') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="sf-tab__item--empty">
            <span>{{ $t('There are currently no active tenders') }}</span>
          </div>
        </template>
        <div
          class="bottom-plp-block"
          v-if="totalPagesActive > 1"
        >
          <SfOPagination
            class="products__pagination"
            :current="currentPage"
            :scroll-top="true"
            :total="totalPagesActive"
            :count-range-pages="countRangePages"
            @loadMoreFromPagEmit="loadMoreFromPagEmitFunction"
            @countRangePagesEmit="countRangePagesEmitFunction"
          />
        </div>
      </SfTab>

      <SfTab :title="computedFinishedTitle">
        <template>
          <div v-if="disActiveListLength">
            <div
              class="sf-tab__item"
              v-for="tab in getTendersDisActiveList.items"
              :key="tab.id"
              :header="$t(tab.name)"
            >
              <div class="sf-tab__item-content">
                <div class="sf-tab__item-info">
                  <header class="sf-heading title sf-tab__item-info--title">
                    <h3 class="sf-heading__title sf-heading__title--h3 title__dis-active">
                      {{ tab.name }}
                    </h3>
                  </header>
                  <div class="sf-tab__item-info--status-date">
                    <span class="sf-tab__item-info--status">
                      {{ $t('Tender completed') }}
                    </span>
                    <span class="sf-tab__item-info--date">
                      {{ $t('Deadline for applications') }}: {{ formatDate(tab.tender_deadline) }}
                    </span>
                  </div>
                  <router-link
                    :to="'tenders/'+tab.id"
                    class="sf-tab__item-info--button button-details"
                  >
                    <span>{{ $t('Show more') }}</span>
                  </router-link>
                </div>
                <div class="sf-tab__item-contact">
                  <p class="sf-tab__item-contact--icons sf-tab__item-contact--name">
                    {{ $t('Responsible') }}: {{ tab.responsible_name }}
                  </p>
                  <a
                    :href="'tel:'+ tab.phone"
                    class="sf-tab__item-contact--icons sf-tab__item-contact--phone"
                  >
                    {{ tab.phone }}
                  </a>
                  <a
                    href="mailto: tender@terwingroup.com"
                    class="sf-tab__item-contact--icons sf-tab__item-contact--email"
                  >
                    {{ tab.email }}
                  </a>
                  <router-link
                    :to="'tenders/'+tab.id"
                    class="sf-tab__item-contact--button button-details"
                  >
                    <span>{{ $t('Show more') }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="sf-tab__item--empty">
            <span>{{ $t('There are currently no active tenders') }}</span>
          </div>
        </template>
        <div
          class="bottom-plp-block"
          v-if="totalPagesDisActive > 1"
        >
          <SfOPagination
            class="products__pagination"
            :current="currentPage"
            :scroll-top="true"
            :total="totalPagesDisActive"
            :count-range-pages="countRangePages"
            @loadMoreFromPagEmit="loadMoreFromPagEmitFunction"
            @countRangePagesEmit="countRangePagesEmitFunction"
          />
        </div>
      </SfTab>
    </SfOTabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AStatic from 'theme/components/atoms/a-static';
import SfOTabs from 'src/themes/varus/components/storefront-override/SfOTabs';
import SfOPagination from 'theme/components/storefront-override/SfOPagination';
import customFormSpinnerLoading from 'theme/mixins/CustomFormSpinnerLoading'

const THEME_PAGE_SIZE = 10;

export default {
  name: 'Tenders',
  mixins: [customFormSpinnerLoading],
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },

  components: {
    AStatic,
    SfOTabs,
    SfOPagination
  },
  data () {
    return {
      banner: {},
      tabFirst: '',
      tabSecond: '',
      actualTabs: [],
      finishedTabs: [],
      countRangePages: [],
      currentPage: 1,
      tendersCollectionLoaded: false
    }
  },
  async created () {
    this.tendersCollectionLoaded = false
    await this.$store.dispatch('tenders/fetchTendersCollection', { route: this.$route })
    this.tendersCollectionLoaded = true
  },
  mounted () {
    this.moveFormBlock();
  },
  methods: {
    toogleTab () {
      this.$store.dispatch('category-extension/changeRouterFilterParameters', {});
    },
    getBannerContent () {
      const bannerSection = this.$refs.tenders.$el.querySelector('#banner');
      const title = bannerSection.querySelector('.banner-text')?.textContent;
      const buttonText = bannerSection.querySelector('.banner-button')?.textContent;
      const image = bannerSection.querySelector('.images-wrapper img')?.outerHTML;

      this.banner = {
        title,
        buttonText,
        image
      }
    },
    /**
     * Moved the custom form block after all elements in DOM structure
     */
    moveFormBlock () {
      this.$refs.tenders?.querySelector('#custom__form').classList.add('custom__form-tender');
      const customFormBlock = this.$refs.tenders?.querySelector('.custom__form');
      const tenderBlock = document.getElementById('tenders');

      return customFormBlock ? tenderBlock.insertAdjacentElement('afterend', customFormBlock) : '';
    },
    formatDate (date) {
      const dateParts = date.split('-')
      let day = dateParts[2].split(' ')[0]
      let month = dateParts[1]
      let year = dateParts[0]
      return `${day}.${month}.${year}`
    },
    async loadMoreFromPagEmitFunction () {
      this.countRangePages.push(this.currentPage);
      this.currentPage += 1;
      this.countRangePages.push(this.currentPage);
      await this.$router.push({ query: { ...this.$route.query, page: this.currentPage }, params: { loadMore: true } });
    },
    countRangePagesEmitFunction () {
      this.countRangePages = [];
    }
  },
  destroyed () {
    // remove tender form from other pages
    document.querySelector('.custom__form-tender')?.remove();
  },
  computed: {
    ...mapGetters({
      getTendersList: 'tenders/getTendersList',
      getTendersActiveList: 'tenders/getTendersActiveList',
      getTendersDisActiveList: 'tenders/getTendersDisActiveList'
    }),
    totalPagesActive () {
      if (this.getTendersActiveList.hasOwnProperty('total')) {
        return Math.ceil(this.getTendersActiveList.total.value / THEME_PAGE_SIZE);
      }
      return 1
    },
    totalPagesDisActive () {
      if (this.getTendersDisActiveList.hasOwnProperty('total')) {
        return Math.ceil(this.getTendersDisActiveList.total.value / THEME_PAGE_SIZE);
      }
      return 1
    },
    computedActualTitle () {
      if (this.getTendersActiveList.hasOwnProperty('total')) {
        const actualTabLength = this.getTendersActiveList.total.value;

        return this.$t('Actual ({count})', { count: `${actualTabLength}` })
      } else {
        return this.$t('Actual')
      }
    },
    computedFinishedTitle () {
      if (this.getTendersActiveList.hasOwnProperty('total')) {
        const finishedTabLength = this.getTendersDisActiveList.total.value;

        return this.$t('Finished ({count})', { count: `${finishedTabLength}` })
      } else {
        return this.$t('Finished')
      }
    },
    isNotActiveTenders () {
      return []
    },
    activeListLength () {
      return this.getTendersActiveList?.items?.length || 0
    },
    disActiveListLength () {
      return this.getTendersDisActiveList?.items?.length || 0
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.$store.dispatch('tenders/fetchTendersCollection', { route: this.$route })
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.static {
  #tenders + .custom__form-tender {
    overflow: hidden;
  }

  #tenders + .custom__form-tender,
  .custom-form__iframe {
    min-height: inherit;
    height: px2rem(770);

    @include for-desktop {
      min-height: inherit;
      height: px2rem(585);
    }

    @include for-tablet {
      min-height: inherit;
      height: px2rem(690);
    }
  }
}
</style>
<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";
  @import "~theme/css/breakpoints";
  @import "~theme/css/px2rem";
  @import '~theme/css/fonts';
  @import "~theme/css/mixins";

  .tenders {
    ::v-deep {
      .tender-tabs-actual,
      .tender-tabs-finished {
        display: none;
      }

      .orange-text {
        color: var(--orange);
      }

      .container {
        margin: 0 var(--spacer-10);

        @media (min-width: $tablet-min) {
          max-width: px2rem(990);
          margin: 0 auto;
          min-height: px2rem(250);
        }
      }

      .content {
        padding: 0;

        & > .sf-heading {
          display: none;
        }
      }

      // Banner
      .banner {
        color: var(--white);
        background: linear-gradient(94.39deg, #FF8A2F 12.72%, #DE5B27 90.6%);
        display: flex;
        min-height: px2rem(150);
        overflow: hidden;
        position: relative;
        margin-bottom: var(--spacer-30);

        @media (min-width: $tablet-min) {
          max-width: px2rem(990);
          margin: 0 auto;
          max-height: px2rem(250);
        }

        &-text {
          font-weight: 500;
          margin-top: 0;
          font-size: var(--font-size-16);
          line-height: var(--spacer-18);
          max-width: px2rem(250);

          @media (min-width: $tablet-min) {
            max-width: px2rem(370);
            font-weight: 500;
            font-size: var(--font-size-32);
            line-height: var(--spacer-33);
            color: var(--white);
          }
        }

        &-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--white);
          margin: 0;
          max-height: px2rem(40);
          outline: none;
          border: none;
          cursor: pointer;
          padding: var(--spacer-8) var(--spacer-16);

          a {
            font-style: normal;
            font-weight: 500;
            text-align: center;
            color: var(--black);
            font-size: var(--font-size-18);
            line-height: var(--spacer-24);
          }

          @media (min-width: $tablet-min) {
            min-width: px2rem(316);
            height: px2rem(56);

            a {
              font-size: var(--font-size-18);
              line-height: var(--spacer-24);
            }
          }
        }
      }

      .description {
        margin: var(--spacer-25) var(--spacer-25) var(--spacer-32);
        z-index: 1;

        @media (min-width: $tablet-min) {
          margin: 64px 0 69px 77px;
        }
      }

      .images-wrapper {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        height: 100%;
        top: 0;
        right: var(--spacer-80);

        img {
          filter: drop-shadow(0 0 70px var(--white));
        }

        @media (max-width: $mobile-max) {
          right: -50px;
        }

        @include for-tablet  {
          right: var(--spacer-20);
        }

        .banner-img {
          & > img {
            height: 100%;
            position: relative;
          }
        }
      }

      .center {
        padding: 0 var(--spacer-13);
        max-width: px2rem(790);
        margin: 0 auto;

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-50);
          padding: 0;
        }
      }

      .title {
        padding-bottom: 0;

        &.title-inactive {
          & > h3 {
            color: var(--dark-gray);
          }
        }

        & > h2 {
          @include header-title;
        }

        & > h3 {
          text-align: left;
          font-weight: 500;
          font-size: var(--font-size-20);
          line-height: var(--spacer-19);
          color: var(--black);

          @media (min-width: $tablet-min) {
            font-weight: 500;
            font-size: var(--font-size-24);
            line-height: var(--spacer-25);
          }
        }
      }

      .main-information {
        margin-bottom: var(--spacer-30);

        @media (min-width: $tablet-min) {
          margin-bottom: var(--spacer-50);
        }

        &__text {
          font-weight: 400;
          font-size: var(--font-size-16);
          line-height: var(--spacer-26);
          color: var(--dark-gray);
          margin-top: var(--spacer-15);
          margin-bottom: var(--spacer-10);

          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-30);
            margin-bottom: var(--spacer-15);
            line-height: var(--spacer-28);
          }
        }

        &__subtext {
          font-size: var(--font-size-14);
          font-weight: 400;
          line-height: var(--spacer-24);
          margin: 0 0 var(--spacer-10);
        }
      }
    }

    .button-details {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spacer-8) var(--spacer-16);
      height: px2rem(40);
      background: var(--orange);
      outline: none;
      border: none;
      cursor: pointer;

      &:hover {
        background: var(--orange-pressed);
        color: var(--white);
      }

      span {
        font-weight: var(--font-medium);
        font-size: var(--font-size-14);
        line-height: var(--font-size-24);
        color: var(--white);
      }
    }

    .sf-tabs {
      max-width: 990px;
      margin: 0 auto;
      font-family: var(--font-family-inter);
      padding: 0 var(--spacer-10);
      // reset mobile styles for the SfTabs
      -tabs-title-z-index: 1;
      --tabs-content-order: 1;
      --tabs-title-flex: 0 0 auto;
      --tabs-title-margin: 0 var(--spacer-lg) -2px 0;
      --tabs-title-padding: var(--spacer-xs) 0;
      --tabs-title-color: var(--c-text-muted);
      --tabs-content-tab-padding: var(--spacer-base) 0;
      --tabs-chevron-display: none;
      --tabs-title-font-size: var(--font-size-16);
      --tabs-title-font-weight: var(--font-medium);

      @include for-desktop {
        padding: 0;
      }
    }

    ::v-deep {
      .sf-tabs__title {
        --tabs-title-color: var(--orange);
      }

      .sf-tabs__title--active {
        --tabs-title-border-width: 0 0 var(--spacer-2) 0;
        --tabs-title-border-color: var(--orange);
        --tabs-title-color: var(--black);
      }
    }

    .sf-tab__item {
      background: var(--light-gray);
      margin-bottom: var(--spacer-10);

      &--empty {
        span {
          font-weight: var(--font-medium);
          font-size: var(--font-size-24);
          line-height: var(--font-size-25);
          color: var(--dark-gray);
        }
      }

      @include for-desktop {
        margin-bottom: var(--spacer-20);
      }
    }

    .sf-tab__item-content {
      display: flex;
      flex-direction: column;

      @include for-desktop {
        flex-direction: row;
      }
    }

    .sf-tab__item-info {
      position: relative;
      padding: var(--spacer-30);

      @include for-desktop {
        padding: var(--spacer-50) var(--spacer-85) var(--spacer-50) var(--spacer-50);
        flex: 0 0 50%;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: px2rem(1);
        width: 100%;
        background: var(--line-gray);
        left: 0;

        @include for-desktop {
          height: 100%;
          width: px2rem(1);
          top: 0;
          right: 0;
          left: unset;
        }
      }

      &--title {
        font-weight: var(--font-medium);
        font-size: var(--font-size-16);
        line-height: var(--font-size-22);
        color: var(--black);
        margin-bottom: var(--spacer-15);

        @include for-desktop {
          font-size: var(--font-size-24);
          line-height: var(--font-size-25);
          margin-bottom: var(--spacer-20);
        }

        .title__dis-active {
          color: var(--dark-gray);
        }
      }

      &--status-date {
        margin: 0;

        @include for-desktop {
          margin: 0 0 var(--spacer-25);
        }
      }

      &--status {
        font-family: var(--font-family-secondary);
        font-weight: var(--font-normal);
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);
        color: var(--dark-gray);
        margin-right: var(--spacer-30);
      }

      &--date {
        font-weight: var(--font-normal);
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);
        color: var(--dark-gray);
        position: relative;
        padding-left: var(--spacer-20);

        @include font-icon(var(--icon-clock));

        &:before {
          position: absolute;
          left: 0;
          top: 40%;
          transform: translateY(-50%);
          color: var(--black);
          font-size: var(--font-size-13);
        }
      }

      &--button {
        display: none;

        @include for-desktop {
          display: flex;
          max-width: px2rem(110);
          height: px2rem(40);
          padding: 0;
        }
      }
    }

    .sf-tab__item-contact {
      padding: var(--spacer-30);

      @include for-desktop {
        padding: var(--spacer-50) var(--spacer-50) var(--spacer-50) var(--spacer-45);
      }

      &--icons {
        color: var(--black);
        position: relative;
        padding-left: var(--spacer-22);
        margin-top: 0;
        margin-bottom: var(--spacer-20);

        @include for-desktop {
          white-space: nowrap;
        }

        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          color: var(--black);
        }
      }

      &--name {
        font-weight: var(--font-normal);
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);

        @include font-icon(var(--icon-user));

        &:before {
          font-size: var(--font-size-16);
        }
      }

      &--phone {
        display: inline-block;
        font-weight: var(--font-medium);
        font-size: var(--font-size-15);
        line-height: var(--font-size-18);

        @include font-icon(var(--icon-phone));

        &:before {
          font-size: var(--font-size-12);
        }
      }

      &--email {
        font-weight: var(--font-normal);
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);
        color: var(--orange);
        margin-bottom: var(--spacer-30);
        display: flex;
        align-items: center;

        @include for-desktop {
          margin-bottom: 0;
        }

        &:hover {
          color: var(--orange-pressed);
        }

        @include font-icon(var(--icon-location));

        &:before {
          font-size: var(--font-size-13);
        }
      }

      &--button {
        display: flex;
        max-width: px2rem(110);
        height: px2rem(40);
        padding: 0 var(--spacer-10);

        @include for-desktop {
          display: none;
        }
      }
    }

    .bottom-plp-block {
      max-width: 990px;
      margin: 0 auto;
      background-color: var(--light-gray);
      padding-top: var(--spacer-40);
      padding-bottom: var(--spacer-5);
      position: relative;

      @media (min-width: $tablet-min) {
        width: 100%;
        padding-top: var(--spacer-5);
      }

      .sf-link--primary {
        font-size: var(--font-sm);
        padding: var(--spacer-20) 0 0;

        @media (min-width: $tablet-min) {
          padding-top: 0;
          padding-left: var(--spacer-30);
        }
      }

      .sf-pagination {
        padding-right: 0;

        @media (min-width: $tablet-min) {
          padding-bottom: var(--spacer-20);
          padding-top: var(--spacer-20);
          align-items: center;
        }

        ::v-deep {
          .router-link-active,
          .sf-pagination__item {
            color: var(--orange);
            transition: color 0.3s ease-in-out;

            &:hover {
              color: var(--orange-hover);
            }
          }
          .sf-pagination__item--current {
            color: var(--black);
          }
          .sf-pagination__item:not(.sf-pagination__item--current):not(.sf-link) {
            color: var(--black);
          }
          .sf-pagination__item {
            &--next--disable,
            &--prev--disable {
              &:after {
                border-color: var(--dark-gray);
              }
            }
          }
        }
      }
    }
  }
</style>
