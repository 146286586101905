<template>
  <div id="faq-page">
    <AStatic :content="content" ref="faqPage" />
    <SfAccordion :show-chevron="false">
      <SfAccordionItem
        v-for="question in questions"
        :key="question.id"
        :header="$t(question.title)"
      >
        <div class="answer" v-html="question.answer" />
      </SfAccordionItem>
    </SfAccordion>
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';
import { SfAccordion } from '@storefront-ui/vue';

export default {
  name: 'FaqPage',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic,
    SfAccordion
  },
  data () {
    return {
      questions: []
    }
  },
  methods: {
    /**
     * Mapping the content value from Magento admin panel (CMS Page) on VS component - SfAccordion
     */
    mapAccordion () {
      const accordion = this.$refs.faqPage.$el.querySelector('#accordion');

      if (!accordion) return '';

      this.questions = [];

      let accordionItems = accordion.querySelectorAll('.accordion-item');
      accordionItems.forEach((el, i) => {
        this.questions.push({
          id: i,
          title: el.querySelector('.title')?.textContent || '',
          answer: el.querySelector('.answer')?.innerHTML || ''
        });
      })
    }
  },
  mounted () {
    this.mapAccordion();
  },
  watch: {
    content: {
      deep: true,
      handler () {
        this.mapAccordion();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@storefront-ui/shared/styles/helpers/breakpoints';
@import '~theme/css/fonts';

#faq-page {
  // Accordions
  ::v-deep {
    .content {
      padding: 0;

      & > header {
        display: none;
      }
    }

    #accordion {
      display: none;
    }

    .title {
      font-size: var(--font-size-20);
      font-weight: 500;
      line-height: var(--spacer-24);
      margin: var(--spacer-13) auto var(--spacer-10);
      max-width: 790px;
      padding-left: var(--spacer-10);
      width: 100%;

      @include for-desktop {
        margin: var(--spacer-20) auto var(--spacer-30);
        font-size: var(--font-size-32);
        line-height: var(--spacer-32);
        padding-left: 0;
      }
    }

    .sf-accordion-item {
      box-sizing: border-box;
      margin: var(--spacer-10) auto;
      max-width: 790px;
      padding: 0 var(--spacer-10);
      width: 100%;
      position: relative;

      &:last-child {
        margin-bottom: var(--spacer-50);

        @include for-desktop {
          margin-bottom: var(--spacer-80);
        }
      }

      @include for-desktop {
        margin: var(--spacer-12) auto;
        padding: 0;
      }

      .sf-accordion-item__header {
        background: var(--light-gray);
        font-size: var(--font-size-14);
        line-height: var(--spacer-24);
        padding: var(--spacer-15) 0 var(--spacer-15) var(--spacer-15);
        text-align: left;
        padding-right: var(--spacer-40);
        position: relative;

        @include font-icon(var(--icon-shape));

        &:before {
          position: absolute;
          right: 0;
          font-size: var(--font-size-12);
          margin: var(--spacer-15);
          height: var(--spacer-13);
          transition: transform 0.2s ease;
          width: var(--spacer-12);

          @media (min-width: $tablet-min) {
            margin-right: var(--spacer-36);
          }
        }

        &.sf-accordion-item__header--open {
          background: transparent;
          border: 1px solid var(--gray3);
          border-bottom: none;
          box-sizing: border-box;
          padding-bottom: var(--spacer-10);

          &:before {
            transform: rotate(180deg);
            transition: transform 0.2s ease;
          }

          @include for-desktop {
            padding-bottom: var(--spacer-25);
          }
        }

        @include for-desktop {
          font-size: var(--font-size-18);
          line-height: var(--spacer-18);
          padding: var(--spacer-30) 0 var(--spacer-30) var(--spacer-30);
        }
      }

      .sf-accordion-item__content {
        box-sizing: border-box;
        border: 1px solid var(--gray3);
        border-top: none;
      }
    }
  }

  .answer {
    font-size: var(--font-size-14);
    font-weight: 400;
    line-height: var(--spacer-24);
    margin: 0 var(--spacer-15) var(--spacer-15);

    @include for-desktop {
      margin: 0 var(--spacer-30) var(--spacer-30);
    }
  }
}

</style>
