<template>
  <div class="own-tm">
    <div class="own-tm__top">
      <h1 class="own-tm__title" />
      <SfButton
        class="own-tm__button"
        data-transaction-name="OwnTm - Go To Become Partner"
        @click="goToPartner"
      >
        {{ $t('Become a partner') }}
      </SfButton>
    </div>
    <div class="own-tm__items" />
  </div>
</template>
<script>
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'OwnTm',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    SfButton
  },
  mounted () {
    const getTitleTag = document.querySelector('.own-tm__title')
    const getItemsTag = document.querySelector('.own-tm__items')

    if (this.isContentAvailable) {
      getTitleTag.innerHTML = this.content.title
      const domElement = this.createElementFromHTML(this.content.message)
      const getItem = domElement.querySelectorAll('.own-tm-item')

      getItemsTag.append(...getItem)
    }
  },
  methods: {
    goToPartner () {
      this.$router.push('suppliers')
    },
    isContentAvailable () {
      return Array.isArray(this.content)
        ? this.content.length
        : this.content.title && this.content.message;
    },
    createElementFromHTML (htmlString) {
      const div = document.createElement('div');
      div.innerHTML = htmlString.trim();

      return div.firstChild;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~theme/css/mixins";

.own-tm {
  max-width: calc(var(--max-width) - var(--spacer-20));
  overflow: hidden;
  margin-bottom: var(--spacer-100);
  margin: 0 auto;
  padding: 0 var(--spacer-10) var(--spacer-50) var(--spacer-10);

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: var(--spacer-10);

    @media (min-width: $tablet-min) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include for-desktop {
      margin-bottom: var(--spacer-30);
    }
  }

  &__items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-column-gap: px2rem(10);
    grid-row-gap: px2rem(60);
  }

  ::v-deep .own-tm-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 49%;
    cursor: pointer;

    @include for-desktop {
      max-width: px2rem(590);
    }

    &__image {
      --image-width: 100%;
      --image-height: 100%;
      height: px2rem(162);

      @include for-desktop {
        height: px2rem(270);
      }

      @include for-tablet {
        height: px2rem(233);
      }
    }

    &__bottom {
      background: var(--light-gray);
      padding: var(--spacer-20);
      box-sizing: border-box;

      @include for-desktop {
        height: 50%;
        padding: var(--spacer-30);
        max-height: 270px;
        overflow: hidden;
      }
    }

    &__description {
      font-size: var(--font-size-12);
      line-height: var(--font-size-22);
      color: var(--dark-gray);

      p:first-of-type {
        margin: var(--spacer-15) 0 var(--spacer-10) 0;
      }

      p + p {
        margin: 0;
      }

      @include for-desktop {
        font-size: var(--font-size-14);
        line-height: var(--font-size-24);

        p:first-of-type {
          margin: var(--spacer-10) 0 var(--spacer-5) 0;
        }
      }
    }

    &__title {
      font-weight: var(--font-medium);
      font-size: var(--font-size-16);
      margin: 0;

      @include for-desktop {
        font-size: var(--font-size-24);
      }
    }
  }

  &__button {
    padding: 0 var(--spacer-16);
    height: var(--button-height, px2rem(40));
    font-size: var(--font-size-14);
    max-width: px2rem(153);
  }

  &__title {
    @include header-title;
    margin: 0;
    padding-bottom: var(--spacer-10);

    @include for-desktop {
      padding-bottom: 0;
    }
  }
}
</style>
