<template>
  <section
    class="m-advantages"
    v-if="advantages.length"
  >
    <div
      class="m-advantages-list"
      v-if="!isMobile"
    >
      <div
        class="m-advantages__item"
        v-for="(advantage, i) in advantages"
        :key="i"
      >
        <span class="m-advantages__item-title">
          {{ advantage.title }}
          <span
            class="m-advantages__item--number"
            v-if="advantage.additionalsTags"
            v-html="advantage.additionalsTags"
          />
        </span>
        <span class="m-advantages__item-desc">
          {{ advantage.description }}
        </span>
      </div>
    </div>
    <SfOCarousel
      v-else
      :settings="sliderSettings"
      is-show-bullets
    >
      <SfCarouselItem
        v-for="(advantage, i) in advantages"
        :key="i"
      >
        <div class="m-advantages__item">
          <span class="m-advantages__item-title">
            {{ advantage.title }}
            <span
              class="m-advantages__item--number"
              v-if="advantage.additionalsTags"
              v-html="advantage.additionalsTags"
            />
          </span>
          <span class="m-advantages__item-desc">
            {{ advantage.description }}
          </span>
        </div>
      </SfCarouselItem>
    </SfOCarousel>
  </section>
</template>

<script>
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel'
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'MTastyJobStatisticsBox',
  mixins: [DeviceType],
  components: {
    SfOCarousel
  },
  props: {
    advantagesContent: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      advantages: [],
      sliderSettings: {
        type: 'slider',
        animationDuration: 2000,
        rewindDuration: 2000,
        gap: 0,
        perView: 2
      },
      getBrowserWidth: 0
    }
  },
  methods: {
    parseAdvantages () {
      const result = []

      if (typeof document === 'undefined') {
        return
      }

      const wrapper = document.createElement('div')
      wrapper.innerHTML = this.advantagesContent

      const items = wrapper.getElementsByClassName('m-advantages__item')

      if (!items || !items.length) {
        return
      }

      for (const item of items) {
        const advantage = { title: '', description: '', additionalsTags: '' }

        for (const child of item.children) {
          if (child.classList.contains('m-advantages__item-title')) {
            const supTag = child.querySelector('.number')

            if (supTag) {
              advantage.additionalsTags = supTag.innerHTML
              supTag.remove()
            }

            advantage.title = child.innerHTML
          } else if (child.classList.contains('m-advantages__item-desc')) {
            advantage.description = child.innerHTML
          }
        }

        if (advantage.title && advantage.description) {
          result.push(advantage)
        }
      }

      this.advantages = result
    }
  },
  beforeCreate () {
    this.$nextTick(() => {
      this.parseAdvantages()
    })
  }
}
</script>

<style lang="scss">
@import "~theme/css/breakpoints";

.m-advantages {
  padding-top: var(--spacer-50);
  padding-bottom: var(--spacer-50);

  @include for-tablet {
    padding-top: var(--spacer-60);
    padding-bottom: var(--spacer-60);
    line-height: var(--spacer-34);
  }

  @include for-desktop {
    padding-top: var(--spacer-2xl);
    padding-bottom: var(--spacer-2xl);
    line-height: var(--spacer-34);
  }

  @include for-mobile {
    .home & {
      margin-left: calc(-1 * var(--spacer-10));
      margin-right: calc(-1 * var(--spacer-10));
    }
  }

  .glide {
    box-sizing: initial;
  }

  &-list {
    display: flex;
    justify-content: space-around;
  }

  &__item {
    display: block;
    text-align: center;
    margin: 0;

    &--number {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
    }

    &.ua-lang {
      @media (min-width: 404px) and (max-width: $tablet-min) {
        width: 60%;
        padding: 0 20%;
      }
    }

    &-title {
      display: block;
      margin-bottom: var(--spacer-xs);
      font-size: var(--font-xl);

      @media(min-width: $tablet-min) {
        font-size: 2.25rem;
      }
    }

    &-desc {
      display: block;
    }
  }

  .sfo-carousel__bullets li {
    &:nth-child(even) {
      display: none;
    }
  }
}
</style>
