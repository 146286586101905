<template>
  <div id="varus-app-agreement">
    <AStatic :content="content" />
  </div>
</template>

<script>
import AStatic from 'theme/components/atoms/a-static';

export default {
  name: 'VarusAppAgreement',
  props: {
    content: {
      type: [Object, Array],
      default: () => ({
        title: '',
        message: ''
      })
    }
  },
  components: {
    AStatic
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#varus-app-agreement {
  ::v-deep {
    .sf-heading {
      display: none;
    }

    .content {
      padding: var(--spacer-13) var(--spacer-10) 0;
      @include for-desktop {
        max-width: 812px;
        margin: var(--spacer-35) auto 0;
        padding: 0 var(--spacer-10);
      }
    }

    .title {
      font-size: var(--font-size-20);
      font-weight: var(--font-medium);
      margin-top: 0;
      line-height: var(--font-size-24);
      letter-spacing: 0;
      text-align: left;
      margin-bottom: var(--spacer-15);

      @include for-desktop {
        font-size: var(--font-size-32);
        line-height: var(--font-size-33);
        margin-bottom: var(--spacer-20);
      }
    }

    .mobile-view,
    .desktop-view {
      color: var(--dark-gray);
      font-size: var(--font-size-16);
      line-height: var(--spacer-28);
      margin: 0 auto var(--spacer-35);
      @include for-desktop {
        max-width: 792px;
        margin: 0 0 var(--spacer-35);
      }
    }

    .mobile-view {
      @include for-desktop {
        display: none;
      }
    }

    .desktop-view {
      @include for-mobile {
        display: none;
      }
    }

    ol.items-list {
      padding-left: 0;
      margin-left: var(--spacer-18);
      @include for-desktop {
        margin-left: var(--spacer-28);
      }
    }

    li {
      font-size: var(--font-size-14);
      line-height: var(--spacer-24);
      margin-bottom: var(--spacer-10);
      counter-increment: section;
      @include for-desktop {
        margin-bottom: var(--spacer-20);
      }

      &::marker {
        font-weight: var(--font-medium);
        font-size: var(--font-size-16);
        @include for-desktop {
          font-size: var(--font-size-24);
        }
      }
    }

    li.item-list {
      &:first-child {
        margin-bottom: var(--spacer-68);
        @include for-desktop {
          margin-bottom: var(--spacer-50);
        }
      }

      & > h2 {
        font-size: var(--font-size-16);
        font-weight: var(--font-medium);
        margin-bottom: var(--spacer-10);
        line-height: var(--spacer-19);
        @include for-desktop {
          font-size: var(--font-size-24);
        }
      }

      div.item-list {
        margin-bottom: var(--spacer-10);
        padding-left: var(--spacer-12);
        @include for-desktop {
          margin-bottom: var(--spacer-20);
        }

        dl {
          margin-top: var(--spacer-10);
          position: relative;

          &:before {
            content: '';
            background: var(--orange);
            border-radius: 50%;
            display: block;
            height: var(--spacer-8);
            left: -28px;
            margin-top: var(--spacer-8);
            position: absolute;
            width: var(--spacer-8);

            @include for-desktop {
              left: -36px;
              top: 16px;
              position: relative;
            }
          }
        }

        dt {
          display: inline;
        }

        dd {
          display: inline;
          margin-left: 0;
      }
      }
    }

    ol.order-items-list {
      list-style: none;
      padding-left: 0;

      li.item {
        counter-increment: subsection;
        margin-left: -18px;

        @include for-desktop {
          margin-left: -26px;
        }

        &:before {
          content: counter(section) "." counter(subsection) ".";
          margin-right: var(--spacer-5);
        }

        a {
          color: var(--orange);
        }
      }
    }

    p {
      font-size: var(--font-size-14);
      margin-bottom: var(--spacer-40);

      @include for-desktop {
        margin-bottom: var(--spacer-94);
      }
    }
  }
}
</style>
