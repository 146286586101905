<template>
  <div class="a-category-placeholder">
    <svg width="460" height="729" viewBox="0 0 460 729" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 546C0 543.239 2.23858 541 5 541H412C414.761 541 417 543.239 417 546V572C417 574.761 414.761 577 412 577H0V546Z" fill="#EEEEEE" />
      <path d="M0 576H200V608C200 610.761 197.761 613 195 613H5C2.23857 613 0 610.761 0 608V576Z" fill="#EEEEEE" />
      <rect y="637" width="200" height="32" rx="5" fill="#EEEEEE" />
      <rect y="691" width="156" height="38" rx="5" fill="#EEEEEE" />
      <rect x="10" width="440" height="490" rx="10" fill="#EEEEEE" />
      <animate
        attributeType="CSS"
        attributeName="opacity"
        from="1"
        to=".3"
        dur="2s"
        values="1; .3; 1"
        keyTimes="0; 0.5; 1"
        repeatCount="indefinite"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AProductPlaceholder'
};
</script>

<style lang="scss">
.a-category-placeholder {
  svg {
    path, rect {
      fill: var(--placeholder-color)
    }
  }
}
</style>
