import { render, staticRenderFns } from "./Novelty.vue?vue&type=template&id=12f2f41a"
import script from "./Novelty.vue?vue&type=script&lang=js"
export * from "./Novelty.vue?vue&type=script&lang=js"
import style0 from "./Novelty.vue?vue&type=style&index=0&id=12f2f41a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports